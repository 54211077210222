import React from "react";
import { Dialog } from "../../../components/Dialogs/Dialog";
import { Box, Button } from "@mui/material";


interface IViewSingleOrderItemDialogProps {
    open: boolean;
    type: string
    onClose: () => void;
    onConfirm: (mode: "EDIT" | "UPLOAD") => void
    onMasterSheetConfirm: (mode: "WITHOUT" | "UPLOAD") => void
}
export const ConfirmUploadOrEditDialog: React.FC<
    IViewSingleOrderItemDialogProps
> = ({ open, type, onConfirm, onClose, onMasterSheetConfirm }) => {


    return (
        <Dialog
            open={open}
            title={`Choose ${type === "ICT_MASTER_SHEET" ? "Mastersheet" : "Mode"}`}
            onClose={onClose}
            size="xs"
        >
            {type === "ICT_MASTER_SHEET" ?
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <Button variant="contained" onClick={() => onMasterSheetConfirm("WITHOUT")}>Continue without mastersheet</Button>
                    <Button variant="contained" onClick={() => onMasterSheetConfirm("UPLOAD")}>Upload Mastersheet</Button>
                </Box>
                :
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <Button variant="contained" onClick={() => onConfirm("EDIT")}>Edit Existing Items</Button>
                    <Button variant="contained" onClick={() => onConfirm("UPLOAD")}>Upload Order</Button>
                </Box>
            }

        </Dialog>
    );
};
