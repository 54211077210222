import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
    CLEAR_SINGLE_SUPPLIER,
    CLEAR_SUPPLIER_STATE,
    FETCH_SUPPLIER_FAILED,
    FETCH_SUPPLIER_LIST_FAILED,
    FETCH_SUPPLIER_LIST_PROGRESS,
    FETCH_SUPPLIER_LIST_SUCCESS,
    FETCH_SUPPLIER_PROGRESS,
    FETCH_SUPPLIER_SUCCESS,
} from "./supplier.actions";
import {
    CLEAR_SUPPLIER_BRANCH,
    FETCH_SUPPLIER_BRANCH_FAILED,
    FETCH_SUPPLIER_BRANCH_LIST_FAILED,
    FETCH_SUPPLIER_BRANCH_LIST_PROGRESS,
    FETCH_SUPPLIER_BRANCH_LIST_SUCCESS,
    FETCH_SUPPLIER_BRANCH_PROGRESS,
    FETCH_SUPPLIER_BRANCH_SUCCESS,
} from "./supplier-branch/supplier-branch.actions";
import { defaultSupplierState, SupplierActions } from ".";
import {
    CLEAR_SINGLE_SUPPLIER_CONTACT, CLEAR_SUPPLIER_CONTACT_LIST,
    FETCH_SINGLE_SUPPLIER_CONTACT_LIST_FAILED,
    FETCH_SINGLE_SUPPLIER_CONTACT_LIST_PROGRESS,
    FETCH_SINGLE_SUPPLIER_CONTACT_LIST_SUCCESS,
    FETCH_SUPPLIER_CONTACT_FAILED, FETCH_SUPPLIER_CONTACT_PROGRESS,
    FETCH_SUPPLIER_CONTACT_SUCCESS, FETCH_SUPPLIER_CONTACTS_LIST_FAILED,
    FETCH_SUPPLIER_CONTACTS_LIST_PROGRESS,
    FETCH_SUPPLIER_CONTACTS_LIST_SUCCESS
} from "./supplier-contact";
import { CLEAR_BANK_DETAILS_LIST, CLEAR_SINGLE_BANK_DETAILS, defaultSupplierBankDetails, FETCH_BANK_DETAILS_LIST_FAILED, FETCH_BANK_DETAILS_LIST_PROGRESS, FETCH_BANK_DETAILS_LIST_SUCCESS, FETCH_SUPPLIER_BANK_DETAILS_LIST_FAILED, FETCH_SUPPLIER_BANK_DETAILS_LIST_PROGRESS, FETCH_SUPPLIER_BANK_DETAILS_LIST_SUCCESS } from "./supplier-bank-details";
import { CLEAR_CUSTOMER_DELIVERY_ADDRESS, FETCH_CUSTOMER_DELILVERY_ADDRESS_FAILED, FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_FAILED, FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_PROGRESS, FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_SUCCESS, FETCH_CUSTOMER_DELILVERY_ADDRESS_PROGRESS, FETCH_CUSTOMER_DELILVERY_ADDRESS_SUCCESS } from "./supplier-delivery-address/supplier-delivery-address.actions";
import { defaultSupplierDeliveryAddress } from "./supplier-delivery-address/supplier-delivery-address.state";
import { CLEAR_SUPPLIER_ATTACHMENT_STATE, FETCH_SUPPLIER_ATTACHMENT_LIST_FAILED, FETCH_SUPPLIER_ATTACHMENT_LIST_PROGRESS, FETCH_SUPPLIER_ATTACHMENT_LIST_SUCCESS } from "./supplier-attachments";
import { FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_FAILED, FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_PROGRESS, FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_SUCCESS, FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_FAILED, FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_PROGRESS, FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_SUCCESS } from "./supplier-payment-terms";


export const supplierReducer = (
    state: IStoreState["supplier"] = defaultSupplierState,
    action: SupplierActions,
) => {
    switch (action.type) {
        // #########################################################################
        // ############################# Supplier ##################################
        // #########################################################################
        case FETCH_SUPPLIER_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_list.loading = LoadState.InProgress;
                draftState.supplier_list.data = [];
                draftState.supplier_list.totalRecords = 0;
            });
            return newState;
        }
        case FETCH_SUPPLIER_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.supplier_list.loading = LoadState.Loaded;
                draftState.supplier_list.data = data;
                draftState.supplier_list.totalRecords = totalRecords;
            });
            return newState;
        }
        case FETCH_SUPPLIER_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_list.loading = LoadState.Failed;
            });
            return newState;
        }

        case FETCH_SUPPLIER_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.single_supplier.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_SUPPLIER_SUCCESS: {
            const { data } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.single_supplier.loading = LoadState.Loaded;
                draftState.single_supplier.data = data;
            });
            return newState;
        }
        case FETCH_SUPPLIER_FAILED: {
            const { errorMessage } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.single_supplier.loading = LoadState.Failed;
                draftState.single_supplier.error = errorMessage;
            });
            return newState;
        }

        case CLEAR_SINGLE_SUPPLIER: {
            const newState = produce(state, (draftState) => {
                draftState.single_supplier.loading = LoadState.NotLoaded;
                draftState.single_supplier.data = defaultSupplierState["single_supplier"]["data"];
                draftState.single_supplier.error = null;
            });
            return newState;
        }

        // #########################################################################
        // ########################## Supplier Branch ##############################
        // #########################################################################

        case FETCH_SUPPLIER_BRANCH_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_Branch_list.loading = LoadState.InProgress;
                draftState.supplier_Branch_list.data = [];
                draftState.supplier_Branch_list.totalRecords = 0;
            });
            return newState;
        }
        case FETCH_SUPPLIER_BRANCH_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.supplier_Branch_list.loading = LoadState.Loaded;
                draftState.supplier_Branch_list.data = data;
                draftState.supplier_Branch_list.totalRecords = totalRecords;
            });
            return newState;
        }
        case FETCH_SUPPLIER_BRANCH_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_Branch_list.loading = LoadState.Failed;
            });
            return newState;
        }

        case FETCH_SUPPLIER_BRANCH_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_branch.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_SUPPLIER_BRANCH_SUCCESS: {
            const { data } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_branch.loading = LoadState.Loaded;
                draftState.single_supplier_branch.data = data;
            });
            return newState;
        }
        case FETCH_SUPPLIER_BRANCH_FAILED: {
            const { errorMessage } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_branch.loading = LoadState.Failed;
                draftState.single_supplier_branch.error = errorMessage;
            });
            return newState;
        }

        case CLEAR_SUPPLIER_BRANCH: {
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_branch.loading = LoadState.NotLoaded;
                draftState.single_supplier_branch.data = defaultSupplierState["single_supplier_branch"]["data"];
                draftState.single_supplier_branch.error = null;
            });
            return newState;
        }


        // #########################################################################
        // ########################## Supplier Contacts ############################
        // #########################################################################

        case FETCH_SUPPLIER_CONTACTS_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_contact_list.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_SUPPLIER_CONTACTS_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.supplier_contact_list.loading = LoadState.Loaded;
                draftState.supplier_contact_list.data = data;
                draftState.supplier_contact_list.totalRecords = totalRecords;
            });
            return newState;
        }
        case FETCH_SUPPLIER_CONTACTS_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_contact_list.loading = LoadState.InProgress;
            });
            return newState;
        }

        case FETCH_SINGLE_SUPPLIER_CONTACT_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_contact_list.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_SINGLE_SUPPLIER_CONTACT_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.supplier_contact_list.loading = LoadState.Loaded;
                draftState.supplier_contact_list.data = data;
                draftState.supplier_contact_list.totalRecords = totalRecords;
            });
            return newState;
        }
        case FETCH_SINGLE_SUPPLIER_CONTACT_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_contact_list.loading = LoadState.InProgress;
            });
            return newState;
        }


        case FETCH_SUPPLIER_CONTACT_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_contact.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_SUPPLIER_CONTACT_SUCCESS: {
            const { data } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_contact.loading = LoadState.Loaded;
                draftState.single_supplier_contact.data = data;
            });
            return newState;
        }
        case FETCH_SUPPLIER_CONTACT_FAILED: {
            const { errorMessage } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_contact.loading = LoadState.Failed;
                draftState.single_supplier_contact.error = errorMessage;
            });
            return newState;
        }
        case CLEAR_SINGLE_SUPPLIER_CONTACT: {
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_contact.loading = LoadState.NotLoaded;
                draftState.single_supplier_contact.data = defaultSupplierState["single_supplier_contact"]["data"];
            });
            return newState;
        }
        case CLEAR_SUPPLIER_CONTACT_LIST: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_contact_list.loading = LoadState.NotLoaded;
                draftState.supplier_contact_list.data = defaultSupplierState["supplier_contact_list"]["data"];
                draftState.supplier_contact_list.totalRecords = defaultSupplierState["supplier_contact_list"]["totalRecords"];
            });
            return newState;
        }

        // #########################################################################
        // ####################### Supplier Bank Details ###########################
        // #########################################################################

        case FETCH_BANK_DETAILS_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_bank_details_list.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_BANK_DETAILS_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.supplier_bank_details_list.loading = LoadState.Loaded;
                draftState.supplier_bank_details_list.data = data;
                draftState.supplier_bank_details_list.totalRecords = totalRecords;
            });
            return newState;
        }
        case FETCH_BANK_DETAILS_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_bank_details_list.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_SUPPLIER_BANK_DETAILS_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_bank_details_list.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_SUPPLIER_BANK_DETAILS_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.supplier_bank_details_list.loading = LoadState.Loaded;
                draftState.supplier_bank_details_list.data = data;
                draftState.supplier_bank_details_list.totalRecords = totalRecords;
            });
            return newState;
        }
        case FETCH_SUPPLIER_BANK_DETAILS_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_bank_details_list.loading = LoadState.InProgress;
            });
            return newState;
        }

        case CLEAR_SINGLE_BANK_DETAILS: {
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_bank_details.loading = LoadState.NotLoaded;
                draftState.single_supplier_bank_details.data = defaultSupplierBankDetails;
            });
            return newState;
        }
        case CLEAR_BANK_DETAILS_LIST: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_bank_details_list.loading = LoadState.InProgress;
                draftState.supplier_bank_details_list.data = [];
                draftState.supplier_bank_details_list.totalRecords = 0;
            });
            return newState;
        }




        // #########################################################################
        // ##################### Supplier Delivery Address #########################
        // #########################################################################


        case FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_delivery_address_list.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.supplier_delivery_address_list.loading = LoadState.Loaded;
                draftState.supplier_delivery_address_list.data = data;
                draftState.supplier_delivery_address_list.totalRecords = totalRecords;
            });
            return newState;
        }
        case FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_delivery_address_list.loading = LoadState.InProgress;
            });
            return newState;
        }

        case FETCH_CUSTOMER_DELILVERY_ADDRESS_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_delivery_address.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_CUSTOMER_DELILVERY_ADDRESS_SUCCESS: {
            const { data } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_delivery_address.loading = LoadState.Loaded;
                draftState.single_supplier_delivery_address.data = data;
            });
            return newState;
        }
        case FETCH_CUSTOMER_DELILVERY_ADDRESS_FAILED: {
            const { errorMessage } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_delivery_address.loading = LoadState.Failed;
                draftState.single_supplier_delivery_address.error = errorMessage;
            });
            return newState;
        }
        case CLEAR_CUSTOMER_DELIVERY_ADDRESS: {
            const newState = produce(state, (draftState) => {
                draftState.single_supplier_delivery_address.loading = LoadState.NotLoaded;
                draftState.single_supplier_delivery_address.data = defaultSupplierDeliveryAddress;
            });
            return newState;
        }


        // #########################################################################
        // ##################### Supplier Attachments #########################
        // #########################################################################


        case FETCH_SUPPLIER_ATTACHMENT_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_attachments_list.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_SUPPLIER_ATTACHMENT_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.supplier_attachments_list.loading = LoadState.Loaded;
                draftState.supplier_attachments_list.data = data;
                draftState.supplier_attachments_list.totalRecords = totalRecords;
            });
            return newState;
        }
        case FETCH_SUPPLIER_ATTACHMENT_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_attachments_list.loading = LoadState.InProgress;
            });
            return newState;
        }

        case CLEAR_SUPPLIER_ATTACHMENT_STATE: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_attachments_list.loading = LoadState.NotLoaded;
                draftState.supplier_attachments_list.data = [];
            });
            return newState;
        }


        // #########################################################################
        // ############################# Supplier Payment Terms ##################################
        // #########################################################################
        // Multiple
        case FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_payment_term_list.loading = LoadState.InProgress;
                draftState.supplier_payment_term_list.data = [];
                draftState.supplier_payment_term_list.totalRecords = 0;
            });
            return newState;
        }
        case FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.supplier_payment_term_list.loading = LoadState.Loaded;
                draftState.supplier_payment_term_list.data = data;
                draftState.supplier_payment_term_list.totalRecords = totalRecords;
            });
            return newState;
        }
        case FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_payment_term_list.loading = LoadState.Failed;
            });
            return newState;
        }
        // Single
        case FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.supplier_single_payment_term.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_SUCCESS: {
            const { data } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.supplier_single_payment_term.loading = LoadState.Loaded;
                draftState.supplier_single_payment_term.data = data;
            });
            return newState;
        }
        case FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_FAILED: {
            const { errorMessage } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.supplier_single_payment_term.loading = LoadState.Failed;
                draftState.supplier_single_payment_term.error = errorMessage;
            });
            return newState;
        }

        default: {
            return state;
        }
    }
};
