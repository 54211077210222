import { io, Socket } from "socket.io-client";

// ----------------------------- Base Urls ----------------------------------
const SOCKET_SERVER_URL = process.env["REACT_APP_SERVER_BASE_URL"]; // Backend URL


export const initializeSocket = (user_uuid: string): Socket => {
    const app_socket = io(SOCKET_SERVER_URL, {
        autoConnect: false, // Prevent automatic connection
        auth: {
            user_uuid // Send user_uuid uuid
        },
    });
    app_socket.connect();
    return app_socket;
};


// const disconnectSocket = () => {
//     if (app_socket) {
//         app_socket.disconnect();
//         app_socket = null;
//     }
// };