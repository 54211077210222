import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
	saveLoaderCompleted,
	saveLoaderProgress,
	showMessage,
} from "../messages/messagesActions";
import { ICustomerDispatchAddress } from "./customDispatchAddress.types";

export const FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_PROGRESS =
	"FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_PROGRESS";
export const FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_SUCCESS =
	"FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_SUCCESS";
export const FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_FAILED =
	"FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_FAILED";

export const fetchCustomerDispatchAddressListProgress = () =>
	action(FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_PROGRESS);
export const fetchCustomerDispatchAddressListSuccess = (
	data: ICustomerDispatchAddress[],
	totalRecords: number
) =>
	action(FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_SUCCESS, { data, totalRecords });
export const fetchCustomerDispatchAddressListFailed = () =>
	action(FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_FAILED);

export const fetchCustomerDispatchAddressListAsync =
	(
		queryParams: IQueryParams,
		customerUUID?: string
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const searchQuery = getSearchQuery(queryParams);
			dispatch(fetchCustomerDispatchAddressListProgress());
			let finalUrl = `/company/get-company-delivery-address${searchQuery}&company_uuid=${customerUUID}`;
			const res = await api.get(finalUrl);
			const data: ICustomerDispatchAddress[] = res.data.data;
			const totalRecords = res.data.totalRecords;
			dispatch(fetchCustomerDispatchAddressListSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchCustomerDispatchAddressListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const upsertCustomerDispatchAddressAsync =
	(
		customerDispatchAddress: ICustomerDispatchAddress,
		onCallback: (
			isSuccess: boolean,
			customerDispatchAddress?: ICustomerDispatchAddress
		) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(saveLoaderProgress());
			const { create_ts, insert_ts, rowId, ...rest } = customerDispatchAddress;
			const res = await api.post(
				"/company/upsert-company-delivery-address",
				rest
			);
			const data = res.data.data;
			onCallback(true, data);
			dispatch(
				showMessage({
					type: "success",
					message: "Dispatch Address saved successfully!",
					displayAs: "snackbar",
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const FETCH_CUSTOMER_DISPATCH_ADDRESS_PROGRESS =
	"FETCH_CUSTOMER_DISPATCH_ADDRESS_PROGRESS";
export const FETCH_CUSTOMER_DISPATCH_ADDRESS_SUCCESS =
	"FETCH_CUSTOMER_DISPATCH_ADDRESS_SUCCESS";
export const FETCH_CUSTOMER_DISPATCH_ADDRESS_FAILED =
	"FETCH_CUSTOMER_DISPATCH_ADDRESS_FAILED";

export const fetchCustomerDispatchAddressProgress = () =>
	action(FETCH_CUSTOMER_DISPATCH_ADDRESS_PROGRESS);
export const fetchCustomerDispatchAddressSuccess = (
	data: ICustomerDispatchAddress
) => action(FETCH_CUSTOMER_DISPATCH_ADDRESS_SUCCESS, { data });
export const fetchCustomerDispatchAddressFailed = (errorMessage: string) =>
	action(FETCH_CUSTOMER_DISPATCH_ADDRESS_FAILED, { errorMessage });

export const fetchCustomerDispatchAddressAsync =
	(
		customerDispatchAddressUUID: string
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchCustomerDispatchAddressProgress());
			const res = await api.get(
				`/company/get-company-delivery-address?company_delivery_address_uuid=${customerDispatchAddressUUID}`
			);
			const data: ICustomerDispatchAddress[] = res.data.data;
			if (data.length > 0) {
				dispatch(fetchCustomerDispatchAddressSuccess(data[0]));
			} else {
				dispatch(
					fetchCustomerDispatchAddressFailed(
						"Oops! We couldn't find any records."
					)
				);
			}
		} catch (err: any) {
			dispatch(
				fetchCustomerDispatchAddressFailed("Something went to be wrong!")
			);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const CLEAR_CUSTOMER_DISPATCH_ADDRESS =
	"CLEAR_CUSTOMER_DISPATCH_ADDRESS";
export const CLEAR_CUSTOMER_DISPATCH_ADDRESS_STATE =
	"CLEAR_CUSTOMER_DISPATCH_ADDRESS_STATE";

export const clearCustomerDispatchAddress = () =>
	action(CLEAR_CUSTOMER_DISPATCH_ADDRESS);
export const clearCustomerDispatchAddressState = () =>
	action(CLEAR_CUSTOMER_DISPATCH_ADDRESS_STATE);
