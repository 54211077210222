// budget.state.ts

import { LoadState } from "../../constants/enums";

import { ICTTemplate, ICTTemplateListState, IICTMapping } from "./ictTypes";

export const defaultICTMapping: IICTMapping = {
	field: "",
	description: "",
	is_required: false,
	is_not_included: false,
};
export const defaultICTTemplate: ICTTemplate = {
	ict_template_uuid: "",
	name: null,
	mapping: {},
	status: "ACTIVE",
	create_ts: "",
	insert_ts: "",
};

export const defaultICTTemplateState: ICTTemplateListState = {
	list: {
		data: [],
		totalRecords: 0,
		loading: LoadState.NotLoaded,
		error: null,
	},

	single_ict: {
		data: defaultICTTemplate,
		totalRecords: 0,
		loading: LoadState.NotLoaded,
		error: null,
	},
};
