/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { api } from "../../api/api";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import Autocomplete from "@mui/material/Autocomplete";
import { IUserBranchAutoSearchProps } from "./AutoCompleteSearches.types";
import { debounce } from "lodash";
import { Box, Stack, CircularProgress } from "@mui/material";
import { IUserBranch } from "../../redux/userBranch/userBranch.types";
import { defaultUserBranch } from "../../redux/userBranch/defaultState";
const INITIAL_STATE: IUserBranch = defaultUserBranch;

export const UserBranchAutoSearch: React.FC<IUserBranchAutoSearchProps> = (
	props
) => {
	const { label, value, onSelect, disabled, error } = props;
	const [options, setOptions] = React.useState<readonly IUserBranch[]>([]);
	const [loading, setLoading] = React.useState(false);
	const [search, setSearchText] = React.useState<any>("");
	const [openUserDialog, setOpenUserDialog] = React.useState(false);
	const [fieldValue, setFieldValue] = React.useState<IUserBranch | null>(null);

	const fetchSuggestion = async (value: string) => {
		setLoading(true);
		try {
			let url = `/user/get-branch?columns=branch_name&value=${value}&pageNo=1&itemPerPage=20`;
			if (value === "") {
				url = `/user/get-branch?pageNo=1&itemPerPage=20`;
			}
			const res = await api.get(url);
			const finalData: IUserBranch[] = res.data.data;
			setOptions(finalData);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

	const getOptionLabel = (option: IUserBranch) => {
		return option.branch_name;
	};

	const getValue = () => {
		let newValue: IUserBranch | null = null;
		if (value && typeof value === "object") {
			newValue =
				options.find((option) => option.branch_uuid === value?.branch_uuid) ||
				null;
		} else {
			newValue = options.find((option) => option.branch_uuid === value) || null;
		}
		setFieldValue(newValue);
	};

	const handleToggle = () => {
		setOpenUserDialog(!openUserDialog);
	};

	React.useEffect(() => {
		if (search && search !== value && search.length > 2) {
			debounceFn(search);
		}
	}, [search]);

	React.useEffect(() => {
		fetchSuggestion("");
	}, []);

	React.useEffect(() => {
		getValue();
	}, [value, options]);

	React.useEffect(() => {
		if (value && typeof value === "object" && value?.user_uuid?.length > 0) {
			const option: IUserBranch = {
				...INITIAL_STATE,
				branch_uuid: value.branch_uuid,
				branch_name: value.branch_name,
			};
			setOptions([option]);
		}
	}, [value]);

	return (
		<>
			{label && (
				<Box
					display="flex"
					justifyContent={"flex-start"}
					alignItems="center"
				>
					<CustomFormLabel display={"block"}>{` ${label}  `}</CustomFormLabel>
					{/* {search.length > 0 && options.length === 0 && !loading && (
            <Box
              sx={{
                paddingLeft: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 1,
              }}
              onClick={handleToggle}
            >
              <AddCircleIcon fontSize="small" color="primary" />
              <Typography
                variant="h6"
                color="primary"
                marginLeft={0.5}
                marginTop={0.3}
                fontWeight={500}
              >
                Add Br
              </Typography>
            </Box>
          )} */}
				</Box>
			)}
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				spacing={1}
			>
				<Autocomplete
					id="google-map-demo"
					fullWidth
					disabled={disabled}
					sx={{
						".MuiOutlinedInput-root": {
							paddingTop: "2px",
							paddingBottom: "2px",
							fontSize: "0.8rem",
							color: "rgb(38, 38, 38)",
							width: "100%",
							backgroundColor: disabled ? "#f8f9fb" : "inherit",
						},
					}}
					getOptionLabel={getOptionLabel}
					isOptionEqualToValue={(option, value) =>
						typeof option === "string"
							? option === value //@ts-ignore
							: option.company_name === value.company_name
					}
					filterOptions={(x) => x}
					options={options}
					autoComplete
					includeInputInList
					value={fieldValue}
					loading={loading}
					noOptionsText="No Branch Found"
					//@ts-ignore
					onChange={(
						event: React.ChangeEvent<HTMLElement>,
						newValue: IUserBranch | null
					) => {
						if (newValue) {
							onSelect(newValue);
						}
					}}
					onInputChange={(event, newInputValue) => {
						if ((event && event.type === "change") || !newInputValue) {
							setSearchText(newInputValue);
						}
					}}
					renderInput={(params) => (
						<CustomTextField
							{...params}
							fullWidth
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{loading && (
											<CircularProgress
												color="inherit"
												size={20}
											/>
										)}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
							disabled={disabled}
							error={error ? true : false}
							helperText={error}
						/>
					)}
				/>
			</Stack>
		</>
	);
};
