import React, { useCallback } from "react";
import { styled } from "@mui/material/styles";
import { OutlinedInputProps, TextField, TextFieldProps, Typography } from "@mui/material";

export const CustomTextFieldStyled = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(({ theme }) => ({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& .MuiOutlinedInput-input": {
    padding: "9px 13px",
    fontSize: "0.8rem",
    color: "rgb(38, 38, 38)",
  },

  "& .MuiOutlinedInput-input::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "#dee3e9"
    }`,
    borderRadius: "5px",
  },
  "& .MuiOutlinedInput-input.Mui-disabled": {
    backgroundColor: `${
      theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.12) " : "#f8f9fb "
    }`,
  },
  "& .MuiOutlinedInput-input.Mui-disabled::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
}));

export const CustomTextField: React.FC<
  TextFieldProps & { readOnlyMode?: boolean }
> = (props) => {
  const { readOnlyMode = false, ...rest } = props;

  if (readOnlyMode) {
    return (
      <Typography variant="body1" padding={1} fontSize={"0.8rem"}>
        <>{props.value || "--"}</>
      </Typography>
    );
  }

  return (
    <>
      <CustomTextFieldStyled {...rest} />
    </>
  );
};



interface CustomNumberFieldProps extends Omit<TextFieldProps, "value"> {
  readOnlyMode?: boolean;
  value?: string | number;
}

export const CustomNumberField: React.FC<CustomNumberFieldProps> = (props) => {
  const { readOnlyMode = false, value, onChange, ...rest } = props;


  // Process Initial Value
  const processedValue = useCallback(() => {
    if (!value) return "";
    return value.toString().replace(/^0+/, ""); // Remove leading zeros
  }, [value])();

  // ReadOnly Mode: Display text instead of input
  if (readOnlyMode) {
    return (
      <Typography variant="body1" padding={1} fontSize="0.8rem">
        {value || "--"}
      </Typography>
    );
  }

  return (
    <CustomTextFieldStyled
      {...rest}
      value={processedValue} // Use processed value correctly
      onChange={(event) => {
        if (!props.onChange) return;
        let newValue = event.target.value
          .replace(/[^0-9.]/g, "")
          .replace(/(\..*)\./g, "$1"); // Allow only numbers
        newValue = newValue.replace(/^0+/, ""); // Remove leading zeros
        props.onChange({
          ...event,
          target: { ...event.target, value: newValue },
        }); // Fire onChange with a new synthetic event
      }}
      InputProps={{ inputProps: { min: 1 } }} // Prevents negative values in UI
    />
  );
};
