import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import {
	saveLoaderCompleted,
	saveLoaderProgress,
	showMessage,
} from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { ICustomerBranch, ICustomerState } from "./customer.types";

export const FETCH_CUSTOMER_BRANCH_LIST_PROGRESS =
	"FETCH_CUSTOMER_BRANCH_LIST_PROGRESS";
export const FETCH_CUSTOMER_BRANCH_LIST_SUCCESS =
	"FETCH_CUSTOMER_BRANCH_LIST_SUCCESS";
export const FETCH_CUSTOMER_BRANCH_LIST_FAILED =
	"FETCH_CUSTOMER_BRANCH_LIST_FAILED";

export const fetchCustomerBranchListProgress = () =>
	action(FETCH_CUSTOMER_BRANCH_LIST_PROGRESS);

export const fetchCustomerBranchListSuccess = (
	data: ICustomerState["customerBranchList"]["data"],
	totalRecords: number
) => action(FETCH_CUSTOMER_BRANCH_LIST_SUCCESS, { data, totalRecords });
export const fetchCustomerBranchListFailed = () =>
	action(FETCH_CUSTOMER_BRANCH_LIST_FAILED);

export const fetchCustomerBranchListAsync =
	(queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const searchQuery = getSearchQuery(queryParams);
			dispatch(fetchCustomerBranchListProgress());
			const res = await api.get(`/company/get-compnay-branch${searchQuery}`);
			const data: ICustomerBranch[] = res.data.data;

			const totalRecords = res.data.totalRecords;

			dispatch(fetchCustomerBranchListSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchCustomerBranchListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const FETCH_CUSTOMER_BRANCH_PROGRESS = "FETCH_CUSTOMER_BRANCH_PROGRESS";
export const FETCH_CUSTOMER_BRANCH_SUCCESS = "FETCH_CUSTOMER_BRANCH_SUCCESS";
export const FETCH_CUSTOMER_BRANCH_FAILED = "FETCH_CUSTOMER_BRANCH_FAILED";

export const fetchCustomerBranchProgress = () =>
	action(FETCH_CUSTOMER_BRANCH_PROGRESS);
export const fetchCustomerBranchSuccess = (data: ICustomerBranch) =>
	action(FETCH_CUSTOMER_BRANCH_SUCCESS, { data });
export const fetchCustomerBranchFailed = (errorMessage: string) =>
	action(FETCH_CUSTOMER_BRANCH_FAILED, { errorMessage });

export const fetchCustomerBranchAsync =
	(uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchCustomerBranchProgress());
			const res = await api.get(
				`/company/get-company-branch?company_branch_uuid=${uuid}`
			);
			const data: ICustomerBranch[] = res.data.data;
			if (data.length > 0) {
				dispatch(fetchCustomerBranchSuccess(data[0]));
			} else {
				dispatch(
					fetchCustomerBranchFailed("Oops! We couldn't find any records.")
				);
			}
		} catch (err: any) {
			dispatch(fetchCustomerBranchFailed("Something went to be wrong!"));
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const upsertCustomerBranchAsync =
	(
		data: ICustomerBranch,
		onCallback: (isSuccess: boolean, customer?: ICustomerBranch) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const { create_ts, insert_ts, ...payload } = data;
			dispatch(saveLoaderProgress());
			const res = await api.post("/company/upsert-company-branch", payload);
			let message = "Customer Branch saved successfully!";
			onCallback(true, res.data.data);
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const CLEAR_CUSTOMER_BRANCH = "CLEAR_CUSTOMER_BRANCH";
export const CLEAR_CUSTOMER_BRANCH_STATE = "CLEAR_CUSTOMER_BRANCH_STATE";
export const clearCustomerBranch = () => action(CLEAR_CUSTOMER_BRANCH);
export const clearCustomerBranchState = () =>
	action(CLEAR_CUSTOMER_BRANCH_STATE);

export const FETCH_CUSTOMER_BRANCHES_PROGRESS =
	"FETCH_CUSTOMER_BRANCHES_PROGRESS";
export const FETCH_CUSTOMER_BRANCHES_SUCCESS =
	"FETCH_CUSTOMER_BRANCHES_SUCCESS";
export const FETCH_CUSTOMER_BRANCHES_FAILED = "FETCH_CUSTOMER_BRANCHES_FAILED";

export const fetchCustomerBranchesProgress = () =>
	action(FETCH_CUSTOMER_BRANCHES_PROGRESS);
export const fetchCustomerBranchesSuccess = (
	data: ICustomerState["customerBranchList"]["data"],
	totalRecords: number
) => action(FETCH_CUSTOMER_BRANCHES_SUCCESS, { data, totalRecords });
export const fetchCustomerBranchesFailed = (errorMessage: string) =>
	action(FETCH_CUSTOMER_BRANCHES_FAILED, { errorMessage });

export const fetchCustomerBranchesAsync =
	(
		uuid: string,
		pageNumber: number,
		rowsPerPage: number
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchCustomerBranchesProgress());
			const res = await api.get(
				`/company/get-company-branch?company_uuid=${uuid}&pageNo=${pageNumber}&itemPerPage=${rowsPerPage}`
			);
			const data: ICustomerBranch[] = res.data.data;
			const totalRecords = res.data.totalRecords;
			dispatch(fetchCustomerBranchesSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchCustomerBranchesFailed("Something went to be wrong!"));
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};
