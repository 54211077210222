import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageLoader } from "../../../components/PageLoader/PageLoader";
import { useSelector } from "react-redux";
import type { IStoreState } from "../../../redux/initialStoreState";
import { LoadState } from "../../../constants/enums";
import { PageContainer } from "../../../components/container/PageContainer";
import { useFormik } from "formik";
import { useDispatchWrapper } from "../../../hooks";
import { Chip } from "@mui/material";
import {
	clearOrderState,
	fetchOrderDocumentDiscrepanciesListAsync,
	fetchOrdersDiscrepanciesListAsync,
	type IOrderDiscrepancyItem,
	upsertOrderDiscrepancies,
} from "../../../redux/orders";
import {
	Box,
	Button,
	Divider,
	Grid,
	Paper,
	Stack,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import {
	ArrowForward,
	CheckOutlined,
	DescriptionOutlined,
	IosShare,
} from "@mui/icons-material";
import produce from "immer";
import { Dialog } from "../../../components/Dialogs/Dialog";
import { PreviewOrderFileDialog } from "../../Orders/PreviewOrderFileDialog";
import { CustomTextField } from "../../../components/formsComponents";
import { OrderDiscrepancyRow } from "../../Orders/OrderDiscrepancy";


interface IUserDocumentsDialog {
	open: boolean;
	order_uuid: string;
	onClose: () => void;
}

export const ViewDiscrepancyDialog: React.FC<IUserDocumentsDialog> = ({
	order_uuid, onClose, open,
}) => {
	const { data: discrepancyReport, error, loading } = useSelector(
		(storeState: IStoreState) => storeState.orders.single_order_discrepancy
	);
	const dispatch = useDispatchWrapper();
	const navigate = useNavigate();
	const theme = useTheme();

	const [openPreViewFileDialog, setOpenPreViewFileDialog] = useState<boolean>(false);

	React.useEffect(() => {
		if (order_uuid) {
			dispatch(fetchOrderDocumentDiscrepanciesListAsync(order_uuid));
		}
	}, [order_uuid]);

	React.useEffect(() => {
		return () => {
			dispatch(clearOrderState());
		};
	}, []);


	return (
		<Dialog
			open={open}
			title="Discrepancy Report"
			onClose={onClose}
			fullScreen
		>
			<>
				<PageLoader
					loading={loading === LoadState.InProgress}
					error={error ? { message: error } : null}
				>
					<Grid
						container
						spacing={1}
					>
						<Grid
							item
							xs={12}
							md={1}
						/>
						<Grid
							item
							xs={12}
							md={10}
						>
							<Paper
								variant="outlined"
								sx={{ p: 5 }}
							>
								<Typography
									variant="h2"
									fontWeight={600}
								>
									{`${discrepancyReport.order_discrepancies_data.length} discrepanices need your review `}
									<Typography
										component={"span"}
										variant="h4"
										fontWeight={500}
									>
										{`(Order confirmation)`}
									</Typography>
								</Typography>
								<Grid
									container
									spacing={1}
									mt={4}
									mb={5}
								>
									<Grid
										item
										xs={4}
									>
										<Stack
											direction={"row"}
											justifyContent={"space-between"}
										>
											<Typography
												variant="body1"
												fontWeight={600}
											>
												Order No#
											</Typography>
											<Typography variant="body1">{discrepancyReport.order_no}</Typography>
										</Stack>
										<Stack
											direction={"row"}
											mt={1}
											justifyContent={"space-between"}
										>
											<Typography
												variant="body1"
												fontWeight={600}
											>
												Supplier
											</Typography>
											<Typography variant="body1">
												{discrepancyReport.supplier_name}
											</Typography>
										</Stack>
										<Stack
											direction={"row"}
											mt={1}
											justifyContent={"space-between"}
										>
											<Typography
												variant="body1"
												fontWeight={600}
											>
												Supplier Email
											</Typography>
											<Typography variant="body1">
												{discrepancyReport.supplier_email}
											</Typography>
										</Stack>
									</Grid>
									<Grid
										item
										xs={4}
									/>
									<Grid
										item
										xs={4}
									>
										<Paper>
											<Stack
												spacing={2}
												direction={"column"}
												justifyContent={"center"}
												alignItems={"center"}
												padding={1}
												sx={{
													minHeight: "200px",
													border: `2px dashed ${theme.palette.grey[500]}`,
												}}
											>
												<Stack
													direction={"column"}
													spacing={1}
													alignItems={"center"}
													justifyContent={"center"}
													width={"80%"}
												>
													<Box position={"relative"}>
														<DescriptionOutlined
															sx={{
																fontSize: 75,
																color: theme.palette.grey[500],
															}}
														/>
														<Box
															sx={{
																position: "absolute",
																padding: "2px",
																right: 3,
																background: theme.palette.success.main,
																borderRadius: "100%",
																display: "flex",
																top: -1,
															}}
														>
															<CheckOutlined sx={{ color: "#fff" }} />
														</Box>
													</Box>
												</Stack>
												<Stack
													direction={"row"}
													spacing={1}
												>
													<Button
														variant="contained"
														size="large"
														color="error"
														onClick={() => setOpenPreViewFileDialog(true)}
													>
														View
													</Button>
												</Stack>
											</Stack>
										</Paper>
									</Grid>
								</Grid>
								<>
									<Box
										mb={3}
										sx={{
											backgroundColor: theme.palette.grey[200],
											padding: 2,
											borderRadius: 2,
										}}
									>
										<Typography
											variant="h4"
											fontWeight={500}
										>
											Order Items
										</Typography>
									</Box>
									<TableContainer component={Paper}>
										<Table
											sx={{ minWidth: 650 }}
											aria-label="simple table"
										>
											<TableHead>
												<TableRow>
													<TableCell
														align="center"
														sx={{ minWidth: 150 }}
													>
														Image
													</TableCell>

													<TableCell
														align="left"
														sx={{ minWidth: 100 }}
													>
														Color
													</TableCell>
													<TableCell
														align="left"
														sx={{ minWidth: 100 }}
													>
														Fabric
													</TableCell>
													<TableCell
														align="left"
														sx={{ minWidth: 100 }}
													>
														Category
													</TableCell>
													<TableCell
														align="left"
														sx={{ minWidth: 100 }}
													>
														line
													</TableCell>
													<TableCell
														align="left"
														sx={{ minWidth: 100 }}
													>
														Style
													</TableCell>
													<TableCell
														align="left"
														sx={{ minWidth: 100 }}
													>
														Submitted Orders
													</TableCell>
													<TableCell
														align="left"
														sx={{ minWidth: 100 }}
													></TableCell>
													<TableCell align="left">Order Confirmation</TableCell>
													<TableCell align="right">
														<Stack
															direction={"row"}
															justifyContent={"flex-end"}
															spacing={1}
														>
															<Button
																variant={"contained"}
																color="error"
																disabled
															>
																NONE OK
															</Button>
															<Button
																variant={"contained"}
																color="success"
																disabled
															>
																All OK
															</Button>
														</Stack>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{discrepancyReport.order_discrepancies_data.map(
													(orderItem, index) => {
														return (
															<OrderDiscrepancyRow
																row={orderItem}
																key={orderItem.order_item_uuid}
																disabled
																changeOrderResponse={() => {}}
																updateRowData={() => {}}
															/>
														);
													}
												)}
											</TableBody>
										</Table>
									</TableContainer>

									<Divider sx={{ mt: 3, mb: 2 }} />

									<Box
										mb={3}
										sx={{
											backgroundColor: theme.palette.grey[200],
											padding: 2,
											borderRadius: 2,
										}}
									>
										<Typography
											variant="h4"
											fontWeight={500}
										>
											Notes
										</Typography>
									</Box>
									<CustomTextField
										fullWidth
										multiline
										rows={3}
										disabled
									// value={discrepancyReport.r}
									></CustomTextField>

									{/* <Stack
										direction={"row"}
										justifyContent={"end"}
										mt={2}
									>
										<Button
											type="submit"
											variant="contained"
											color="success"
											sx={{ minWidth: "10%" }}
											endIcon={<IosShare sx={{ transform: "rotate(90deg)" }} />}
										>
											Submit
										</Button>
									</Stack> */}
								</>
							</Paper>
						</Grid>
						<Grid
							item
							xs={12}
							md={1}
						/>
					</Grid>
				</PageLoader>
				{openPreViewFileDialog && (
					<PreviewOrderFileDialog
						open={openPreViewFileDialog}
						fileUrl={discrepancyReport.order_confirmation_file || ""}
						onClose={() => setOpenPreViewFileDialog(false)}
					/>
				)}
			</>
		</Dialog>
	);
};
