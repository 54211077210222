import { LoadState } from "../../constants/enums";
import {
	ICustomerDispatchAddress,
	ICustomerDispatchAddressState,
} from "./customDispatchAddress.types";

export const initialCustomerDispatchAddress: ICustomerDispatchAddress = {
	company_dispatch_address_uuid: "",
	company_uuid: null,
	company_name: "",
	dispatch_name: null,
	company_dispatch_address_line1: null,
	company_dispatch_address_line2: null,
	company_dispatch_address_city: null,
	company_dispatch_address_state: null,
	company_dispatch_address_pincode: null,
	company_dispatch_address_country: null,
	dispatch_through: null,
	transport_id: null,
	transport_doc_no: null,
	transport_doc_date: null,
	vehicle_no: null,
	status: "ACTIVE",
};
export const initialCustomerDispatchAddressState: ICustomerDispatchAddressState =
	{
		list: [],
		totalRecords: 0,
		loading: LoadState.NotLoaded,
		error: null,
		customerDispatchAddress: initialCustomerDispatchAddress,
		customDispatchAddressLoading: LoadState.NotLoaded,
		customDispatchAddressError: null,
	};
