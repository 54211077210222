import { Avatar, Box, Button, MenuItem } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { StatusRenderer } from "../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { CustomOnClickWrapper } from "../../../../components/CustomLink/CustomClickWrapper";
import LoadingDialog from "../../../../components/Dialogs/LoadingDialog";
import { StandardTableActions } from "../../../../components/StandardTableActions/StandardTableActions";
import { DataTable } from "../../../../components/Table/DataTable";
import {
	IDataTableProps,
	MobileLogoRenderType,
	RenderType,
} from "../../../../components/Table/interfaces/IDataTableProps";
import {
	CSVExportPlugin,
	RefreshPlugin,
	SearchPlugin,
} from "../../../../components/Table/plugins";
import { PageContainer } from "../../../../components/container/PageContainer";
import { CustomSelect } from "../../../../components/formsComponents";
import { LoadState } from "../../../../constants/enums";
import { useDispatchWrapper } from "../../../../hooks";
import { IBankDetails } from "../../../../redux/bankDetails/bankDetails.types";
import {
	clearBankDetails,
	clearBankDetailsState,
	fetchCustomerBankDetailsListAsync,
} from "../../../../redux/bankDetails/bankDetailsActions";
import { initialBankDetails } from "../../../../redux/bankDetails/defaultState";
import { IStoreState } from "../../../../redux/initialStoreState";
import { ManageBankDetails } from "../../../BankDetails/ManageBankDetails";

export const CustomerBankDetailList = () => {
	const { uuid } = useParams() as { uuid?: string };

	const dispatch = useDispatchWrapper();
	const { data: customerData, error } = useSelector(
		(storeState: IStoreState) => storeState.customer.customer
	);
	const [saveLoading, setSaveLoading] = React.useState<boolean>(false);

	const { list, totalRecords, loading } = useSelector(
		(storeState: IStoreState) => storeState.bankDetails
	);

	const [status, setStatus] = React.useState<IBankDetails["status"] | "-1">(
		"-1"
	);
	const [searchColumns, setSearchColumns] = React.useState<string[]>([]);
	const [search, setSearch] = React.useState<string>("");
	const [pagination, setPagination] = React.useState<{
		pageNumber: number;
		rowsPerPage: number;
	}>({ pageNumber: 1, rowsPerPage: 10 });

	// ******Modal Start******
	const [openDialog, setOpenDialog] = React.useState<boolean>(false);
	const [dialogData, setDialogData] =
		React.useState<IBankDetails>(initialBankDetails);

	const handleOpenDialog = () => {
		setOpenDialog(true);
		setDialogData({
			...initialBankDetails,
			company_name: customerData.company_name,
			company_uuid: customerData.company_uuid,
		});
	};
	const handleCloseDialog = () => {
		setOpenDialog(false);
		dispatch(clearBankDetails());
	};
	const handleComplete = () => {
		setOpenDialog(false);
		dispatch(clearBankDetails());
		fetchList();
	};
	const bankDetailsProps: IDataTableProps = {
		isPagination: true,
		totalRecords: totalRecords,
		rowsPerPageOptions: pagination.rowsPerPage,
		isDataLoading: loading !== LoadState.Loaded,
		uniqueRowKeyName: "id",
		tableCommandBarProps: {
			leftItems: {
				customPlugins: [
					{
						key: "status",
						onRender: () => (
							<CustomSelect
								sx={{ minWidth: "120px" }}
								value={status}
								onChange={(e: any) => setStatus(e.target.value as "TODO")}
							>
								<MenuItem value="-1">All Status</MenuItem>
								<MenuItem value="ACTIVE">Active</MenuItem>
								<MenuItem value="INACTIVE">In Active</MenuItem>
							</CustomSelect>
						),
					},
					{
						key: "csvExport",
						onRender: (columns, items) => (
							<CSVExportPlugin
								columns={columns}
								items={items}
								filePrefixName="Report"
							/>
						),
					},
					{
						key: "refresh",
						onRender: () => <RefreshPlugin onClick={fetchList} />,
					},
				],
			},
			rightItems: {
				plugins: {},
				customPlugins: [
					{
						key: "search",
						onRender: () => {
							return (
								<SearchPlugin
									selectedDropdownValue={searchColumns}
									dropdownOptions={[{ label: "Bank Name", value: "bank_name" }]}
									onDropdownChange={(value) => setSearchColumns(value)}
									onChange={(newValue) => {
										setSearch(newValue);
									}}
								/>
							);
						},
					},
				],
			},
		},
		mobileLogo: {
			type: MobileLogoRenderType.reactNode,
			onMobileLogoRender: (data: any) => {
				return (
					<Avatar
						sx={{
							width: 45,
							height: 45,
							color: "#fff",
							ml: "-8px",
							mr: 2,
						}}
					></Avatar>
				);
			},
		},
		columns: [
			{
				key: "status",
				headerName: "Status",
				fieldName: "status",
				exportCellWidth: 30,
				renderType: RenderType.CHIP_WARNING,
				onRowCellRender: (value, row: IBankDetails) => {
					return <StatusRenderer status={row.status} />;
				},
			},
			{
				key: "company_name",
				headerName: "Customer Name",
				fieldName: "company_name",
				exportCellWidth: 40,
				renderType: RenderType.CUSTOM_RENDER,
				onRowCellRender: (value, row: IBankDetails) => {
					return (
						<CustomOnClickWrapper
							label={row.company_name || ""}
							onClick={() => {
								setOpenDialog(true);
								setDialogData(row);
							}}
						/>
					);
				},
			},
			{
				key: "bank_name",
				headerName: "Bank Name",
				fieldName: "bank_name",
				exportCellWidth: 40,
				renderType: RenderType.CUSTOM_RENDER,
			},
			{
				key: "branch",
				headerName: "Branch Name",
				fieldName: "branch",
				exportCellWidth: 40,
				renderType: RenderType.CUSTOM_RENDER,
			},
			{
				key: "view",
				headerName: "Actions",
				fieldName: "",
				renderType: RenderType.CUSTOM_RENDER,
				onRowCellRender: (value, row: IBankDetails) => {
					return (
						<StandardTableActions
							// commentBoxTypeId={row.task_definition_uuid}
							onEditClick={() => {
								setOpenDialog(true);
								setDialogData(row);
							}}
						/>
					);
				},
			},
		],
		items: list,
		onPageChange: (newPageNumber: number) => {
			setPagination({ ...pagination, pageNumber: newPageNumber });
		},
		onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
			setPagination({
				pageNumber: pageNumber,
				rowsPerPage: rowsPerPage,
			});
		},
	};
	const fetchList = () => {
		dispatch(
			fetchCustomerBankDetailsListAsync(
				{
					status: status,
					page: pagination.pageNumber,
					rowsPerPage: pagination.rowsPerPage,
					columns: searchColumns,
					value: search,
				},
				uuid
			)
		);
	};

	React.useEffect(() => {
		fetchList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, status, search]);

	React.useEffect(() => {
		return () => {
			dispatch(clearBankDetailsState());
		};
	}, []);
	return (
		<PageContainer
			title=""
			description=""
		>
			<Box
				display={"flex"}
				justifyContent={"end"}
				sx={{
					mx: 5,
					my: 2,
				}}
			>
				<Button
					variant="contained"
					onClick={() => handleOpenDialog()}
				>
					Add new Bank
				</Button>
			</Box>
			<DataTable {...bankDetailsProps} />
			{openDialog && (
				<ManageBankDetails
					open={openDialog}
					data={dialogData}
					onClose={() => handleCloseDialog()}
					onComplete={() => handleComplete()}
				/>
			)}
			<LoadingDialog open={saveLoading} />
		</PageContainer>
	);
};
