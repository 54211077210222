import { LoadState } from "../../../constants/enums";
import {
	ISupplierContact,
	ISupplierContactState,
} from "./supplier-contact.types";

export const defaultSupplierContact: ISupplierContact = {
	supplier_contact_uuid: null,
	name: "",
	supplier_uuid: null,
	supplier_name: "",
	salutation: null,
	designation: null,
	contact_type: null,
	department: null,
	extension: null,
	company_landline: null,
	fax: null,
	dob: null,
	previous_organisation: null,
	skype_id: null,
	executive_location_line1: null,
	executive_location_line2: null,
	executive_location_city: null,
	executive_location_state: null,
	executive_location_pincode: null,
	executive_location_country: null,
	contact_number: null,
	mail_id: "",
	status: "ACTIVE",
	created_by_uuid: "",
	create_ts: "",
	insert_ts: "",
};

export const defaultSupplierContactState: ISupplierContactState = {
	supplier_contact_list: {
		data: [],
		totalRecords: 0,
		loading: LoadState.NotLoaded,
		error: null,
	},
	single_supplier_contact: {
		data: defaultSupplierContact,
		loading: LoadState.NotLoaded,
		error: null,
	},
};
