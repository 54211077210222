import { LoadState } from "../../constants/enums";
import {
	ICustomerDeliveryAddress,
	ICustomerDeliveryAddressState,
} from "./customDeliveryAddress.types";

export const initialCustomerDeliveryAddress: ICustomerDeliveryAddress = {
	company_delivery_address_uuid: "",
	company_uuid: null,
	company_name: "",
	delivery_name: null,
	company_delivery_address_line1: null,
	company_delivery_address_line2: null,
	company_delivery_address_city: null,
	company_delivery_address_state: null,
	company_delivery_address_pincode: null,
	company_delivery_address_country: null,
	status: "ACTIVE",
};
export const initialCustomerDeliveryAddressState: ICustomerDeliveryAddressState =
	{
		list: [],
		totalRecords: 0,
		loading: LoadState.NotLoaded,
		error: null,
		customerDeliveryAddress: initialCustomerDeliveryAddress,
		customDeliveryAddressLoading: LoadState.NotLoaded,
		customDeliveryAddressError: null,
	};
