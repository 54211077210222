import { action } from "typesafe-actions";

import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { IUserDocument } from "./userDocument.types";
import { IStoreState } from "../initialStoreState";
import { api } from "../../api/api";
import {
	saveLoaderCompleted,
	saveLoaderProgress,
	showMessage,
} from "../messages/messagesActions";
import { uploadFile } from "../../helpers/uploadFile";
import { IOrder } from "../orders";

export const FETCH_USER_DOCUMENTS_LIST_PROGRESS =
	"FETCH_USER_DOCUMENTS_LIST_PROGRESS";
export const FETCH_USER_DOCUMENTS_LIST_SUCCESS =
	"FETCH_USER_DOCUMENTS_LIST_SUCCESS";
export const FETCH_USER_DOCUMENTS_LIST_FAILED =
	"FETCH_USER_DOCUMENTS_LIST_FAILED";

export const fetchUserDocumentsListProgress = () =>
	action(FETCH_USER_DOCUMENTS_LIST_PROGRESS);
export const fetchUserDocumentsListSuccess = (data: IUserDocument[]) =>
	action(FETCH_USER_DOCUMENTS_LIST_SUCCESS, { data });
export const fetchUserDocumentsListFailed = () =>
	action(FETCH_USER_DOCUMENTS_LIST_FAILED);

export const fetchUserDocumentsListAsync =
	(
		orders_uuid: string,
		page: number,
		rowsPerPage: number
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
		async (dispatch, getState) => {
			try {
				dispatch(fetchUserDocumentsListProgress());
				const res = await api.get(
					`/externalSupplier/get-order-documents?orders_uuid=${orders_uuid}&pageNo=${page}&itemPerPage=${rowsPerPage}`
				);
				const data: IUserDocument[] = res.data.data;
				dispatch(fetchUserDocumentsListSuccess(data));
			} catch (err: any) {
				dispatch(fetchUserDocumentsListFailed());
				dispatch(
					showMessage({
						type: "error",
						message: err.response.data.message,
						displayAs: "snackbar",
					})
				);
			}
		};

export const fetchInternalUserDocumentsListAsync =
	(
		orders_uuid: string,
		page: number,
		rowsPerPage: number
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
		async (dispatch, getState) => {
			try {
				dispatch(fetchUserDocumentsListProgress());
				const res = await api.get(
					`/order/get-order-documents?orders_uuid=${orders_uuid}&pageNo=${page}&itemPerPage=${rowsPerPage}`
				);
				const data: IUserDocument[] = res.data.data;
				dispatch(fetchUserDocumentsListSuccess(data));
			} catch (err: any) {
				dispatch(fetchUserDocumentsListFailed());
				dispatch(
					showMessage({
						type: "error",
						message: err.response.data.message,
						displayAs: "snackbar",
					})
				);
			}
		};

// export const addUserDocumentsAsync =
// 	(
// 		data: IUserDocument,
// 		file: any | null,
// 		onCallback: (isSuccess: boolean, path?: string) => void
// 	): ThunkAction<void, IStoreState, {}, AnyAction> =>
// 	async (dispatch, getState) => {
// 		try {
// 			const { incremental_no, create_ts, insert_ts, rowId, ...rest } = data;
// 			dispatch(saveLoaderProgress());
// 			const asPayload = {
// 				document_type: data.document_type,
// 			};
// 			const path = await uploadFile(
// 				file,
// 				"USER",
// 				data.file_url || "",
// 				asPayload
// 			);
// 			await api.post("/externalSupplier/upload-order-documents", {
// 				...rest,
// 				file_url: path,
// 			});

// 			dispatch(saveLoaderCompleted());
// 			dispatch(
// 				showMessage({
// 					type: "success",
// 					message: "Document saved successfully!",
// 					displayAs: "snackbar",
// 				})
// 			);
// 			onCallback(true, path);
// 		} catch (err: any) {
// 			onCallback(false, "");
// 			dispatch(
// 				showMessage({
// 					type: "error",
// 					message: err.response.data.message,
// 					displayAs: "snackbar",
// 				})
// 			);
// 		}
// 	};

export const addUserDocumentsAsync =
	(
		data: IUserDocument,
		file: File | null,
		onCallback: (isSuccess: boolean) => void,
		isExternalUser?: boolean
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
		async (dispatch) => {
			try {
				const { create_ts, insert_ts, file_url, rowId, ...rest } = data;
				dispatch(saveLoaderProgress());

				// Create FormData object
				const formData = new FormData();

				if (file) {
					formData.append("files", file); // Attach the file
				}

				// Append remaining data fields
				Object.entries(rest).forEach(([key, value]) => {
					if (value !== undefined && value !== null) {
						formData.append(key, value as string);
					}
				});

				await api.post("/externalSupplier/upload-order-document", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});


				dispatch(
					showMessage({
						type: "success",
						message: "Document saved successfully!",
						displayAs: "snackbar",
					})
				);
				onCallback(true);
			} catch (err: any) {
				onCallback(false);
				dispatch(
					showMessage({
						type: "error",
						message: err.response?.data?.message || "Failed to upload document",
						displayAs: "snackbar",
					})
				);
			} finally {
				dispatch(saveLoaderCompleted());
			}
		};

export const UpsertOrderItemsForExternalSupplierAsync = (
	orders_uuid: string,
	data: IOrder["order_item_list"],
	type: "SHIPMENT" | "ORDER_CONFIRMATION",
	onCallback: (isSuccess: boolean) => void
): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch) => {
		try {
			dispatch(saveLoaderProgress());
			// Create FormData object

			await api.post("/externalSupplier/upload-order-document", {
				orders_uuid,
				order_items_list: data,
				type
			});

			dispatch(
				showMessage({
					type: "success",
					message: "Data saved successfully!",
					displayAs: "snackbar",
				})
			);
			onCallback(true);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response?.data?.message || "Failed to upload document",
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const addInternalUserDocumentsAsync =
	(
		data: IUserDocument,
		file: File | null,
		onCallback: (isSuccess: boolean) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
		async (dispatch) => {
			try {
				const { create_ts, insert_ts, file_url, rowId, ...rest } = data;
				dispatch(saveLoaderProgress());

				// Create FormData object
				const formData = new FormData();

				if (file) {
					formData.append("files", file); // Attach the file
				}

				// Append remaining data fields
				Object.entries(rest).forEach(([key, value]) => {
					if (value !== undefined && value !== null) {
						formData.append(key, value as string);
					}
				});

				await api.post("/order/upload-order-document", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});

				dispatch(
					showMessage({
						type: "success",
						message: "Document saved successfully!",
						displayAs: "snackbar",
					})
				);
				onCallback(true);
			} catch (err: any) {
				onCallback(false);
				dispatch(
					showMessage({
						type: "error",
						message: err.response?.data?.message || "Failed to upload document",
						displayAs: "snackbar",
					})
				);
			} finally {
				dispatch(saveLoaderCompleted());
			}
		};
