export function formatWithCommas(value: string) {
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// export const getNumericValue = (value: string) => {
// 	return value.replace(/,/g, "");
// };

export const getNumericValue = (value: unknown) => {
	if (typeof value !== "string") {
		return String(value || "").replace(/,/g, "");
	}
	return value.replace(/,/g, "");
};
