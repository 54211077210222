import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../redux/initialStoreState";
import { api } from "../../api/api";
import { showMessage } from "../../redux/messages/messagesActions";
import { IPreviewType } from "./StandardPreview.types";

export const previewAndDownloadAsync =
	(
		uuid: string,
		type: IPreviewType,

		previewOnly: boolean,
		onCallback: (isSuccess: boolean, html?: any) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
		async (dispatch, getState) => {
			try {
				let get_url = "";

				get_url += `orders_uuid=${uuid}`;

				const res = await api.get(
					`/order/get-order-preview?${get_url}&isPreview=${previewOnly}`,
					previewOnly
						? {}
						: {
							responseType: "arraybuffer",
						}
				);

				if (!previewOnly) {
					// Convert the PDF content into a Blob
					const blob = new Blob([res.data], { type: "application/pdf" });

					// Create a temporary URL for the Blob
					const url = window.URL.createObjectURL(blob);

					// Create a link element and simulate a click to trigger the download
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "invoice.pdf"); // Set the filename for the downloaded file
					document.body.appendChild(link);
					link.click();

					// Clean up by revoking the URL object
					window.URL.revokeObjectURL(url);
					document.body.removeChild(link);
				}
				onCallback(true, res.data.data);
			} catch (err: any) {
				dispatch(
					showMessage({
						type: "error",
						message: err.response.data.message,
						displayAs: "snackbar",
					})
				);
				onCallback(false);
			}
		};

export const previewAndDownloadSupplierOrderPdfAsync =
	(
		orderUuid: string,
		previewOnly: boolean,
		onCallback: (isSuccess: boolean, html?: any) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
		async (dispatch, getState) => {
			try {

				const res = await api.get(`/externalSupplier/get-order-preview?orders_uuid=${orderUuid}&isPreview=${previewOnly}`,
					{
						...(!previewOnly&& {responseType: "arraybuffer"}),
					}
				);

				if (!previewOnly) {
					// Convert the PDF content into a Blob
					const blob = new Blob([res.data], { type: "application/pdf" });

					// Create a temporary URL for the Blob
					const url = window.URL.createObjectURL(blob);

					// Create a link element and simulate a click to trigger the download
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "invoice.pdf"); // Set the filename for the downloaded file
					document.body.appendChild(link);
					link.click();

					// Clean up by revoking the URL object
					window.URL.revokeObjectURL(url);
					document.body.removeChild(link);
				}
				onCallback(true, res.data.data);
			} catch (err: any) {
				dispatch(
					showMessage({
						type: "error",
						message: err.response.data.message,
						displayAs: "snackbar",
					})
				);
				onCallback(false);
			}
		};
