import { LoadState } from "../../constants/enums";
import { defaultProductAddOnState } from "./product-addon";
import { defaultProductTemplateState } from "./product-templates";
import { IProduct, IProductsState } from "./products.types";

export const defaultProduct: IProduct = {
	product_uuid: "",
	product_documents_uuid: "",

	product_vpn: null,
	primary_image_url: null,
	preview_url: null,
	barcode: null,
	style: null,
	color: null,
	color_code: null,
	fabric: null,
	size: null,
	description: null,
	category: null,
	class: null,
	subclass: null,
	season: null,
	line: null,
	subline: null,
	gender: null,
	brand: null,
	unit_cost: null,
	rsp: null,
	quantity: null,
	currency: null,
	additional_property: {},
	status: null,
	additional_image_urls: [],
	supplier: "",
	supplier_code: "",
	zone: "",
	area: "",
	season_uda: "",
	recurrence: "",
	po_number: "",
	delivery_start_date: null,
	delivery_end_date: null,
	taxomany: "",
	usage_specificity: "",
	country_manufacture: "",
	country_shipment: "",
	discount: 0,
	mark_up: 0,
	hts_code: "",
	made_of: "",
	comment: "",
};

export const defaultProductsState: IProductsState = {
	list: [],
	totalRecords: 0,
	loading: LoadState.NotLoaded,
	product: defaultProduct,
	productLoading: LoadState.NotLoaded,
	error: null,
	...defaultProductTemplateState,
	...defaultProductAddOnState,
};
