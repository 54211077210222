import React, { useEffect, useState } from "react";

import { useFormik } from "formik";

import { Grid, Typography } from "@mui/material";
import { useDispatchWrapper } from "../../../hooks";
import { Dialog } from "../../../components/Dialogs/Dialog";
import {
	CustomFormLabel,
	CustomTextField,
} from "../../../components/formsComponents";
import { FileUpload } from "../../../components/FileUpload/FileUpload";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { IUserDocument } from "../../../redux/userDocument/userDocument.types";
import {
	addInternalUserDocumentsAsync,
	addUserDocumentsAsync,
} from "../../../redux/userDocument/userDocumentAction";
import { capitalizeUnderScoreWords } from "../../../utils/format-word";

interface IUserDocumentsDialog {
	open: boolean;
	data: IUserDocument;
	isExternal: boolean;
	onClose: () => void;
	onComplete: () => void;
	onFileChange?: (path: string) => void;
	documentTypeArr?: { label: string; value: string }[];
}

export const UserDocumentsDialog: React.FC<IUserDocumentsDialog> = (props) => {
	const {
		open,
		data,
		isExternal,
		onClose,
		onComplete,
		onFileChange,
		documentTypeArr,
	} = props;


	const [saveLoading, setSaveLoading] = React.useState(false);
	const dispatch = useDispatchWrapper();
	const [file, setFile] = useState<File | null>(null);

	const {
		values,
		errors,
		handleChange,
		handleSubmit,
		setFieldValue,
		setValues,
		validateForm,
	} = useFormik({
		initialValues: data,
		validate: (values) => {
			const errors: any = {};
			if (!values.description) {
				errors.description = "Description is requried!";
			} else if (!values.file_url.length && !file) {
				errors.file_url = "File is required!";
			}
			return errors;
		},
		onSubmit: (values) => {
			setSaveLoading(true);
			if (isExternal === true) {
				dispatch(
					addUserDocumentsAsync(
						values,
						file,
						(isSuccess: boolean) => {
							if (isSuccess) {
								onClose();
							} else {
								onClose();
							}

							setSaveLoading(false);
						}
					)
				);
			} else {
				dispatch(
					addInternalUserDocumentsAsync(
						values,
						file,
						(isSuccess: boolean) => {
							if (isSuccess) {
								window.location.reload()
							}
							setSaveLoading(false);
						}
					)
				);
			}
		},
	});

	useEffect(() => {
		validateForm(values);
	}, [file]);

	return (
		<Dialog
			open={open}
			title="Create/Edit Documents"
			onClose={onClose}
			size="sm"
			contentWrappedWithForm={{ onSubmit: handleSubmit }}
			actions={[
				{ type: "button", label: "Close", variant: "text", onClick: onClose },
				{
					type: "submit",
					label: "Save",
					variant: "contained",
					disabled: saveLoading,
				},
			]}
		>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
					md={12}
					display="flex"
					alignItems="center"
gap={2}
				>
					<Typography variant="body1" color="#595959">
						Type
					</Typography>
					<Typography variant="body1" padding={1} fontSize={"0.8rem"} sx={{ background: "#ffcf08", borderRadius: 0.8 }}>
						<>{capitalizeUnderScoreWords(values.type) || "--"}</>
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					md={12}
				>
					<CustomFormLabel>Description</CustomFormLabel>
					<CustomTextField
						name="description"
						variant="outlined"
						size="small"
						type="text"
						fullWidth
						multiline
						rows={4}
						value={values.description}
						onChange={handleChange}
						error={errors.description ? true : false}
						helperText={errors.description}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					md={12}
				>
					<CustomFormLabel>File Upload</CustomFormLabel>
					<FileUpload
						value={values.file_url}
						onChange={(file) => setFile(file)}
					/>
					{errors.file_url && <ErrorMessage value={errors.file_url} />}
				</Grid>
			</Grid>
		</Dialog>
	);
};
