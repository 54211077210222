import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { AuthProvider } from "./contexts/AuthProvider/AuthProvider";
import { LinearProgress } from "@mui/material";
import { MessageBox } from "./components/MessageBox/MessageBox";
import { RolesProvider } from "./security/RolesProvider/RolesProvider";
import { SocketProvider } from "./contexts";
import { BillingCompanyProvider } from "./providers";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<BrowserRouter>
		<Provider store={store}>
			<AuthProvider>
				<SocketProvider>
					<RolesProvider>
						<BillingCompanyProvider>
							<Suspense fallback={<LinearProgress />}>
								<App />
							</Suspense>
						</BillingCompanyProvider>
					</RolesProvider>
					<MessageBox />
				</SocketProvider>
			</AuthProvider>
		</Provider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
