import { LoadState } from "../../constants/enums";

export interface IContactsState {
	list: IContact[];
	totalRecords: number;
	loading: LoadState;
	contact: IContact;
	contactLoading: LoadState;
	error: string | null;
}

export interface IContact {
	contact_uuid: string | null;
	name: string | null;
	company_uuid: string | null;
	company_name: string | null;
	company_branch_uuid: string | null;
	salutation: string | null;
	designation: string | null;
	contact_type: string | null;
	department: string | null;
	extension: string | null;
	company_landline: string | null;
	fax: string | null;
	website: string | null;
	dob: string | null;
	previous_organisation: string | null;
	skype_id: string | null;
	executive_location_line1: string | null;
	executive_location_line2: string | null;
	executive_location_city: string | null;
	executive_location_state: string | null;
	executive_location_pincode: string | null;
	executive_location_country: string | null;
	contact_number: string | null;
	mail_id: string | null;
	status: string;
	created_by_uuid: string | null;
	create_ts?: string;
	insert_ts?: string;
}

export const initialContactState: IContactsState = {
	list: [],
	totalRecords: 0,
	loading: LoadState.NotLoaded,
	contact: {
		contact_uuid: null,
		name: "",
		company_uuid: null,
		company_name: "",
		company_branch_uuid: null,
		salutation: null,
		designation: null,
		contact_type: null,
		department: null,
		extension: null,
		company_landline: null,
		fax: null,
		website: null,
		dob: null,
		previous_organisation: null,
		skype_id: null,
		executive_location_line1: null,
		executive_location_line2: null,
		executive_location_city: null,
		executive_location_state: null,
		executive_location_pincode: null,
		executive_location_country: null,
		contact_number: null,
		mail_id: "",
		status: "ACTIVE",
		created_by_uuid: "",
		create_ts: "",
		insert_ts: "",
	},
	contactLoading: LoadState.NotLoaded,
	error: null,
};
