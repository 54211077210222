import { Box, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from "../Dialogs/Dialog";
import {
	IFileExtension,
	convertBase64ToBlob,
	getFileExtensionType,
} from "./helpers";
import * as XLSX from "xlsx";
import "./RenderHtmlFromBase64.css";

export interface IViewImageDialog {
	open: boolean;
	file: string;
	fileType: IFileExtension;
	onClose: () => void;
}
export const ViewImageDialog: React.FC<IViewImageDialog> = (props) => {
	const { open, file, fileType, onClose } = props;

	return (
		<Dialog
			size={"xl"}
			open={open}
			onClose={onClose}
		>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				width="100%"
			>
				{fileType === "IMAGE" ? (
					<img
						alt="preview"
						src={file}
						style={{ width: "100%", height: "auto" }}
					/>
				) : fileType === "PDF" ? (
					<iframe
						src={file}
						style={{ width: "100%", height: "auto", minHeight: "90vh" }}
					/>
				) : fileType === "EXCEL" ? (
					<iframe
						title="excel-file-view"
						src={`${process.env["REACT_APP_EXCEL_VIEW_URL_PREFIX"]}${process.env["REACT_APP_SERVER_URL"]}/general/get-signed-url?key=${file}`}
						style={{ width: "100%", height: "auto", minHeight: "90vh" }}
					/>
				) : (
					<></>
				)}
			</Box>
		</Dialog>
	);
};

export const RenderHtmlFromBase64: React.FC<{ base64File: string }> = ({
	base64File,
}) => {
	const [__html, setHTML] = React.useState("");
	React.useEffect(() => {
		const reader = new FileReader();
		reader.onload = (evt) => {
			// evt = on_file_select event
			const bstr = evt?.target?.result as ArrayBuffer;
			const workbook = XLSX.read(bstr, { type: "binary" });
			const worksheet_name = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[worksheet_name];
			const data = XLSX.utils.sheet_to_html(worksheet);
			setHTML(data);
		};
		reader.readAsArrayBuffer(convertBase64ToBlob(base64File));
	}, []);
	return (
		<div
			id="table_sheet"
			dangerouslySetInnerHTML={{ __html }}
		/>
	);
};
