import styled from "@emotion/styled";
import { ArrowBack } from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
	Box,
	Breadcrumbs,
	Chip,
	Theme,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomStatus } from "../../../components/customStatus/CustomStatus";
import { IBreadcrumbProps } from "./IBreadcrumbProps";
import { BillingInfoDialog } from "../../../views/dashboard/Dashboard/BillingInfoDialog";
import { useBillingCompanyContext } from "../../../providers";

export const Breadcrumb: React.FC<IBreadcrumbProps> = ({
	subtitle,
	items,
	title,
	children,
}) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
	const { comapny: userBillingInfo } = useBillingCompanyContext();
	const [openCompanyInfoDialog, setCompanyInfoDialog] =
		React.useState<boolean>(false);

	return (
		<Box
			display={"flex"}
			sx={{ mb: 2 }}
		>
			<Box
				sx={(theme: Theme) => ({
					pl: 1,
					pr: 1,
					mr: 1,
					[theme.breakpoints.down("sm")]: { mt: 6 },
					// display: "flex",
					justifyContent: "center",
					alignItems: "center",
				})}
			>
				<ArrowBack
					color="action"
					sx={{ cursor: "pointer" }}
					onClick={() => navigate(-1)}
				/>
			</Box>
			<Box
				sx={{
					display: isDesktop ? "flex" : "flex-column",
					width: "100%",
					justifyContent: "space-between",
				}}
			>
				<Box>
					<Typography
						color="textSecondary"
						fontWeight="400"
						variant="h4"
					>
						{subtitle}
					</Typography>

					<Breadcrumbs
						separator={<NavigateNextIcon fontSize="small" />}
						aria-label="breadcrumb"
						sx={(theme: Theme) => ({
							[theme.breakpoints.down("sm")]: { mt: 6 },
						})}
					>
						{items
							? items.map((item) => (
									<div key={item.title}>
										{item.to ? (
											<StyledBreadcrumb
												component="a"
												href={item.to}
												label={item.title}
											/>
										) : (
											<Typography
												color="textPrimary"
												textTransform={"capitalize"}
											>
												{item.title}
											</Typography>
										)}
									</div>
							  ))
							: ""}
					</Breadcrumbs>
				</Box>
				<Box>
					{userBillingInfo.company_name && isDesktop && (
						<Box
							sx={{ cursor: "pointer" }}
							onClick={() => setCompanyInfoDialog(true)}
						>
							<CustomStatus value={userBillingInfo?.company_name} />
						</Box>
					)}

					{userBillingInfo.company_name && isMobile && (
						<Box
							sx={{
								display: "flex",
								gap: 2,
								backgroundColor: "primary.main",
								color: "#fff",
								fontWeight: 600,
								paddingX: 2,
								paddingY: 1,
								my: 2,
								mr: 1,
								borderRadius: 1,
							}}
							onClick={() => setCompanyInfoDialog(true)}
						>
							<Typography>
								Cmpany: {userBillingInfo?.company_name || "--"}
							</Typography>
							{/* <Typography>
                GST In:{" "}
                {userBillingInfo?.customer_branch_gst_in ||
                  "--"}
              </Typography> */}
						</Box>
					)}
				</Box>
			</Box>
			{openCompanyInfoDialog && (
				<BillingInfoDialog
					open={openCompanyInfoDialog}
					onClose={() => setCompanyInfoDialog(false)}
				/>
			)}
		</Box>
	);
};

const StyledBreadcrumb = styled(Chip)(({ theme }: any) => {
	return {
		backgroundColor: theme.palette.primary.main,
		height: theme.spacing(3),
		color: "#fff",
		mt: 3,
		fontWeight: theme.typography.fontWeightRegular,
		// textTransform: "capitalize",
	};
}) as typeof Chip;
