import { defaultCustomer } from "../../redux/customer";
import { ICustomer } from "../../redux/customer";

export const LOCAL_KEY_BILLING_INFO = "billing_info";

export const getBillingCompanyFromStorage = (): ICustomer => {
	const OutletInfo = localStorage.getItem(LOCAL_KEY_BILLING_INFO);
	return OutletInfo ? JSON.parse(OutletInfo) : (defaultCustomer as ICustomer);
};

export const saveBillingCompanyInStorage = (customer_info: ICustomer) => {
	localStorage.setItem(LOCAL_KEY_BILLING_INFO, JSON.stringify(customer_info));
};
