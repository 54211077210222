import React, { useEffect } from "react";
import { ISocket } from "./SocketProvider.types";
import { useAuth } from "../AuthProvider/AuthProvider";
import { SocketContext } from "./SocketProvider.context";
import { LinearProgress, Typography } from "@mui/material";
import { initializeSocket } from "./SocketProvider.utils";
import { LinesheetDocumetAlert } from "./components/LinesheetDocumetAlert";

export const SocketProvider: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const [appSocket, setAppSocket] = React.useState<ISocket>(null);
  const [loading, setLoading] = React.useState(false);
  const { userInfo } = useAuth();
  const { user: { user_uuid } } = userInfo;


  useEffect(() => {
    if (!user_uuid) return;
    const newSocket = initializeSocket(user_uuid);
    newSocket.on("connect", () => {
      setAppSocket(newSocket);
      console.log("✅ Socket connected:", newSocket.connected, newSocket.id);
    });
    newSocket.on("disconnect", () => {
      console.log("❌ Socket disconnected");
    });

    return () => {
      newSocket.disconnect(); // ✅ Cleanup on unmount
    }
  }, [user_uuid]);

  if ((user_uuid && !appSocket) || loading) return <LinearProgress />;

  return (
    <SocketContext.Provider value={{ socket: appSocket }}>
      <LinesheetDocumetAlert />
      {props.children}
    </SocketContext.Provider>
  );
};
