import { IUser } from "../components/AutoCompleteSearches/AutoCompleteSearches.types";
import { ISecurityRoleGroup } from "../components/AutoCompleteSearches/SecurityRoleGroupAutoSearch";
import { ICTTemplate } from "../redux/ict";
import { IProductTemplate } from "../redux/products";
import { IProduct } from "../redux/products/products.types";
import { IQuote } from "../redux/quote";
import { ISupplier } from "../redux/supplier";
import { ISupplierContact } from "../redux/supplier/supplier-contact";
import { ITemplate } from "../redux/templates";

type IRemoveDuplicateItems =
  | IProduct
  | IUser
  | IQuote
  | IProductTemplate
  | ISecurityRoleGroup
  | ISupplier
  | ISupplierContact
  | ITemplate
  | ICTTemplate

export const removeDuplicateItems = (
  options: IRemoveDuplicateItems[],
  key: string,
) => {
  // return options.filter((item, index) => options.indexOf(item) === index);
  //@ts-ignore
  return [...new Map(options.map((item) => [item[key], item])).values()];
};

export const removeDuplicateUsers = (options: IUser[]) => {
  return options.filter(
    (item, index) =>
      options.find((item2) => item2.user_uuid === item.user_uuid)?.user_uuid !==
      item.user_uuid,
  );
};
