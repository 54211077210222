import React from 'react'
import { Dialog } from '../../components/Dialogs/Dialog';
import { getFileExtensionType, PreviewExcelFile, PreviewPdfFile } from '../FileUploadWithAI/components/PreviewPdfFile';

interface IPreviewOrderFileDialogProps {
  open: boolean;
  fileUrl: string;
  onClose: () => void;
}
export const PreviewOrderFileDialog: React.FC<IPreviewOrderFileDialogProps> = ({
  open,
  fileUrl,
  onClose
}) => {

  return (
    <Dialog size="xl" fullScreen open={open} onClose={onClose}>
      {getFileExtensionType(fileUrl) === "PDF" ?
        <PreviewPdfFile filePath={fileUrl} />
        :
        <PreviewExcelFile filePath={fileUrl} />
      }
    </Dialog>
  )
}
