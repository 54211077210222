import { initialCustomerDeliveryAddress } from "../../redux/customerDeliveryAddress";
import { fetchBillingCompanyAddressesInfoAsync } from "./BillingCompanyProvider.services";
import {
	getBillingCompanyFromStorage,
	saveBillingCompanyInStorage,
} from "./BillingCompanyProvider.storage";
import { IBillingCompanyContextState } from "./BillingCompanyProvider.types";

export const checkIfUserHasValidBillingCompany = async (
	comapnyInfo: IBillingCompanyContextState["comapny"][],
	isAdmin: boolean
): Promise<IBillingCompanyContextState | null> => {
	if (comapnyInfo.length > 0) {
		const currentComapnyInfo = getBillingCompanyFromStorage();
		if (currentComapnyInfo && isAdmin) {
			const hasValidComapny = comapnyInfo.find((company) => company.company_uuid === currentComapnyInfo.company_uuid);
			if (hasValidComapny) {
				const billing_address = await fetchBillingCompanyAddressesInfoAsync(comapnyInfo[0].company_uuid) || initialCustomerDeliveryAddress
				saveBillingCompanyInStorage(hasValidComapny);
				return { comapny: hasValidComapny, delivery_address: billing_address };
			}
			const billing_address = await fetchBillingCompanyAddressesInfoAsync(comapnyInfo[0].company_uuid) || initialCustomerDeliveryAddress
			saveBillingCompanyInStorage(comapnyInfo[0]);
			return { comapny: comapnyInfo[0], delivery_address: billing_address };
		}
		const billing_address = await fetchBillingCompanyAddressesInfoAsync(comapnyInfo[0].company_uuid) || initialCustomerDeliveryAddress
		saveBillingCompanyInStorage(comapnyInfo[0]);
		return { comapny: comapnyInfo[0], delivery_address: billing_address };
	}
	return null;
};

// export const checkIfUserHasValidBillingCompanyV2 = (
// 	comapnyInfo: IBillingCompanyContextState["comapny"][]
// ): IBillingCompanyContextState | null => {
// 	if (comapnyInfo.length > 0) {
// 		saveBillingCompanyInStorage(comapnyInfo[0]);
// 		return { comapny: comapnyInfo[0] };
// 	}
// 	return null;
// };
