import { LoadState } from "../../constants/enums";
import { IBankDetails, IBankDetailsState } from "./bankDetails.types";

export const initialBankDetails: IBankDetails = {
	company_bank_details_uuid: null,
	company_uuid: "",
	company_name: "",
	bank_name: "",
	branch: null,
	account_no: "",
	ifsc_code: null,
	status: "ACTIVE",
};

export const initialBankDetailsState: IBankDetailsState = {
	list: [],
	totalRecords: 0,
	loading: LoadState.NotLoaded,
	BankDetails: initialBankDetails,
	BankDetailsLoading: LoadState.NotLoaded,
	error: null,
};
