import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { showMessage } from "../../messages/messagesActions";
import { IQueryParams } from "../../common/common.types";
import { getSearchQuery } from "../../common/helpers";
import {
	ISupplierBankDetails,
	ISupplierBankDetailsState,
} from "./supplier-bank-details.types";

export const FETCH_BANK_DETAILS_LIST_PROGRESS =
	"FETCH_BANK_DETAILS_LIST_PROGRESS";
export const FETCH_BANK_DETAILS_LIST_SUCCESS =
	"FETCH_BANK_DETAILS_LIST_SUCCESS";
export const FETCH_BANK_DETAILS_LIST_FAILED = "FETCH_BANK_DETAILS_LIST_FAILED";

export const fetchBankDetailsListProgress = () =>
	action(FETCH_BANK_DETAILS_LIST_PROGRESS);
export const fetchBankDetailsListSuccess = (
	data: ISupplierBankDetailsState["supplier_bank_details_list"]["data"],
	totalRecords: number
) => action(FETCH_BANK_DETAILS_LIST_SUCCESS, { data, totalRecords });
export const fetchBankDetailsListFailed = () =>
	action(FETCH_BANK_DETAILS_LIST_FAILED);

export const fetchBankDetailsListAsync =
	(queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchBankDetailsListProgress());
			const searchQuery = getSearchQuery(queryParams);
			const res = await api.get(
				`/supplier/get-supplier-bank-details${searchQuery}`
			);
			const data: ISupplierBankDetails[] = res.data.data;
			const totalRecords = res.data.totalRecords;

			dispatch(fetchBankDetailsListSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchBankDetailsListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const FETCH_SUPPLIER_BANK_DETAILS_LIST_PROGRESS =
	"FETCH_SUPPLIER_BANK_DETAILS_LIST_PROGRESS";
export const FETCH_SUPPLIER_BANK_DETAILS_LIST_SUCCESS =
	"FETCH_SUPPLIER_BANK_DETAILS_LIST_SUCCESS";
export const FETCH_SUPPLIER_BANK_DETAILS_LIST_FAILED =
	"FETCH_SUPPLIER_BANK_DETAILS_LIST_FAILED";

export const fetchSupplierBankDetailsListProgress = () =>
	action(FETCH_SUPPLIER_BANK_DETAILS_LIST_PROGRESS);
export const fetchSupplierBankDetailsListSuccess = (
	data: ISupplierBankDetailsState["supplier_bank_details_list"]["data"],
	totalRecords: number
) => action(FETCH_SUPPLIER_BANK_DETAILS_LIST_SUCCESS, { data, totalRecords });
export const fetchSupplierBankDetailsListFailed = () =>
	action(FETCH_SUPPLIER_BANK_DETAILS_LIST_FAILED);

export const fetchSupplierBankDetailsListAsync =
	(
		queryParams: IQueryParams,
		customerUUID?: string
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchSupplierBankDetailsListProgress());
			const searchQuery = getSearchQuery(queryParams);
			const res = await api.get(
				`/supplier/get-supplier-bank-details${searchQuery}&supplier_uuid=${customerUUID}`
			);
			const data: ISupplierBankDetails[] = res.data.data;
			const totalRecords = res.data.totalRecords;

			dispatch(fetchSupplierBankDetailsListSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchSupplierBankDetailsListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const upsertSupplierBankDetailsAsync =
	(
		data: ISupplierBankDetails,
		// file: any | null,
		onCallback: (isSuccess: boolean, BankDetails?: ISupplierBankDetails) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const { create_ts, insert_ts, ...rest } = data;
			const res = await api.post(
				"/supplier/upsert-supplier-bank-details",
				rest
			);
			let message = "Bank Details saved successfully!";
			onCallback(true, res.data.data);
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const CLEAR_SINGLE_BANK_DETAILS = "CLEAR_SINGLE_BANK_DETAILS";
export const clearSingleBankDetailsSync = () =>
	action(CLEAR_SINGLE_BANK_DETAILS);
export const CLEAR_BANK_DETAILS_LIST = "CLEAR_BANK_DETAILS_LIST";
export const clearBankDetailsListSync = () => action(CLEAR_BANK_DETAILS_LIST);
