import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
	saveLoaderCompleted,
	saveLoaderProgress,
	showMessage,
} from "../messages/messagesActions";
import { IProduct } from "./products.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { uploadFile, uploadMultipleFiles } from "../../helpers/uploadFile";
import { IFileUpload } from "../../components/FileUpload/FileUpload.type";
import { getUniqueId } from "../../helpers";
import { IQueryParams } from "../common/common.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { getSearchQuery } from "../common/helpers";

export const FETCH_PRODUCTS_LIST_PROGRESS = "FETCH_PRODUCTS_LIST_PROGRESS";
export const FETCH_PRODUCTS_LIST_SUCCESS = "FETCH_PRODUCTS_LIST_SUCCESS";
export const FETCH_PRODUCTS_LIST_FAILED = "FETCH_PRODUCTS_LIST_FAILED";

export const fetchProductsListProgress = () =>
	action(FETCH_PRODUCTS_LIST_PROGRESS);
export const fetchProductsListSuccess = (
	data: IProduct[],
	totalRecords: number
) => action(FETCH_PRODUCTS_LIST_SUCCESS, { data, totalRecords });
export const fetchProductsListFailed = () => action(FETCH_PRODUCTS_LIST_FAILED);

export const fetchProductsListAsync =
	(
		queryParams: IQueryParams,

		searchValue?: IDatatableV2AdvancedSearchFilter
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchProductsListProgress());
			const searchQuery = getSearchQuery(queryParams);
			let url = `/product/get-product${searchQuery}`;
			if (searchValue && searchValue.length > 0) {
				url += `&advanceFilter=${JSON.stringify(searchValue)}`;
			}
			const res = await api.get(url);
			const data: IProduct[] = res.data.data;

			const totalRecords = res.data.totalRecords;

			dispatch(fetchProductsListSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchProductsListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const upsertSingleProductsAsync =
	(
		data: IProduct,
		file: any | null,
		onCallback: (isSuccess: boolean, product?: IProduct) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(saveLoaderProgress());
			const { ...rest } = data;
			const asPayload = {
				product_unique_no: data.line,
			};
			const paths = await uploadMultipleFiles(
				data.additional_image_urls,
				"PRODUCT",
				asPayload as any
			);
			const payload = {
				...rest,
				additional_image_urls: paths,
			};
			const res = await api.post("/product/upsert-product", payload);
			dispatch(
				showMessage({
					type: "success",
					message: "Item save successfully!",
					displayAs: "snackbar",
				})
			);
			onCallback(true, res.data.data);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const FETCH_PRODUCT_PROGRESS = "FETCH_PRODUCT_PROGRESS";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILED = "FETCH_PRODUCT_FAILED";

export const fetchProductProgress = () => action(FETCH_PRODUCT_PROGRESS);
export const fetchProductSuccess = (data: IProduct) =>
	action(FETCH_PRODUCT_SUCCESS, { data });
export const fetchProductFailed = (errorMessage: string) =>
	action(FETCH_PRODUCT_FAILED, { errorMessage });

export const fetchSingleProductAsync =
	(id: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchProductProgress());
			const res = await api.get(
				`/product/get-product?product_uuid=${id}&pageNo=1&itemPerPage=1`
			);
			const data: IProduct[] = res.data.data;
			if (data.length > 0) {
				const attachments: IFileUpload[] = [];
				if (data[0].preview_url) {
					attachments.push({
						file: null,
						key: getUniqueId(),
						path: data[0].preview_url,
					});
				}

				for (const item of (data[0].additional_image_urls || []) as any) {
					attachments.push({
						file: null,
						key: getUniqueId(),
						path: item,
					});
				}
				dispatch(
					fetchProductSuccess({
						...data[0],
						additional_image_urls: attachments,
					})
				);
			} else {
				dispatch(fetchProductFailed("Oops! We couldn't find any records."));
			}
		} catch (err: any) {
			dispatch(fetchProductFailed("Something went to be wrong!"));
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const extendProductExpiry =
	(
		productUUID: string,
		onCallback: (isSuccess: boolean, date?: string) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const res = await api.put(`/quotes/extend-product-expiry`, {
				product_uuid: productUUID,
			});

			const data = res.data.data;
			if (data.length > 0) {
				onCallback(true, data);
			}
		} catch (err: any) {
			dispatch(fetchProductFailed("Something went to be wrong!"));
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const CLEAR_PRODUCT = "CLEAR_PRODUCT";
export const CLEAR_PRODUCT_STATE = "CLEAR_PRODUCT_STATE";
export const clearSingleProductSync = () => action(CLEAR_PRODUCT);
export const clearProductState = () => action(CLEAR_PRODUCT_STATE);
