import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { IStoreState } from "../initialStoreState";
import {
	saveLoaderCompleted,
	saveLoaderProgress,
	showMessage,
} from "../messages/messagesActions";
import { IQuote, IUpsertQuote } from "./quote.types";

export const FETCH_QUOTE_LIST_PROGRESS = "FETCH_QUOTE_LIST_PROGRESS";
export const FETCH_QUOTE_LIST_SUCCESS = "FETCH_QUOTE_LIST_SUCCESS";
export const FETCH_QUOTE_LIST_FAILED = "FETCH_QUOTE_LIST_FAILED";

export const fetchQuotesListProgress = () => action(FETCH_QUOTE_LIST_PROGRESS);
export const fetchQuotesListSuccess = (list: IQuote[], totalRecords: number) =>
	action(FETCH_QUOTE_LIST_SUCCESS, { list, totalRecords });
export const fetchQuotesListFailed = () => action(FETCH_QUOTE_LIST_FAILED);

export const fetchQuotesListAsync =
	(
		pageNumber: number,
		rowsInPerPage: number,
		status: string,
		date: IDate,
		searchValue: string,
		searchType: string[]
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchQuotesListProgress());
			let url = "";
			if (status !== "-1") {
				url = "&status=" + status;
			}
			let finalUrl = `/quotes/get-quotes?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
			if (searchType.length > 0 && searchValue) {
				finalUrl = `/quotes/get-quotes?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&columns=${[
					searchType,
				]}&value=${searchValue}`;
			}

			const res = await api.get(finalUrl);
			const data: IQuote[] = res.data.data.result;
			const totalRecords = res.data.totalRecords;

			dispatch(fetchQuotesListSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchQuotesListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const FETCH_QUOTE_PROGRESS = "FETCH_QUOTE_PROGRESS";
export const FETCH_QUOTE_SUCCESS = "FETCH_QUOTE_SUCCESS";
export const FETCH_QUOTE_FAILED = "FETCH_QUOTE_FAILED";

export const fetchQuoteProgress = () => action(FETCH_QUOTE_PROGRESS);
export const fetchQuoteSuccess = (data: IQuote) =>
	action(FETCH_QUOTE_SUCCESS, { data });
export const fetchQuoteFailed = (errorMessage: string) =>
	action(FETCH_QUOTE_FAILED, { errorMessage });

export const fetchQuoteAsync =
	(
		quoteId: string,
		quoteRevision: string
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchQuoteProgress());
			const res = await api.get(
				`/quotes/get-quotes?quote_uuid=${quoteId}&quote_revision=${quoteRevision}`
			);
			const data: IQuote[] = res.data.data.result;
			if (data.length > 0) {
				dispatch(fetchQuoteSuccess(data[0]));
			} else {
				dispatch(fetchQuoteFailed("Oops! We couldn't find any records."));
			}
		} catch (err: any) {
			dispatch(fetchQuoteFailed("Something went to be wrong!"));
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const fetchPublicQuoteAsync =
	(quoteId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchQuoteProgress());
			const res = await api.get(
				`/quotes/get-single-quote?quote_uuid=${quoteId}`
			);
			const data: IQuote = res.data.data.quote;
			if (data) {
				dispatch(fetchQuoteSuccess(data));
			} else {
				dispatch(fetchQuoteFailed("Oops! We couldn't find any records."));
			}
		} catch (err: any) {
			dispatch(fetchQuoteFailed("Something went to be wrong!"));
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const upsertQuoteAsync =
	(
		existingQuote: IQuote,
		onCallback: (isSuccess: boolean, quoteData?: IQuote) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const payload: IUpsertQuote = {
				quote_no: existingQuote.quote_no,
				quote_revision: existingQuote.quote_revision,
				enquiry_no: existingQuote.enquiry_no,
				approved_by_uuid: existingQuote.approved_by_uuid,
				approved_by_name: existingQuote.approved_by_name,
				quote_items: existingQuote.quote_items,
				quote_uuid: existingQuote.quote_uuid,
				quotation_type: existingQuote.quotation_type,
				payment_term: existingQuote.payment_term,
				payment_mode: existingQuote.payment_mode,
				remark: existingQuote.remark,
				region: existingQuote.region,
				advising_bank: existingQuote.advising_bank,
				sales_type: existingQuote.sales_type,
				// approved_flag: existingQuote.approved_flag,
				// approved_by: existingQuote.approved_by,
				full_amount: existingQuote.full_amount,

				discount: existingQuote.discount,
				discounted_value: existingQuote.discounted_value,
				additional_discount: existingQuote.additional_discount,
				additional_discounted_value: existingQuote.additional_discounted_value,
				special_discount: existingQuote.special_discount,
				special_discounted_value: existingQuote.special_discounted_value,
				amount_after_discount: existingQuote.amount_after_discount,

				customs_duty: existingQuote.customs_duty,
				customs_duty_value: existingQuote.customs_duty_value,
				labour_cess: existingQuote.labour_cess,
				labour_cess_value: existingQuote.labour_cess_value,
				IGST: existingQuote.IGST,
				CGST: existingQuote.CGST,
				SGST: existingQuote.SGST,
				tax_amount: existingQuote.tax_amount,
				total_amount_after_tax: existingQuote.total_amount_after_tax,

				roundoff_value: existingQuote.roundoff_value,
				assigned_to_uuid: existingQuote.assigned_to_uuid,
				assigned_to_name: existingQuote.assigned_to_name,
				project_name: existingQuote.project_name,
				status: existingQuote.status,

				origin_from: existingQuote.origin_from,
				area: existingQuote.area,

				inco: existingQuote.inco,
				term_and_condition: existingQuote.term_and_condition,

				currency: existingQuote.currency,
				customer_address_line1: existingQuote.customer_address_line1,
				customer_address_line2: existingQuote.customer_address_line2,
				customer_address_city: existingQuote.customer_address_city,
				customer_address_state: existingQuote.customer_address_state,
				customer_address_country: existingQuote.customer_address_country,
				customer_address_pincode: existingQuote.customer_address_pincode,
				delivery_address_line1: existingQuote.delivery_address_line1,
				delivery_address_line2: existingQuote.delivery_address_line2,
				delivery_address_city: existingQuote.delivery_address_city,
				delivery_address_state: existingQuote.delivery_address_state,
				delivery_address_country: existingQuote.delivery_address_country,
				delivery_address_pincode: existingQuote.delivery_address_pincode,
				company_name: existingQuote.company_name,
				company_uuid: existingQuote.company_uuid,
			};
			dispatch(saveLoaderProgress());
			const { quote_no, project_name, ...rest } = payload;
			// const asPayload = {
			//   quote_no: data.quote_no
			// };
			// const path = await uploadFile(file, "QUOTE", data.filePath, asPayload)
			// const newPayload = { ...rest, filePath: path }
			const res = await api.post("/quotes/upsert-quotes", rest);
			const data: IQuote = res.data.data;
			onCallback(true, data);
			dispatch(
				showMessage({
					type: "success",
					message: "Quotes saved successfully!",
					displayAs: "snackbar",
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

// match product from costing sheet
export const upsertMatchProductAsync =
	(
		quoteUUID: string,
		onCallback: (isSuccess: boolean, quoteData?: IQuote) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(saveLoaderProgress());
			// const { create_ts, project_name, ...rest } = payload;
			const res = await api.post("/quotes/match-products-from-costing-sheet", {
				quote_uuid: quoteUUID,
			});
			const data: IQuote = res.data.data;
			onCallback(true, data);
			dispatch(
				showMessage({
					type: "success",
					message: "Quotes saved successfully!",
					displayAs: "snackbar",
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const quotesPreview =
	(
		quoteUUID: string,
		quoteRevision: string,
		isDescription: boolean,
		onCallback: (isSuccess: boolean, html?: any) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const res = await api.get(
				`/quotes/get-quote-preview?quote_uuid=${quoteUUID}&quote_revision=${quoteRevision}&isPreview=true&is_description=${isDescription}`
			);

			onCallback(true, res.data.data);
		} catch (err: any) {
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
			onCallback(false);
		}
	};

export const quoteDownloadPdf =
	(
		quoteUUID: string,
		quoteRevision: string,
		isDescription: boolean,
		onCallback: (isSuccess: boolean, html?: any) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			// dispatch(saveLoaderProgress());

			const res = await api.get(
				`/quotes/get-quote-preview?quote_uuid=${quoteUUID}&quote_revision=${quoteRevision}&isPreview=false&is_description=${isDescription}`,
				{
					responseType: "arraybuffer",
				}
			);

			dispatch(saveLoaderCompleted());
			// Convert the PDF content into a Blob
			const blob = new Blob([res.data], { type: "application/pdf" });

			// Create a temporary URL for the Blob
			const url = window.URL.createObjectURL(blob);

			// Create a link element and simulate a click to trigger the download
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "invoice.pdf"); // Set the filename for the downloaded file
			document.body.appendChild(link);
			link.click();

			// Clean up by revoking the URL object
			window.URL.revokeObjectURL(url);
			document.body.removeChild(link);

			onCallback(true, res.data.data);
		} catch (err: any) {
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
			onCallback(false);
		}
	};
export const PIPreviewPdf =
	(
		proformaUUID: string,
		isProformaInvoice: boolean,
		onCallback: (isSuccess: boolean, html?: any) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const res = await api.get(
				`/quotes/get-pi-preview-or-pdf?proforma_invoice_uuid=${proformaUUID}&isPreview=true&isProformaInvoice=${isProformaInvoice}`
			);

			onCallback(true, res.data.data);
		} catch (err: any) {
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
			onCallback(false);
		}
	};

export const PIDownloadPdf =
	(
		proformaUUID: string,
		isProformaInvoice: boolean,
		onCallback: (isSuccess: boolean, html?: any) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			// dispatch(saveLoaderProgress());

			const res = await api.get(
				`/quotes/get-pi-preview-or-pdf?proforma_invoice_uuid=${proformaUUID}&isPreview=false&isProformaInvoice=${isProformaInvoice}`,
				{
					responseType: "arraybuffer",
				}
			);

			dispatch(saveLoaderCompleted());
			// Convert the PDF content into a Blob
			const blob = new Blob([res.data], { type: "application/pdf" });

			// Create a temporary URL for the Blob
			const url = window.URL.createObjectURL(blob);

			// Create a link element and simulate a click to trigger the download
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "invoice.pdf"); // Set the filename for the downloaded file
			document.body.appendChild(link);
			link.click();

			// Clean up by revoking the URL object
			window.URL.revokeObjectURL(url);
			document.body.removeChild(link);

			onCallback(true, res.data.data);
		} catch (err: any) {
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
			onCallback(false);
		}
	};
export const SODPreviewPdf =
	(
		proformaUUID: string,
		onCallback: (isSuccess: boolean, html?: any) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const res = await api.get(
				`/quotes/get-sod-preview-or-pdf?proforma_invoice_uuid=${proformaUUID}&isPreview=true`
			);

			onCallback(true, res.data.data);
		} catch (err: any) {
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
			onCallback(false);
		}
	};

export const SODDownloadPdf =
	(
		proformaUUID: string,
		onCallback: (isSuccess: boolean, html?: any) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			// dispatch(saveLoaderProgress());

			const res = await api.get(
				`/quotes/get-sod-preview-or-pdf?proforma_invoice_uuid=${proformaUUID}&isPreview=false`,
				{
					responseType: "arraybuffer",
				}
			);

			dispatch(saveLoaderCompleted());
			// Convert the PDF content into a Blob
			const blob = new Blob([res.data], { type: "application/pdf" });

			// Create a temporary URL for the Blob
			const url = window.URL.createObjectURL(blob);

			// Create a link element and simulate a click to trigger the download
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "invoice.pdf"); // Set the filename for the downloaded file
			document.body.appendChild(link);
			link.click();

			// Clean up by revoking the URL object
			window.URL.revokeObjectURL(url);
			document.body.removeChild(link);

			onCallback(true, res.data.data);
		} catch (err: any) {
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
			onCallback(false);
		}
	};

// uplaod quote pdf

export const CLEAR_QUOTE = "CLEAR_QUOTE";
export const CLEAR_QUOTE_STATE = "CLEAR_QUOTE_STATE";
export const clearQuote = () => action(CLEAR_QUOTE);
export const clearQuoteState = () => action(CLEAR_QUOTE_STATE);
