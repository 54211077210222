import { LoadState } from "../../constants/enums";
import { IProformaInvoice } from "./proformaInvoice.types";
import {
	IQuote,
	IQuoteLayout,
	IQuoteLayoutItem,
	IQuoteState,
} from "./quote.types";

export const defaultQuoteLayoutItem: IQuoteLayoutItem = {
	id: 1,
	product_uuid: "",
	product_name: "",
	customer_product_name: "",
	product_type: "",
	item_pi_status: "ACTIVE",
	product_code: "",
	description: "",
	code_no: "",
	length: null,
	width: null,
	height: null,
	metal: "",
	wood: "",
	acrylic: "",
	quantity: 100,
	price: 0,
	discount: 0,
	total: 0,
	attachment: "",
	comment: "",
	product_add_ons: [],
};

export const defaultQuoteLayout: IQuoteLayout = {
	title: "Layout",
	description: "",
	items: [defaultQuoteLayoutItem],
	attachment: "",
	discount_average: 0,
	price: 0,
	total: 0,
	total_price: 0,
};

export const defaultQuote: IQuote = {
	enquiry_no: "",
	enquiry_uuid: "",
	quote_no: "",
	quote_revision: "0",
	quote_items: [defaultQuoteLayout],
	quote_uuid: "",
	// quote_no: "",
	quotation_type: null,
	payment_term: [{ key: null, value: null }],
	payment_mode: null,
	remark: null,
	region: null,
	advising_bank: null,
	sales_type: null,
	approved_flag: null,
	approved_by: null,
	approved_by_name: null,
	approved_by_uuid: null,
	discount: null,
	full_amount: null,
	discounted_value: null,
	roundoff_value: null,
	tax_amount: null,
	total_amount_after_tax: null,
	assigned_to_uuid: null,
	assigned_to_name: null,
	amount_after_discount: null,
	requested_by_uuid: null,
	approval_uuid: null,

	customer_address_line1: "",
	customer_address_line2: "",
	customer_address_city: "",
	customer_address_state: "",
	customer_address_pincode: "",
	customer_address_country: "",
	delivery_address_line1: "",
	delivery_address_line2: "",
	delivery_address_city: "",
	delivery_address_state: "",
	delivery_address_pincode: "",
	delivery_address_country: "",
	company_name: "",
	customer_type: "",
	sign_off_url: null,
	project_name: null,
	status: "REQUEST_FOR_QUOTE",
	created_by_uuid: "",

	origin_from: null,
	area: null,
	IGST: null,
	CGST: null,
	SGST: null,
	inco: null,
	term_and_condition: null,
	additional_discount: null,
	additional_discounted_value: null,
	special_discount: null,
	special_discounted_value: null,
	customs_duty: null,
	customs_duty_value: null,
	labour_cess: null,
	labour_cess_value: null,
	currency: null,
	company_uuid: null,
	// no need to pass it while upserting quote
	// costing_sheet_uuid: null
};

export const defaultProformaInvoice: IProformaInvoice = {
	...defaultQuote,
	proforma_invoice_uuid: "",
};

export const defaultQuoteState: IQuoteState = {
	list: [],
	totalRecords: 0,
	loading: LoadState.NotLoaded,
	quote: defaultQuote,
	quoteLoading: LoadState.NotLoaded,
	error: null,

	quotePIList: {
		data: [],
		loading: LoadState.NotLoaded,
		totalRecords: 0,
		error: null,
	},
	quotePI: {
		data: defaultProformaInvoice,
		loading: LoadState.NotLoaded,
		error: null,
	},
	allPIList: {
		data: [],
		loading: LoadState.NotLoaded,
		totalRecords: 0,
		error: null,
	},
};
