import {
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Skeleton,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Dialog } from "../../../components/Dialogs/Dialog";
import { useDispatchWrapper } from "../../../hooks";

import { ICustomer, fetchCustomerListAsync } from "../../../redux/customer";

import { IStoreState } from "../../../redux/initialStoreState";
import { saveBillingCompanyInStorage } from "../../../providers/BillingCompanyProvider/BillingCompanyProvider.storage";

interface ISelectCompanyInfoProps {
	open: boolean;
	onClose: () => void;
}

export const BillingInfoDialog: React.FC<ISelectCompanyInfoProps> = (props) => {
	const { open, onClose } = props;

	const {
		data: billingCompanyList,
		totalRecords,
		loading: billingCompanyLoading,
	} = useSelector((storeState: IStoreState) => storeState.customer.list);

	const dispatch = useDispatchWrapper();
	const defaultSearchQuery = {
		status: "ACTIVE",
		page: 1,
		rowsPerPage: 100,
		columns: [],
		value: "",
	};

	React.useEffect(() => {
		dispatch(fetchCustomerListAsync(defaultSearchQuery));
	}, []);

	const handleSelectBillingCompany = (company: ICustomer) => {
		if (company) {
			saveBillingCompanyInStorage(company);
			window.location.reload()
		}
		onClose();
	};

	return (
		<>
			<Dialog
				open={open}
				title="Select Company Info"
				onClose={onClose}
				size="sm"
			>
				{billingCompanyLoading === 1 ? (
					<>
						<Skeleton />
						<Skeleton />
					</>
				) : (
					<List>
						{billingCompanyList.map((company) => (
							<>
								<ListItem disablePadding>
									<ListItemButton
										onClick={() => handleSelectBillingCompany(company)}
									>
										<ListItemText primary={company.company_name} />
									</ListItemButton>
								</ListItem>
							</>
						))}
					</List>
				)}
			</Dialog>
		</>
	);
};
