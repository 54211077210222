export enum LoadState {
	NotLoaded = 0,
	InProgress = 1,
	Loaded = 2,
	Failed = 3,
}

export enum SaveState {
	NotStarted = 0,
	Progress = 1,
	Saved = 2,
}

export enum USER_ROLES {
	ADMIN = 1,
	CSR = 2,
	PRODUCER = 3,
	MARKETER = 4,
	BRANCH_MANAGER = 5,
}

export enum TASK_SUBTYPE {
	VEHICLE_CHANGE = "Vehicle Change",
	DRIVER_CHANGE = "Driver Change",
	COVERAGE_CHANGE = "Coverage Change",
	GARAGE_PLATE_CHANGE = "Garage Plate Change",
	INTERESTED_PARTY_CHANGE = "Interested Party Change",
	LESSOR_CHANGE = "Lessor Change",
	ADDRESS_CHANGE = "Address Change",
	RISK_LOCATION_CHANGE = "Risk Location Change",
	CANCELLATION_REINSTALLMENT = "Cancellation/Reinstatement",
	BUSINESS_SUBMISSION = "Business Submission",
	GENERAL_CHANGE = "General Change",
	PAYMENT_AUTH_CHANGE = "Payment Authorization Change",
	LAO_BOR = "LAO/BOR",
	NEW_BUSINESS = "Business",
	NEW_PERSONAL_AUTO = "Personal Auto",
	NEW_HOME = "Home",
	NEW_COMMERCIAL_AUTO = "Commercial Auto",
	REMARKET_COMMERCIAL_AUTO = "Commercial Auto",
	REMARKET_HOME = "Home",
	REMARKET_BUSINESS = "Business",
	REMARKET_PERSONAL_AUTO = "Personal Auto",
}

export enum POLICIES {
	BUSINESS_POLICY = "Business Policy",
	AUTOMOBILE_POLICY = "Automobile Policy",
	HABITATIONAL_POLICY = "Habitational Policy",
	COMMERCIAL_AUTO_POLICY = "Commercial Auto Policy",
	LIFE_AND_FINCANCIAL_POLICY = "Life & Fincancial Policy",
}

export enum POLICIES_NAMES {
	BUSINESS_POLICY = "Business",
	AUTOMOBILE_POLICY = "Automobile",
	HABITATIONAL_POLICY = "Habitational",
	COMMERCIAL_AUTO_POLICY = "Commercial Auto",
	LIFE_AND_FINCANCIAL_POLICY = "Life & Fincancial",
}

export enum ERROR_MESSAGES {
	"SERVER_ERROR" = "Someting went to be wrong!",
	"POLICY_NOT_FOUND" = "Oops! We couldn't find any records at the moment. Please ensure that the provided information is accurate and try again.",
}

export enum MODULE_IDS {
	TASK = "latest_tasks",
	TEMPLATE = "TEMPLATE_TEMPLATE_LATEST_TEMPLATE",
	PRODUCT = "PRODUCT_PRODUCT_LATEST_PRODUCT",
	ORDER = "ORDER_ORDER_LATEST_ORDERS",
	USERS = "USERS_USERS_LATEST_USER",
	ROLE = "USERS_CHANGE ROLE_CHANGE_ROLE",
	SECURITY = "SECURITY_SECURITY_ROLE_MODULE",
	ROLE_GROUP = "SECURITY_ROLE GROUP_LATEST_ROLE_GROUP",
	BRANCH = "DATA MANAGEMENT_BRANCH_LATEST_BRANCH",
	ZONE = "DATA MANAGEMENT_ZONE_ZONE",
	SUPPLIER = "SUPPLIER_SUPPLIER_LATEST_SUPPLIER",
	CONTACT = "SUPPLIER_CONTACT_LATEST_CONTACT",
	BANK_DETAILS = "SUPPLIER_BANK DETAILS_LATEST_BANK_DETAILS",
	SUPPLIER_BRANCH = "SUPPLIER_BRANCH_LATEST_SUPPLIER_BRANCH",
	SUPPLIER_DELIVERY_ADDRESS = "SUPPLIER_DELIVERY ADDRESS_LATEST_SUPPLIER_DELIVERY_ADDRESS",
	SUPPLIER_ATTACHMENT = "SUPPLIER_ATTACHMENT_LATEST_SUPPLIER_ATTACHMENT",
	PRODUCT_DOCUMENT = "IMPORTS_IMPORT_LATEST_PRODUCT_DOCUMENTS",
	TEMP_PRODUCT = "IMPORTS_TEMP PRODUCT_LATEST_TEMP_PRODUCT",
	BUDGET = "BUDGET_BUDGET_LATEST_BUDGET",
	BUDGET_LEDGER = "BUDGET_BUDGET LEDGER_LATEST_BUDGET_LEDGER",
	PRODUCT_AND_PROCESS = "IMPORTS_LINESHEET_LATEST_PRODUCT_AND_PROCESS",
	ICT = "ICT",
	COMPANY = "COMPANY_COMPANY_LATEST_CUSTOMER",
	CONTACTS = "",
}

export enum APPLICATION_ROLES {
	SUPPLIER = "SUPPLIER",
}
