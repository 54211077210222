import { LoadState } from "../../../constants/enums";
import { supplier_payment_term_order_status } from "./supplier-payment-terms.constants";
import { ISupplierPaymentTerm, ISupplierPaymentTermState } from "./supplier-payment-terms.types";



export const defaultSupplierPaymentTerm: ISupplierPaymentTerm = {
    supplier_payment_term_uuid: null,
    supplier_uuid: null,
    supplier_name: null,
    term_description: null,
    term_value: null,
    term_notice_period: 0,
    currency: null,
    payment_method: null,
    status: "ACTIVE",
    order_status: null
}

export const defaultSupplierPaymentTermState: ISupplierPaymentTermState = {
    supplier_payment_term_list: {
        data: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error: null,
    },
    supplier_single_payment_term: {
        data: defaultSupplierPaymentTerm,
        loading: LoadState.NotLoaded,
        error: null,
    },
};