import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { ICustomer, ICustomerState, ILoadCustomerData } from "./customer.types";
import {
	saveLoaderCompleted,
	saveLoaderProgress,
	showMessage,
} from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";

export const FETCH_CUSTOMER_LIST_PROGRESS = "FETCH_CUSTOMER_LIST_PROGRESS";
export const FETCH_CUSTOMER_LIST_SUCCESS = "FETCH_CUSTOMER_LIST_SUCCESS";
export const FETCH_CUSTOMER_LIST_FAILED = "FETCH_CUSTOMER_LIST_FAILED";

export const fetchCustomerListProgress = () =>
	action(FETCH_CUSTOMER_LIST_PROGRESS);

export const fetchCustomerListSuccess = (
	data: ICustomerState["list"]["data"],
	totalRecords: number
) => action(FETCH_CUSTOMER_LIST_SUCCESS, { data, totalRecords });
export const fetchCustomerListFailed = () => action(FETCH_CUSTOMER_LIST_FAILED);

export const fetchCustomerListAsync =
	(queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const searchQuery = getSearchQuery(queryParams);
			dispatch(fetchCustomerListProgress());
			const res = await api.get(`/company/get-company${searchQuery}`);
			const data: ICustomerState["list"]["data"] = res.data.data;

			const totalRecords = res.data.totalRecords;

			dispatch(fetchCustomerListSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchCustomerListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const FETCH_CUSTOMER_PROGRESS = "FETCH_CUSTOMER_PROGRESS";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAILED = "FETCH_CUSTOMER_FAILED";

export const fetchCustomerProgress = () => action(FETCH_CUSTOMER_PROGRESS);
export const fetchCustomerSuccess = (data: ICustomer) =>
	action(FETCH_CUSTOMER_SUCCESS, { data });
export const fetchCustomerFailed = (errorMessage: string) =>
	action(FETCH_CUSTOMER_FAILED, { errorMessage });

export const fetchCustomerAsync =
	(uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchCustomerProgress());
			const res = await api.get(
				`/company/get-company?company_uuid=${uuid}&pageNo=1&itemPerPage=1`
			);
			const data: ICustomer[] = res.data.data;
			if (data.length > 0) {
				dispatch(fetchCustomerSuccess(data[0]));
			} else {
				dispatch(fetchCustomerFailed("Oops! We couldn't find any records."));
			}
		} catch (err: any) {
			dispatch(fetchCustomerFailed("Something went to be wrong!"));
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const upsertCustomerAsync =
	(
		data: ICustomer,
		onCallback: (isSuccess: boolean, customer?: ICustomer) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const {
				company_uuid,

				create_ts,
				insert_ts,
				...payload
			} = data;
			dispatch(saveLoaderProgress());
			const res = await api.post("/company/upsert-company", payload);
			let message = "Customer saved successfully!";
			onCallback(true, res.data.data);
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const upsertCustomerDataAsync =
	(
		data: ILoadCustomerData,
		onCallback: (isSuccess: boolean) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const { ...rest } = data;
			await api.post("/customer/load-customer-data", rest);
			let message = "Customer info saved successfully!";
			onCallback(true);
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const CLEAR_CUSTOMER_DATA = "CLEAR_CUSTOMER_DATA";

export const clearCustomerData = () => action(CLEAR_CUSTOMER_DATA);

export const CLEAR_CUSTOMER = "CLEAR_CUSTOMER";
export const CLEAR_CUSTOMER_STATE = "CLEAR_CUSTOMER_STATE";

export const clearCustomer = () => action(CLEAR_CUSTOMER);
export const clearCustomerState = () => action(CLEAR_CUSTOMER_STATE);

// ------------------ old data -----------------

// export const CLEAR_CUSTOMER_STATE = "CLEAR_CUSTOMER_STATE";
// export const RECORD_NOT_FOUND = "RECORD_NOT_FOUND";

// export const ADD_CUSTOMER_SOCIAL = "ADD_CUSTOMER_SOCIAL";
// export const FETCH_CUSTOMER_SOCIAL_PROGRESS = "FETCH_CUSTOMER_SOCIAL_PROGRESS";

// export const ADD_CUSTOMER_REL_ACCOUNT = "ADD_CUSTOMER_REL_ACCOUNT";
// export const FETCH_CUSTOMER_REL_ACCOUNT_PROGRESS =
//   "FETCH_CUSTOMER_REL_ACCOUNT_PROGRESS";

// export const ADD_CUSTOMER_CONSENT = "ADD_CUSTOMER_CONSENT";
// export const FETCH_CUSTOMER_CONSENT_PROGRESS =
//   "FETCH_CUSTOMER_CONSENT_PROGRESS";

// export const FETCH_OPPPRTUNITY_PROGRESS = "FETCH_OPPPRTUNITY_PROGRESS";
// export const ADD_OPPPRTUNITY = "ADD_OPPPRTUNITY";

// export const FETCH_CUSTOMER_FULL_DETAILS_PROGRESS =
//   "FETCH_CUSTOMER_FULL_DETAILS_PROGRESS";
// export const ADD_CUSTOMER_FULL_DETAILS = "ADD_CUSTOMER_FULL_DETAILS";
// export const FETCH_CUSTOMER_FULL_DETAILS_FAILED =
//   "FETCH_CUSTOMER_FULL_DETAILS_FAILED";

// export const FETCH_CUSTOMER_CONSENT_LIST_PROGRESS =
//   "FETCH_CUSTOMER_CONSENT_LIST_PROGRESS";
// export const FETCH_CUSTOMER_CONSENT_LIST_SUCCESS =
//   "FETCH_CUSTOMER_CONSENT_LIST_SUCCESS";
// export const FETCH_CUSTOMER_CONSENT_LIST_FAILED =
//   "FETCH_CUSTOMER_CONSENT_LIST_FAILED";

// export const FETCH_CUSTOMER_GROUP_LIST_PROGRESS =
//   "FETCH_CUSTOMER_GROUP_LIST_PROGRESS";
// export const FETCH_CUSTOMER_GROUP_LIST_SUCCESS =
//   "FETCH_CUSTOMER_GROUP_LIST_SUCCESS";
// export const FETCH_CUSTOMER_GROUP_LIST_FAILED =
//   "FETCH_CUSTOMER_GROUP_LIST_FAILED";

// export const clearCustomerState = () => action(CLEAR_CUSTOMER_STATE);
// export const recordNotFound = () => action(RECORD_NOT_FOUND);

// export const ADD_PERSONAL_INFORMATION = "ADD_PERSONAL_INFORMATION";
// export const FETCH_PERSONAL_INFO_PROGRESS = "FETCH_PERSONAL_INFO_PROGRESS";

// export const fetchPersonalInfoProgress = () =>
//   action(FETCH_PERSONAL_INFO_PROGRESS);
// export const fetchPersonalInfoAsync =
//   (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     const isSaved =
//       getState().customer.personalInformation.loading === LoadState.Loaded;
//     const token = getUserAuth();
//     try {
//       if (token && !isSaved) {
//         dispatch(fetchPersonalInfoProgress());
//         const res = await api.get(
//           `/customer/get-customer-personal-information?customer_id=${customerId}`,
//           {
//             headers: {
//               "auth-Token": token,
//             },
//           },
//         );
//         const data: IPersonalInformation[] = res.data.data;
//         if (data.length > 0) {
//           dispatch(addPersonalInformation(data[0]));
//         } else {
//           dispatch(recordNotFound());
//         }
//       }
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

// export const addPersonalInformation = (data: IPersonalInformation) =>
//   action(ADD_PERSONAL_INFORMATION, { data: data });
// export const addPersonalInformationAsync =
//   (
//     data: IPersonalInformation,
//     onCallback: (isSuccess: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       await api.post("/customer/edit-customer-personal-information", {
//         first_name: data.first_name,
//         email: data.email,
//         phone: data.phone,
//         salutation: data.salutation,
//         last_name: data.last_name,
//         goes_by: data.goes_by,
//         position: data.position,
//         mobile: data.mobile,
//         phone_ext: data.phone_ext,
//         status: data.status,
//         company_name: data.first_name + " " + data.last_name,
//         //customerId: data.customer_id,
//       });
//       dispatch(addPersonalInformation(data));

//       dispatch(
//         showMessage({
//           type: "success",
//           message: "Personal information saved successfully!",
//           displayAs: "snackbar",
//         }),
//       );
//       onCallback(true);
//       // dispatch(fetchPersonalInfoAsync(data.customer_id));
//     } catch (err: any) {
//       onCallback(false);
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

// export const ADD_CUSTOMER_PROSPECT = "ADD_CUSTOMER_PROSPECT";
// export const FETCH_CUSTOMER_PROSPECT_PROGRESS =
//   "FETCH_CUSTOMER_PROSPECT_PROGRESS";

// export const fetchCustomerProspectProgress = () =>
//   action(FETCH_CUSTOMER_PROSPECT_PROGRESS);
// export const addCustomerProspect = (data: ICustomerProspect) =>
//   action(ADD_CUSTOMER_PROSPECT, { data: data });
// export const addCustomerProspectAsync =
//   (
//     data: ICustomerProspect,
//     onCallback: (isSuccess: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       await api.post("/customer/edit-customer-prospact-details", {
//         ...data,
//         status: "ACTIVE",
//       });
//       dispatch(addCustomerProspect(data));
//       dispatch(
//         showMessage({
//           type: "success",
//           message: "Customer propspect saved successfully!",
//           displayAs: "snackbar",
//         }),
//       );
//       onCallback(true);
//     } catch (err: any) {
//       onCallback(false);
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

// export const fetchCustomerProspectAsync =
//   (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     const isSaved =  getState().customer.customerProspect.loading === LoadState.Loaded;
//     const token = getUserAuth();
//     try {
//       if (token && !isSaved) {
//         dispatch(fetchCustomerProspectProgress());
//         const res = await api.get(
//           `/customer/get-customer-prospact-details?customer_id=${customerId}`,
//           {
//             headers: {
//               "auth-Token": token,
//             },
//           },
//         );
//         const data: ICustomerProspect[] = res.data.data;
//         if (data.length > 0) {
//           dispatch(addCustomerProspect(data[0]));
//         } else {
//           dispatch(
//             addCustomerConsent(initialCustomerState["customerConsent"]["data"]),
//           );
//         }
//       }
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

// export const addCustomerSocial = (data: ICustomerSocial) =>
//   action(ADD_CUSTOMER_SOCIAL, { data: data });

// export const addCustomerSocialAsync =
//   (
//     data: ICustomerSocial,
//     onCallback: (isSuccess: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       await api.post("/customer/edit-customer-social", {
//         ...data,
//         status: "ACTIVE",
//       });
//       dispatch(addCustomerSocial(data));
//       dispatch(
//         showMessage({
//           type: "success",
//           message: "Customer social saved successfully!",
//           displayAs: "snackbar",
//         }),
//       );
//       onCallback(true);
//     } catch (err: any) {
//       onCallback(false);
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

// export const fetchCustomerSocialProgress = () =>
//   action(FETCH_CUSTOMER_SOCIAL_PROGRESS);

// export const fetchCustomerSocialAsync =
//   (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     const isSaved =
//       getState().customer.customerSocial.loading === LoadState.Loaded;
//     const token = getUserAuth();
//     try {
//       if (token && !isSaved) {
//         dispatch(fetchCustomerSocialProgress());
//         const res = await api.get(
//           `/customer/get-customer-social?customer_id=${customerId}`,
//           {
//             headers: {
//               "auth-Token": token,
//             },
//           },
//         );
//         const data: ICustomerSocial[] = res.data.data;
//         if (data.length > 0) {
//           dispatch(addCustomerSocial(data[0]));
//         } else {
//           dispatch(
//             addCustomerSocial(initialCustomerState["customerSocial"]["data"]),
//           );
//         }
//       }
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

// export const addCustomerConsent = (data: ICustomerConsent) =>
//   action(ADD_CUSTOMER_CONSENT, { data: data });

// export const aaddCustomerConsentAsync =
//   (
//     data: ICustomerConsent,
//     onCallback: (isSuccess: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       await api.post("/customer/edit-customer-consent", {
//         ...data,
//         status: "ACTIVE",
//       });
//       dispatch(addCustomerConsent(data));
//       dispatch(
//         showMessage({
//           type: "success",
//           message: "Customer consent saved successfully!",
//           displayAs: "snackbar",
//         }),
//       );
//       onCallback(true);
//     } catch (err: any) {
//       onCallback(false);
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

// export const fetchCustomerConsentProgress = () =>
//   action(FETCH_CUSTOMER_CONSENT_PROGRESS);

// export const fetchCustomerConsentAsync =
//   (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     const isSaved =
//       getState().customer.customerConsent.loading === LoadState.Loaded;
//     const token = getUserAuth();
//     try {
//       if (token && !isSaved) {
//         dispatch(fetchCustomerConsentProgress());
//         const res = await api.get(
//           `/customer/get-customer-consent?customer_id=${customerId}`,
//           {
//             headers: {
//               "auth-Token": token,
//             },
//           },
//         );
//         const data: ICustomerConsent[] = res.data.data;
//         if (data.length > 0) {
//           dispatch(addCustomerConsent(data[0]));
//         }
//       }
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

// export const addCustomerRelAccount = (data: ICustomerRelatedAccount) =>
//   action(ADD_CUSTOMER_REL_ACCOUNT, { data: data });

// export const addCustomerRelAccountlAsync =
//   (
//     data: ICustomerRelatedAccount,
//     onCallback: (isSuccess: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       await api.post("/customer/edit-customer-related-account", {
//         customer_id: data.customer_id,
//         cross_reference: data.cross_reference_id,
//         status: "ACTIVE",
//       });
//       dispatch(addCustomerRelAccount(data));
//       dispatch(
//         showMessage({
//           type: "success",
//           message: "Customer related account saved successfully!",
//           displayAs: "snackbar",
//         }),
//       );
//       onCallback(true);
//     } catch (err: any) {
//       onCallback(false);
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

// export const fetchCustomerRelAccountProgress = () =>
//   action(FETCH_CUSTOMER_REL_ACCOUNT_PROGRESS);

// export const fetchCustomerRelAccountAsync =
//   (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       dispatch(fetchCustomerRelAccountProgress());
//       const res = await api.get(
//         `/customer/get-customer-related-account?customer_id=${customerId}`,
//       );
//       const data: ICustomerRelatedAccount[] = res.data.data;
//       if (data.length > 0) {
//         dispatch(addCustomerRelAccount(data[0]));
//       } else {
//         dispatch(
//           addCustomerRelAccount(
//             initialCustomerState["customerRelatedAccount"]["data"],
//           ),
//         );
//       }
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

// export const fetchOpprtunitiesProgress = () =>
//   action(FETCH_OPPPRTUNITY_PROGRESS);

// export const addOpprtunities = (data: IOpportunity[], totalRecords: number) =>
//   action(ADD_OPPPRTUNITY, { data, totalRecords });

// export const fetchOpprtunity =
//   (
//     customerId: number,
//     pageNumber: number,
//     rowsInPerPage: number,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     const token = getUserAuth();
//     try {
//       if (token) {
//         dispatch(fetchOpprtunitiesProgress());
//         const res = await api.get(
//           `/lead/get-opportunity?customer_id=${customerId}&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`,
//           {
//             headers: {
//               "auth-Token": token,
//             },
//           },
//         );
//         const data: IOpportunity[] = res.data.data;
//         dispatch(addOpprtunities(data, res.data.totalRecords));
//       }
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

// export const fetchCustomerFullDetailsProgress = () =>
//   action(FETCH_CUSTOMER_FULL_DETAILS_PROGRESS);
// export const addCustomerFullDetails = (
//   customer: ICustomerFullDetails,
//   customerId: number,
// ) => action(ADD_CUSTOMER_FULL_DETAILS, { customer, customerId });

// export const fetchCustomerFullDetails =
//   (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     const token = getUserAuth();
//     const existingCustomerId =
//       getState().customer.customerFullDetails.customerId;
//     try {
//       if (token && (!existingCustomerId || existingCustomerId !== customerId)) {
//         dispatch(fetchCustomerFullDetailsProgress());
//         const res = await api.get(
//           `customer/get-customer-all-detailed-record?customer_id=${customerId}`,
//           {
//             headers: {
//               "auth-Token": token,
//             },
//           },
//         );
//         const data: ICustomerFullDetails[] = res.data.data;
//         if (data.length > 0) {
//           dispatch(addCustomerFullDetails(data[0], customerId));
//         }
//       }
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

// export const fetchCustomerConsentListProgress = () =>
//   action(FETCH_CUSTOMER_CONSENT_LIST_PROGRESS);

// export const fetchCustomerConsentListSuccess = (data: ICustomerConsent[]) =>
//   action(FETCH_CUSTOMER_CONSENT_LIST_SUCCESS, { data });

// export const fetchCustomerConsentListFailed = () =>
//   action(FETCH_CUSTOMER_CONSENT_LIST_FAILED);

// export const fetchCustomerConsentListAsync =
//   (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       dispatch(fetchCustomerConsentListProgress());
//       const res = await api.get(
//         `/customer/get-customer-consent?customer_id=${customerId}`,
//       );
//       const data: ICustomerConsent[] = res.data.data;
//       dispatch(fetchCustomerConsentListSuccess(data));
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//       dispatch(fetchCustomerConsentListFailed());
//     }
//   };

// export const editCustomerConsentAsync =
//   (
//     data: ICustomerConsent,
//     onCallback: (isSucces?: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       await api.post("/customer/edit-customer-consent", {
//         ...data,
//         status: "ACTIVE",
//       });

//       onCallback(true);
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );

//       onCallback(false);
//     }
//   };

// export const fetchCustomerGroupListProgress = () =>
//   action(FETCH_CUSTOMER_GROUP_LIST_PROGRESS);

// export const fetchCustomerGroupListSuccess = (data: ICustomerGroup[]) =>
//   action(FETCH_CUSTOMER_GROUP_LIST_SUCCESS, { data });

// export const fetchCustomerGroupListFailed = () =>
//   action(FETCH_CUSTOMER_GROUP_LIST_FAILED);

// export const fetchCustomerGroupListAsync =
//   (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       dispatch(fetchCustomerGroupListProgress());
//       const res = await api.get(
//         `/customer/get-customer-group?customer_id=${customerId}`,
//       );
//       const data: ICustomerGroup[] = res.data.data;
//       dispatch(fetchCustomerGroupListSuccess(data));
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//       dispatch(fetchCustomerGroupListFailed());
//     }
//   };

// export const editCustomerGroupAsync =
//   (
//     data: ICustomerGroup,
//     onCallback: (isSucces?: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       await api.post("/customer/edit-customer-group", {
//         ...data,
//         status: "ACTIVE",
//       });

//       onCallback(true);
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );

//       onCallback(false);
//     }
//   };

// export const FETCH_CUSTOMER_CONTACTS_LIST_PROGRESS =
//   "FETCH_CUSTOMER_CONTACTS_LIST_PROGRESS";
// export const FETCH_CUSTOMER_CONTACTS_LIST_SUCCESS =
//   "FETCH_CUSTOMER_CONTACTS_LIST_SUCCESS";
// export const FETCH_CUSTOMER_CONTACTS_LIST_FAILED =
//   "FETCH_CUSTOMER_CONTACTS_LIST_FAILED";

// export const fetchCustomerContactsListProgress = () =>
//   action(FETCH_CUSTOMER_CONTACTS_LIST_PROGRESS);

// export const fetchCustomerContactsListSuccess = (data: ICustomerContact[]) =>
//   action(FETCH_CUSTOMER_CONTACTS_LIST_SUCCESS, { data });

// export const fetchCustomerContactsListFailed = () =>
//   action(FETCH_CUSTOMER_CONTACTS_LIST_FAILED);

// export const fetchCustomerContactsListAsync =
//   (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       dispatch(fetchCustomerContactsListProgress());
//       const res = await api.get(
//         `/customer/get-customer-secondary-contact-details?customer_id=${customerId}`,
//       );
//       const data: ICustomerContact[] = res.data.data;
//       dispatch(fetchCustomerContactsListSuccess(data));
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//       dispatch(fetchCustomerGroupListFailed());
//     }
//   };

// export const upsertCustomerContactAsync =
//   (
//     data: ICustomerContact,
//     onCallback: (isSucces?: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       await api.post("/customer/create-secondary-contact", {
//         ...data,
//         status: "ACTIVE",
//       });

//       onCallback(true);
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );

//       onCallback(false);
//     }
//   };
