import { Typography } from "@mui/material";
import React from "react";

export const CustomOnClickWrapper: React.FC<{
	label: string;
	onClick: () => void;
}> = (props) => {
	const { label, onClick } = props;

	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};

	return (
		<Typography
			sx={{ cursor: "pointer" }}
			variant="body1"
			color={"primary.main"}
			onClick={handleClick}
		>
			{" "}
			{label}
		</Typography>
	);
};
