import React from "react";
import { IConfirmDialogProps } from "./interfaces/IDialogProps";
import { Dialog } from "./Dialog";

export const ConfirmDialog: React.FC<IConfirmDialogProps> = (props) => {
  const { open, content, onClose, onConfrim, submitType = "button" } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        size="xs"
        title="Attention!"
        actions={[
          {
            label: "Close",
            type: "button",
            variant: "text",
            onClick: onClose
          },
          {
            label: "Confirm",
            type: submitType,
            variant: "contained",
            onClick: onConfrim,
          },
        ]}
      >
        {content}
      </Dialog>
    </>
  );
};
