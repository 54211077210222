import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { useSelector } from "react-redux";
import type { IStoreState } from "../../redux/initialStoreState";
import { LoadState } from "../../constants/enums";
import { PageContainer } from "../../components/container/PageContainer";
import { useFormik } from "formik";
import { useDispatchWrapper } from "../../hooks";
import { Chip } from "@mui/material";
import {
	clearOrderState,
	fetchOrdersDiscrepanciesListAsync,
	type IOrderDiscrepancyItem,
	upsertOrderDiscrepancies,
} from "../../redux/orders";
import {
	Box,
	Button,
	Divider,
	Grid,
	Paper,
	Stack,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import {
	ArrowForward,
	CheckOutlined,
	DescriptionOutlined,
	IosShare,
} from "@mui/icons-material";
import { CustomTextField } from "../../components/formsComponents";
import produce from "immer";
import { APP_ORDERS_ROUTE } from "./Orders.constants";
import { PreviewOrderFileDialog } from "./PreviewOrderFileDialog";

export const OrderDiscrepancy: React.FC = () => {
	const { uuid: orderUUID } = useParams() as { uuid?: string };
	const { data, error, loading } = useSelector(
		(storeState: IStoreState) => storeState.orders.single_order_discrepancy
	);
	const dispatch = useDispatchWrapper();
	const navigate = useNavigate();
	const theme = useTheme();

	const [openPreViewFileDialog, setOpenPreViewFileDialog] =
		useState<boolean>(false);

	const { values, errors, setFieldValue, setValues, handleSubmit } = useFormik({
		initialValues: data,
		validate: (values) => {
			const errors = {};
			return errors;
		},
		onSubmit: (values) => {
			const payload = {
				...values,

				order_discrepancies_data: values.order_discrepancies_data.map(
					(item: IOrderDiscrepancyItem) => {
						return {
							...item,
							submitted_price:
								item.submitted_price?.toString().replaceAll(",", "") || "0",
							submitted_quantity:
								item.submitted_quantity?.toString().replaceAll(",", "") || "0",
							orders_uuid: orderUUID || null,
						};
					}
				),
			};
			dispatch(
				upsertOrderDiscrepancies(payload, (isSuccess) => {
					if (isSuccess) {
						navigate(APP_ORDERS_ROUTE);
					}
				})
			);
		},
	});



	React.useEffect(() => {
		if (orderUUID) {
			dispatch(fetchOrdersDiscrepanciesListAsync(orderUUID));
		}
	}, [orderUUID]);

	React.useEffect(() => {
		const newValues = data.order_discrepancies_data.map((item) => {
			if (item.submitted_quantity === item.order_confirmation_quantity && item.submitted_price === item.order_confirmation_price) {
				return { ...item, response: true, disableAction: true };
			}
			return { ...item, response: false };
		});
		setValues({ ...data, order_discrepancies_data: newValues });
	}, [data]);

	React.useEffect(() => {
		return () => {
			dispatch(clearOrderState());
		};
	}, []);


	const handlerOrderItemsResponse = (index: number) => (value: boolean) => {
		const newValues = produce(values, (draftvalues) => {
			draftvalues.order_discrepancies_data[index].response = value;
		});
		setValues(newValues);
	};

	const changeResponseToOk = () => {
		const newValues = values.order_discrepancies_data.map((item) => {
			return { ...item, response: true };
		});
		setFieldValue("order_discrepancies_data", newValues);
	};
	const changeResponseToNotOk = () => {
		const newValues = values.order_discrepancies_data.map((item) => {
			return { ...item, response: false };
		});
		setFieldValue("order_discrepancies_data", newValues);
	};


	const updateRowData =
		(index: number) => (updatedRow: IOrderDiscrepancyItem) => {
			const newValues = produce(values, (draftValues) => {
				draftValues.order_discrepancies_data[index] = updatedRow;
			});
			setValues(newValues);
		};



	return (
		<>
			<PageLoader
				loading={loading === LoadState.InProgress}
				error={error ? { message: error } : null}
			>
				<PageContainer
					title="Order Discrepancy"
					description="This is inner page"
				>
					<Grid
						container
						spacing={1}
					>
						<Grid
							item
							xs={12}
							md={1}
						/>
						<Grid
							item
							xs={12}
							md={10}
						>
							<Paper
								variant="outlined"
								sx={{ p: 5 }}
							>
								<Typography
									variant="h2"
									fontWeight={600}
								>
									{`${values.order_discrepancies_data.length} discrepanices need your review `}
									<Typography
										component={"span"}
										variant="h4"
										fontWeight={500}
									>
										{`(Order confirmation)`}
									</Typography>
								</Typography>
								<Grid
									container
									spacing={1}
									mt={4}
									mb={5}
								>
									<Grid
										item
										xs={4}
									>
										<Stack
											direction={"row"}
											justifyContent={"space-between"}
										>
											<Typography
												variant="body1"
												fontWeight={600}
											>
												Order No#
											</Typography>
											<Typography variant="body1">{values.order_no}</Typography>
										</Stack>
										<Stack
											direction={"row"}
											mt={1}
											justifyContent={"space-between"}
										>
											<Typography
												variant="body1"
												fontWeight={600}
											>
												Supplier
											</Typography>
											<Typography variant="body1">
												{values.supplier_name}
											</Typography>
										</Stack>
										<Stack
											direction={"row"}
											mt={1}
											justifyContent={"space-between"}
										>
											<Typography
												variant="body1"
												fontWeight={600}
											>
												Supplier Email
											</Typography>
											<Typography variant="body1">
												{values.supplier_email}
											</Typography>
										</Stack>
									</Grid>
									<Grid
										item
										xs={4}
									/>
									<Grid
										item
										xs={4}
									>
										{values.order_confirmation_file && <Paper>
											<Stack
												spacing={2}
												direction={"column"}
												justifyContent={"center"}
												alignItems={"center"}
												padding={1}
												sx={{
													minHeight: "200px",
													border: `2px dashed ${theme.palette.grey[500]}`,
												}}
											>
												<Stack
													direction={"column"}
													spacing={1}
													alignItems={"center"}
													justifyContent={"center"}
													width={"80%"}
												>
													<Box position={"relative"}>
														<DescriptionOutlined
															sx={{
																fontSize: 75,
																color: theme.palette.grey[500],
															}}
														/>
														<Box
															sx={{
																position: "absolute",
																padding: "2px",
																right: 3,
																background: theme.palette.success.main,
																borderRadius: "100%",
																display: "flex",
																top: -1,
															}}
														>
															<CheckOutlined sx={{ color: "#fff" }} />
														</Box>
													</Box>
												</Stack>
												<Stack
													direction={"row"}
													spacing={1}
												>
													<Button
														variant="contained"
														size="large"
														color="error"
														onClick={() => setOpenPreViewFileDialog(true)}
													>
														View
													</Button>
												</Stack>
											</Stack>
										</Paper>}
									</Grid>
								</Grid>
								<form onSubmit={handleSubmit}>
									<Box
										mb={3}
										sx={{
											backgroundColor: theme.palette.grey[200],
											padding: 2,
											borderRadius: 2,
										}}
									>
										<Typography
											variant="h4"
											fontWeight={500}
										>
											Order Items
										</Typography>
									</Box>
									<TableContainer component={Paper}>
										<Table
											sx={{ minWidth: 650 }}
											aria-label="simple table"
										>
											<TableHead>
												<TableRow>
													<TableCell
														align="center"
														sx={{ minWidth: 150 }}
													>
														Image
													</TableCell>

													<TableCell
														align="left"
														sx={{ minWidth: 100 }}
													>
														Description
													</TableCell>
													<TableCell
														align="left"
														sx={{ minWidth: 100 }}
													>
														Style
													</TableCell>
													<TableCell
														align="left"
														sx={{ minWidth: 100 }}
													>
														Submitted Orders
													</TableCell>
													<TableCell
														align="left"
														sx={{ minWidth: 100 }}
													></TableCell>
													<TableCell align="left">Order Confirmation</TableCell>
													<TableCell align="right">
														<Stack
															direction={"row"}
															justifyContent={"flex-end"}
															spacing={1}
														>
															<Button
																variant={"contained"}
																color="error"
																onClick={changeResponseToNotOk}
															>
																NONE OK
															</Button>
															<Button
																variant={"contained"}
																color="success"
																onClick={changeResponseToOk}
															>
																All OK
															</Button>
														</Stack>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{values.order_discrepancies_data.map(
													(orderItem, index) => {
														return (
															<OrderDiscrepancyRow
																row={orderItem}
																key={orderItem.order_item_uuid}
																changeOrderResponse={handlerOrderItemsResponse(
																	index
																)}
																updateRowData={updateRowData(index)}
															/>
														);
													}
												)}
											</TableBody>
										</Table>
									</TableContainer>

									<Divider sx={{ mt: 3, mb: 2 }} />

									<Box
										mb={3}
										sx={{
											backgroundColor: theme.palette.grey[200],
											padding: 2,
											borderRadius: 2,
										}}
									>
										<Typography
											variant="h4"
											fontWeight={500}
										>
											Notes
										</Typography>
									</Box>
									<CustomTextField
										fullWidth
										multiline
										rows={3}
									// value={values.r}
									></CustomTextField>

									<Stack
										direction={"row"}
										justifyContent={"end"}
										mt={2}
									>
										<Button
											type="submit"
											variant="contained"
											color="success"
											sx={{ minWidth: "10%" }}
											endIcon={<IosShare sx={{ transform: "rotate(90deg)" }} />}
										>
											Submit
										</Button>
									</Stack>
								</form>
							</Paper>
						</Grid>
						<Grid
							item
							xs={12}
							md={1}
						/>
					</Grid>
				</PageContainer>
			</PageLoader>
			{openPreViewFileDialog && (
				<PreviewOrderFileDialog
					open={openPreViewFileDialog}
					fileUrl={values.order_confirmation_file || ""}
					onClose={() => setOpenPreViewFileDialog(false)}
				/>
			)}
		</>
	);
};

export const OrderDiscrepancyRow: React.FC<{
	row: IOrderDiscrepancyItem;
	disabled?: boolean
	changeOrderResponse: (value: boolean) => void;
	updateRowData: (updatedRow: IOrderDiscrepancyItem) => void;
}> = ({ row, disabled, changeOrderResponse, updateRowData }) => {
	const theme = useTheme();
	const [price, setPrice] = useState(row.order_confirmation_price);
	const [quantity, setQuantity] = useState(row.order_confirmation_quantity);
	const [isEdited, setIsEdited] = useState(false);

	React.useEffect(() => {
		if (
			price !== row.order_confirmation_price ||
			quantity !== row.order_confirmation_quantity
		) {
			setIsEdited(true);
			changeOrderResponse(false);
		} else {
			setIsEdited(false);
		}
	}, [
		price,
		row.order_confirmation_price,
		quantity,
		row.order_confirmation_quantity,
	]);

	const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPrice(event.target.value);
	};
	const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQuantity(event.target.value);
	};

	return (
		<>
			<TableRow
				sx={{
					backgroundColor: row.is_new_item
						? theme.palette.grey[200]
						: "inherit",
				}}
			>
				<TableCell sx={{ minWidth: 150 }}>
					<Box
						height={120}
						width={120}
						sx={{
							p: 1,
							backgroundColor: theme.palette.grey[100],
							borderRadius: 5,
						}}
					>
						<img
							width={"100%"}
							height={"100%"}
							style={{ borderRadius: 20 }}
							src={row.preview_url || "/placeholder.svg"}
							alt="Product"
						/>
					</Box>
				</TableCell>
				<TableCell>
					<Typography variant="subtitle2" > {`Color: ${row.color || ""}`} </Typography>
					<Typography variant="subtitle2" > {`Fabric: ${row.fabric || ""}`} </Typography>
					<Typography variant="subtitle2" > {`Category: ${row.category || ""}`} </Typography>
					<Typography variant="subtitle2" > {`Line: ${row.line || ""}`} </Typography>
					<Typography variant="subtitle2" > {`VPN: ${row.product_vpn || ""}`} </Typography>
				</TableCell>
				<TableCell>
					<Typography
						variant="h4"
						fontWeight={600}
					>
						{row.style}
					</Typography>
					<Typography
						variant="h5"
						fontWeight={500}
						mt={1}
					>
						Quantity
					</Typography>
					<Typography
						variant="h5"
						fontWeight={500}
						mt={1}
					>
						Price
					</Typography>
				</TableCell>

				<TableCell align="left">
					<Typography
						variant="h4"
						fontWeight={600}
						visibility={"hidden"}
					>
						{row.style}
					</Typography>
					<Typography
						variant="h5"
						fontWeight={500}
						mt={1}
					>
						{row.submitted_quantity}
					</Typography>
					<Typography
						variant="h5"
						fontWeight={500}
						mt={1}
					>
						{row.submitted_price}
					</Typography>
				</TableCell>

				<TableCell sx={{ minWidth: 100 }}>
					<Typography
						variant="h4"
						fontWeight={600}
						visibility={"hidden"}
					>
						{row.style}
					</Typography>
					<Box mt={1}>
						<ArrowForward />
					</Box>
					<Box mt={1}>
						<ArrowForward />
					</Box>
				</TableCell>

				<TableCell align="left">
					<Typography
						variant="h4"
						fontWeight={600}
						visibility={"hidden"}
					>
						{row.style}
					</Typography>
					<Typography
						variant="h5"
						fontWeight={500}
						mt={1}
					>
						{row.order_confirmation_quantity}
					</Typography>
					<Typography
						variant="h5"
						fontWeight={500}
						mt={1}
					>
						{row.order_confirmation_price}
					</Typography>
				</TableCell>

				<TableCell sx={{ minWidth: 180 }}>
					<Stack
						direction={"row"}
						justifyContent={"flex-end"}
						spacing={0.25}
						sx={{ alignItems: "right" }}
					>
						{Boolean(row.is_new_item) && (
							<Chip
								label="New"
								color="success"
								size="small"
							/>
						)}
					</Stack>
					<Stack
						direction={"row"}
						justifyContent={"flex-end"}
						spacing={0.25}
						sx={{ alignItems: "center" }}
					>
						<Typography fontWeight={600}>Not OK</Typography>
						<Switch
							color={row.response ? "success" : "error"}
							checked={row.response}
							onChange={(evt) => changeOrderResponse(evt.target.checked)}
							disabled={isEdited || disabled || row.disableAction}
						/>
						<Typography fontWeight={600}>OK</Typography>
					</Stack>

					<Stack>
						{!row.response && (
							<Box mt={2}>
								<CustomTextField
									fullWidth
									multiline
									rows={2}
									placeholder="Comment"
									value={row.comment || ""}
									onChange={(e) => {
										const newComment = e.target.value;
										changeOrderResponse(false);
										const updatedRow = { ...row, comment: newComment };
										updateRowData(updatedRow);
									}}
								/>
							</Box>
						)}
					</Stack>
				</TableCell>
			</TableRow>
		</>
	);
};
