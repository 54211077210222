import { action } from "typesafe-actions";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { ICTTemplate } from "./ictTypes";
import { api } from "../../api/api";
import {
	saveLoaderCompleted,
	saveLoaderProgress,
	showMessage,
} from "../messages/messagesActions";

export const FETCH_ICT_TEMPLATES_LIST_PROGRESS =
	"FETCH_ICT_TEMPLATES_LIST_PROGRESS";
export const FETCH_ICT_TEMPLATES_LIST_SUCCESS =
	"FETCH_ICT_TEMPLATES_LIST_SUCCESS";
export const FETCH_ICT_TEMPLATES_LIST_FAILED =
	"FETCH_ICT_TEMPLATES_LIST_FAILED";

export const fetchItcTemplatesProgress = () =>
	action(FETCH_ICT_TEMPLATES_LIST_PROGRESS);
export const fetchItcTemplatesSuccess = (
	data: ICTTemplate[],
	totalRecords: number
) => action(FETCH_ICT_TEMPLATES_LIST_SUCCESS, { data, totalRecords });
export const fetchItcTemplatesFailed = () =>
	action(FETCH_ICT_TEMPLATES_LIST_FAILED);

export const fetchItcTemplatesListAsync =
	(
		pageNumber: number,
		rowsInPerPage: number,
		status: string,
		date: IDate,
		searchValue: string,
		searchType: string[]
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchItcTemplatesProgress());
			let finalUrl = `/template/get-ict-template?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

			if (searchType.length > 0 && searchValue) {
				finalUrl = `/template/get-ict-template?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${
					date.fromDate
				}&to_date=${date.toDate}&column=${[searchType]}&value=${searchValue}`;
			}
			if (status !== "-1") {
				finalUrl += "&status=" + status;
			}
			const res = await api.get(finalUrl);
			const data: ICTTemplate[] = res.data.data;
			const totalRecords = res.data.totalRecords;

			dispatch(fetchItcTemplatesSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchItcTemplatesFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const FETCH_ICT_TEMPLATE_PROGRESS = "FETCH_ICT_TEMPLATE_PROGRESS";
export const FETCH_ICT_TEMPLATE_SUCCESS = "FETCH_ICT_TEMPLATE_SUCCESS";
export const FETCH_ICT_TEMPLATE_FAILED = "FETCH_ICT_TEMPLATE_FAILED";
export const UPDATE_ICT_TEMPLATE_IMAGE_PATH = "UPDATE_ICT_TEMPLATE_IMAGE_PATH";

export const fetchIctTemplateProgress = () =>
	action(FETCH_ICT_TEMPLATE_PROGRESS);
export const fetchIctTemplateSuccess = (data: ICTTemplate) =>
	action(FETCH_ICT_TEMPLATE_SUCCESS, { data });
export const fetchIctTemplateFailed = (errorMessage: string) =>
	action(FETCH_ICT_TEMPLATE_FAILED, { errorMessage });

export const updateIctTemplateImagePath = (path: string) =>
	action(UPDATE_ICT_TEMPLATE_IMAGE_PATH, { path });

export const fetchSingleICTTemplateAsync =
	(ict_template_uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchIctTemplateProgress());
			const res = await api.get(
				`/template/get-ict-template?ict_template_uuid=${ict_template_uuid}&pageNo=1&itemPerPage=10`
			);
			const data = res.data.data;
			if (data.length > 0) {
				dispatch(fetchIctTemplateSuccess(data[0]));
			} else {
				dispatch(
					fetchIctTemplateFailed(
						"Unfortunately, there are no records available at the moment."
					)
				);
			}
		} catch (err: any) {
			dispatch(fetchIctTemplateFailed("Something went to be wrong!"));
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const upsertSingleICTTemplateAsync =
	(
		templateData: ICTTemplate,
		onCallback: (isSuccess: boolean, data?: any) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(saveLoaderProgress());
			const { create_ts, insert_ts, ...rest } = templateData;
			const res = await api.post("/template/upsert-ict-template", rest);
			dispatch(
				showMessage({
					type: "success",
					message: "Template saved successfully!",
					displayAs: "snackbar",
				})
			);
			onCallback(true, res.data.data);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response?.data?.message || "An error occurred",
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const CLEAR_ICT_TEMPLATES = "CLEAR_ICT_TEMPLATES";
export const clearICTTemplateFullStateSync = () => action(CLEAR_ICT_TEMPLATES);

export const CLEAR_SINGLE_ICT_TEMPLATES = "CLEAR_SINGLE_ICT_TEMPLATES";
export const clearSingleICTTemplateStateSync = () =>
	action(CLEAR_SINGLE_ICT_TEMPLATES);
