import React, { useEffect, useState } from 'react'
import { useExtractProductSocket } from '../SocketProvider.hooks'
import { Alert, Button, Snackbar, SnackbarContent } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { APP_PRODUCT_VALIDATION_ROUTE } from '../../../views/ProductValidation/ProductValidation.constants'
import { useAppRoutePath } from '../../../hooks'

export const LinesheetDocumetAlert = () => {
    const navigate = useNavigate()
    const path = useAppRoutePath()
    const { status, uuid } = useExtractProductSocket();

    const [openSnackBar, setDpenSnackBar] = useState(false)

    useEffect(() => {
        if (status === "SUCCESS" && !path.includes('extract-and-validate')) setDpenSnackBar(true)
    }, [status])

    const handleViewDocumentClick = () => {
        navigate(`${APP_PRODUCT_VALIDATION_ROUTE}/manage/${uuid}`)
        setDpenSnackBar(false)
    }

    if (openSnackBar) return (
        <Snackbar open={openSnackBar}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            sx={{ width: "100%", p: 1, left: "0px !important", top: "0px !important" }}
        >
            <Alert
                severity="success"
                variant='filled'
                sx={{ width: '100%', py: 2, background: "#00b509" }}
                action={
                    <>
                        <Button color='inherit' size="small" sx={{ textTransform: "capitalize" }} onClick={handleViewDocumentClick}>
                            View Products
                        </Button>
                        <Button color='inherit' size="small" sx={{ textTransform: "capitalize" }} onClick={() => setDpenSnackBar(false)}>
                            Close
                        </Button>
                    </>
                }
            >
                Product Extraction Successfull.
            </Alert>
        </Snackbar>
    )
 
    return <></>
}
