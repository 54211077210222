import produce from "immer";
import { IBudgetActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
	CLEAR_BUDGET,
	FETCH_BUDGET_LIST_FAILED,
	FETCH_BUDGET_LIST_PROGRESS,
	FETCH_BUDGET_LIST_SUCCESS,
	FETCH_SINGLE_BUDGET_FAILED,
	FETCH_SINGLE_BUDGET_PROGRESS,
	FETCH_SINGLE_BUDGET_SUCCESS,
} from "./budget.actions";
import { defaultBudgetState } from "./budget.state";

export const budgetReducer = (
	state: IStoreState["budget"] = defaultBudgetState,
	action: IBudgetActions
) => {
	switch (action.type) {
		// #########################################################################
		// ############################ budget List ################################
		// #########################################################################
		case FETCH_BUDGET_LIST_PROGRESS: {
			const newState = produce(state, (draftState) => {
				draftState.list.loading = LoadState.InProgress;
				draftState.list.data = [];
				draftState.list.totalRecords = 0;
			});
			return newState;
		}
		case FETCH_BUDGET_LIST_SUCCESS: {
			const { data, totalRecords } = action.payload;
			const newState = produce(state, (draftState) => {
				draftState.list.loading = LoadState.Loaded;
				draftState.list.data = data;
				draftState.list.totalRecords = totalRecords;
			});
			return newState;
		}
		case FETCH_BUDGET_LIST_FAILED: {
			const newState = produce(state, (draftState) => {
				draftState.list.loading = LoadState.Failed;
			});
			return newState;
		}

		// #########################################################################
		// ############################ Single budget ##############################
		// #########################################################################

		case FETCH_SINGLE_BUDGET_PROGRESS: {
			const newState = produce(state, (draftState) => {
				draftState.single_budget_object.loading = LoadState.InProgress;
			});
			return newState;
		}
		case FETCH_SINGLE_BUDGET_SUCCESS: {
			const data = action.payload;
			const newState = produce(state, (draftState) => {
				draftState.single_budget_object.loading = LoadState.Loaded;
				draftState.single_budget_object.data = data;
			});
			return newState;
		}
		case FETCH_SINGLE_BUDGET_FAILED: {
			const newState = produce(state, (draftState) => {
				draftState.single_budget_object.loading = LoadState.Failed;
			});
			return newState;
		}

		case CLEAR_BUDGET: {
			const newState = produce(state, (draftState: any) => {
				draftState.single_budget_object.loading = LoadState.NotLoaded;
				draftState.single_budget_object.data = defaultBudgetState;
			});
			return newState;
		}

		default:
			return state;
	}
};
