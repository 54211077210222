import { api } from "../../../api/api";

import {
	getFileExtensionFromUrl,
	getFileNameFromUrl,
} from "./getFileNameFromUrl";

export const downLoadFile = async (
	fileName: string | null,
	isExternalLogin: boolean | null = false
) => {
	if (fileName) {
		let finalUrl = "";

		if (isExternalLogin === true) {
			finalUrl = "/externalSupplier/download-files";
		} else {
			finalUrl = "/general/download-files";
		}
		console.log("finalUrl", finalUrl);
		const res = await api.post(
			finalUrl,
			{
				type: "",
				keys: [fileName],
			},
			{
				responseType: "arraybuffer",
			}
		);

		const blob = new Blob([res.data], {
			type: "application/" + getFileExtensionFromUrl(fileName),
		});

		const url = window.URL.createObjectURL(blob);

		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", getFileNameFromUrl(fileName));
		document.body.appendChild(link);
		link.click();

		window.URL.revokeObjectURL(url);
		document.body.removeChild(link);
	}
	return null;
};
