import { Close, Download, OpenInNew } from "@mui/icons-material";
import { Box, Button, Stack, Switch, Typography } from "@mui/material";
import React from "react";
import { useDispatchWrapper } from "../../hooks";
import { IStandardPreviewProps } from "./StandardPreview.types";
import { previewAndDownloadAsync, previewAndDownloadSupplierOrderPdfAsync } from "./StandardPreview.actions";
import { showMessage } from "../../redux/messages/messagesActions";
import { RightPanel } from "../../components/RightPanel";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { EmailComposeDialog } from "../../components/MessagesDialogs/EmailCompose/EmailComposeDialog";

export const SupplierOrderPdfPreview: React.FC<IStandardPreviewProps> = (props) => {
	const { open, uuid, type, onClose } = props;
	const [previewLoading, setPreviewLoading] = React.useState(false);
	const [downloadLoading, setDownloadLoading] = React.useState(false);
	const [htmlContent, setHtmlContent] = React.useState("");
	const [openEmail, setOpenEmail] = React.useState(false);
	// const [isIndianStyle, setIsIndianStyle] = React.useState<boolean>(true);

	const dispatch = useDispatchWrapper();

	const fetchPreview = (preview_only = true) => {
		preview_only ? setPreviewLoading(true) : setDownloadLoading(true);
		dispatch(
			previewAndDownloadSupplierOrderPdfAsync(
				uuid,
				preview_only,
				(isSuccess, html) => {
					if (isSuccess && html) {
						setHtmlContent(html);
					}
					setPreviewLoading(false);
					setDownloadLoading(false);
				}
			)
		);
	};

	const handleDownloadPdf = async () => {
		fetchPreview(false);
	};

	const handleOpenInNewTab = () => {
		const newWindow = window.open();
		if (newWindow) {
			newWindow.document.write(htmlContent);
		}
	};

	const handleCopy = async () => {
		try {
			await navigator.clipboard.writeText(
				`${window.location.host}/public/invoices/${uuid}`
			);
			dispatch(
				showMessage({
					displayAs: "snackbar",
					message: "Link Copied!",
					type: "success",
				})
			);
		} catch (error) {
			dispatch(
				showMessage({
					displayAs: "snackbar",
					message: "Something went to be wrong!",
					type: "error",
				})
			);
		}
	};

	React.useEffect(() => {
		fetchPreview();
	}, []);

	return (
		<>
			<RightPanel
				heading={`Order Preview`}
				// subHeading="Quotes Preview"
				open={open}
				width="50%"
				onClose={onClose}
				actionButtons={() => {
					return (
						<Stack
							direction={"row"}
							justifyContent={"space-between"}
							spacing={2}
						>
							<Stack
								direction={"row"}
								spacing={2}
							>
								<Button
									variant="contained"
									onClick={onClose}
								>
									<Close sx={{ fontSize: 18, mr: 0.5 }} />
									Close
								</Button>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Typography
										variant="h5"
										sx={{ color: "GrayText" }}
										fontWeight={600}
									>
										International
									</Typography>
									{/* <Switch
										checked={isIndianStyle ? true : false}
										onChange={(e, checked) => setIsIndianStyle(!isIndianStyle)}
									/>
									<Typography
										variant="h5"
										sx={{ color: "GrayText" }}
										fontWeight={600}
									>
										Indian
									</Typography> */}
								</Box>
							</Stack>
							<Stack
								direction={"row"}
								spacing={2}
							>
								<Button
									variant="contained"
									onClick={handleOpenInNewTab}
								>
									<OpenInNew sx={{ fontSize: 18, mr: 0.5 }} />
									New Tab
								</Button>
								{/* <Button variant="contained" onClick={handleCopy}>
                  <CopyAll sx={{ fontSize: 18, mr: 0.5 }} />
                  Copy Link
                </Button>
                <Button variant="contained" onClick={() => setOpenEmail(true)}>
                  <Email sx={{ fontSize: 18, mr: 0.5 }} />
                  Email
                </Button> */}
								<Button
									variant="contained"
									onClick={handleDownloadPdf}
								>
									<Download sx={{ fontSize: 18, mr: 0.5 }} />
									Download
								</Button>
							</Stack>
						</Stack>
					);
				}}
				// showBackdrop={downloadLoading}
			>
				<PageLoader loading={previewLoading}>
					<div dangerouslySetInnerHTML={{ __html: htmlContent }} />
				</PageLoader>
			</RightPanel>
			<EmailComposeDialog
				open={openEmail}
				enableEmail
				onClose={() => setOpenEmail(false)}
			/>
		</>
	);
};
