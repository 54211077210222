import { action } from "typesafe-actions";
import { IQueryParams } from "../../common/common.types";
import { ISupplierPaymentTerm } from "./supplier-payment-terms.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { getSearchQuery } from "../../common/helpers";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { api_base_endpoints } from "../../../api/api-base-endpoints";

const internal_supplier_endpoint = api_base_endpoints.internal_supplier

export const FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_PROGRESS =
    "FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_PROGRESS";
export const FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_SUCCESS =
    "FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_SUCCESS";
export const FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_FAILED =
    "FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_FAILED";

export const fetchSingleSupplierPaymentTermListProgress = () =>
    action(FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_PROGRESS);
export const fetchSingleSupplierPaymentTermListSuccess = (
    data: ISupplierPaymentTerm[],
    totalRecords: number
) => action(FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_SUCCESS, { data, totalRecords });
export const fetchSingleSupplierPaymentTermListFailed = () =>
    action(FETCH_SINGLE_SUPPLIER_PAYMENT_TERM_LIST_FAILED);

export const fetchSingleSupplierPaymentTermListAsync =
    (
        queryParams: IQueryParams,
        supplierUUID?: string
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
        try {
            const searchQuery = getSearchQuery(queryParams);
            dispatch(fetchSingleSupplierPaymentTermListProgress());
            const res = await api.get(
                `${internal_supplier_endpoint.get_payment_term}${searchQuery}&supplier_uuid=${supplierUUID}`
            );
            const data: ISupplierPaymentTerm[] = res.data.data;

            const totalRecords = res.data.totalRecords;

            dispatch(fetchSingleSupplierPaymentTermListSuccess(data, totalRecords));
        } catch (err: any) {
            dispatch(fetchSingleSupplierPaymentTermListFailed());
            dispatch(
                showMessage({
                    type: "error",
                    message: err.response.data.message,
                    displayAs: "snackbar",
                })
            );
        }
    };

export const upsertSingleSupplierPaymentTermAsync =
    (
        data: ISupplierPaymentTerm,
        onCallback: (isSuccess: boolean, contact?: ISupplierPaymentTerm) => void
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
        try {
            dispatch(saveLoaderProgress());
            const { supplier_payment_term_unique_id, create_ts, insert_ts, ...rest } = data;
            const res = await api.post(internal_supplier_endpoint.upsert_payment_term, rest);

            onCallback(true, res.data.data);
            dispatch(
                showMessage({
                    type: "success",
                    message: "Payment Term saved successfully!",
                    displayAs: "snackbar",
                })
            );
        } catch (err: any) {
            onCallback(false);
            dispatch(
                showMessage({
                    type: "error",
                    message: err.response.data.message,
                    displayAs: "snackbar",
                })
            );
        } finally {
            dispatch(saveLoaderCompleted());
        }
    };




    export const FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_PROGRESS =
        "FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_PROGRESS";
    export const FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_SUCCESS = "FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_SUCCESS";
    export const FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_FAILED = "FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_FAILED";
    
    export const fetchSupplierSinglePaymentTermProgress = () =>
        action(FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_PROGRESS);
    export const fetchSupplierSinglePaymentTermSuccess = (data: ISupplierPaymentTerm) =>
        action(FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_SUCCESS, { data });
    export const fetchSupplierSinglePaymentTermFailed = (errorMessage: string) =>
        action(FETCH_SINGLE_SUPPLIER_PAYMENT_TERN_FAILED, { errorMessage });
    
    export const fetchSupplierSinglePaymentTermAsync =
        (contactId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                dispatch(fetchSupplierSinglePaymentTermProgress());
                const res = await api.get(
                    `${internal_supplier_endpoint.get_payment_term}?contact_uuid=${contactId}&pageNo=1&itemPerPage=1`
                );
                const data: ISupplierPaymentTerm[] = res.data.data;
                if (data.length > 0) {
                    dispatch(fetchSupplierSinglePaymentTermSuccess(data[0]));
                } else {
                    dispatch(fetchSupplierSinglePaymentTermFailed("Oops! We couldn't find any records."));
                }
            } catch (err: any) {
                dispatch(fetchSupplierSinglePaymentTermFailed("Something went to be wrong!"));
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    })
                );
            }
        };
    
    export const CLEAR_SINGLE_SUPPLIER_CONTACT = "CLEAR_SINGLE_SUPPLIER_CONTACT";