import React from "react";
import { SocketContext } from "./SocketProvider.context";

export function useSocketContext() {
  const context = React.useContext(SocketContext);

  if (!context) {
    throw new Error(
      "SocketContext: Context must be used inside AuthProvider",
    );
  }

  return context;
}



export const useExtractProductSocket = () => {
  const { socket } = useSocketContext();
  const [extractProductProcess, setExtractProductProcess] = React.useState<{
    status: "IN_PROGRESS" | "SUCCESS" | "FAILED",
    uuid: string | null
  }>({
    status: "IN_PROGRESS",
    uuid: null
  })

  React.useEffect(() => {
    if (!socket) return;
    
    socket.on("LINE_SHEET_EXTRACTED", (data: any) => {
      console.log("LINE_SHEET_EXTRACTED:", data.status);
      if (data.status === "IN_PROGRESS") {
        setExtractProductProcess((prev) => ({
          ...prev,
          status: "IN_PROGRESS",
        }))
      }
      if (data.status === "FAILED") {
        setExtractProductProcess((prev) => ({
          ...prev,
          status: "FAILED",
        }))
      }
      if (data.status === "SUCCESS") {
        setExtractProductProcess({
          status: "SUCCESS",
          uuid: data.product_documents_uuid
        })
      }
    });
    socket.on("KUCH_BHI", (data) => {
      console.log("KUCH_BHI Event:", socket.id, data);
    });
  }, []);

  return extractProductProcess;
}
