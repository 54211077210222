import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import { IQuillEditorProps } from "./QuillEditor.types";
import "react-quill/dist/quill.snow.css";
import { error_message } from "../../constants/constants";

export const QuillEditor: React.FC<IQuillEditorProps> = (props) => {
  const { value, onChange, error } = props;

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          ".ql-container": {
            minHeight: 200,
          },
        }}
      >
        <ReactQuill theme="snow" value={value} onChange={onChange} />
        {error && <Typography sx={{ color: "red", fontSize: "11px", p: 1 }}>*{error_message.required}</Typography>}
      </Box>
    </Grid>
  );
};
