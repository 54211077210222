import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { useAuth } from "../../../contexts/AuthProvider/AuthProvider";

const CustomBox = styled(Box)(({ theme }) => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const NoBillingComapnyScreen: React.FC<{}> = () => {
  const { logout } = useAuth();

  const handleLogoutClick = () => {
    logout();
    window.location.reload();
  };

  return (
    <CustomBox>
      <Typography variant="h4" sx={{ fontWeight: 600 }}>
        Ooops..! It Seems you don't have valid Billing Company.
      </Typography>
      <Typography variant="h6">Please contact your admin</Typography>
      <Typography variant="h6">or</Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Login with different account to continue
      </Typography>
      <Button variant="contained" onClick={handleLogoutClick}>
        Logout
      </Button>
    </CustomBox>
  );
};
