import { action } from "typesafe-actions";
import { IBillingCompany } from "./billingCompany.types";
import { IQueryParams } from "../common/common.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { getSearchQuery } from "../common/helpers";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";

export const FETCH_BILLING_COMPANY_LIST_PROGRESS =
  "FETCH_BILLING_COMPANY_LIST_PROGRESS";
export const FETCH_BILLING_COMPANY_LIST_SUCCESS =
  "FETCH_BILLING_COMPANY_LIST_SUCCESS";
export const FETCH_BILLING_COMPANY_LIST_FAILED =
  "FETCH_BILLING_COMPANY_LIST_FAILED";

export const fetchBillingCompanyListProgress = () =>
  action(FETCH_BILLING_COMPANY_LIST_PROGRESS);
export const fetchBillingCompanyListSuccess = (
  data: IBillingCompany[],
  totalRecords: number,
) => action(FETCH_BILLING_COMPANY_LIST_SUCCESS, { data, totalRecords });
export const fetchBillingCompanyListFailed = () =>
  action(FETCH_BILLING_COMPANY_LIST_FAILED);

export const fetchBillingCompanyListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchBillingCompanyListProgress());
      let finalUrl = `/billing_company/get-billing-company${searchQuery}`;
      const res = await api.get(finalUrl);
      const data: IBillingCompany[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchBillingCompanyListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchBillingCompanyListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertBillingCompanyAsync =
  (
    billingCompany: IBillingCompany,
    onCallback: (isSuccess: boolean, billingCompany?: IBillingCompany) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const { create_ts, insert_ts, ...rest } = billingCompany;
      const res = await api.post(
        "/billing_company/upsert-billing-company",
        rest,
      );
      const data = res.data.data;
      onCallback(true, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Company saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BILLING_COMPANY_PROGRESS = "FETCH_BILLING_COMPANY_PROGRESS";
export const FETCH_BILLING_COMPANY_SUCCESS = "FETCH_BILLING_COMPANY_SUCCESS";
export const FETCH_BILLING_COMPANY_FAILED = "FETCH_BILLING_COMPANY_FAILED";

export const fetchBillingCompanyProgress = () =>
  action(FETCH_BILLING_COMPANY_PROGRESS);
export const fetchBillingCompanySuccess = (data: IBillingCompany) =>
  action(FETCH_BILLING_COMPANY_SUCCESS, { data });
export const fetchBillingCompanyFailed = (errorMessage: string) =>
  action(FETCH_BILLING_COMPANY_FAILED, { errorMessage });

export const fetchBillingCompanyAsync =
  (billingCompanyUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBillingCompanyProgress());
      const res = await api.get(
        `/billing_company/get-billing-company?billing_company_uuid=${billingCompanyUUID}`,
      );
      const data: IBillingCompany[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBillingCompanySuccess(data[0]));
      } else {
        dispatch(
          fetchBillingCompanyFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchBillingCompanyFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_BILLING_COMPANY = "CLEAR_BILLING_COMPANY";
export const CLEAR_BILLING_COMPANY_STATE = "CLEAR_BILLING_COMPANY_STATE";

export const clearBillingCompany = () => action(CLEAR_BILLING_COMPANY);
export const clearBillingCompanyState = () =>
  action(CLEAR_BILLING_COMPANY_STATE);
