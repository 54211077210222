import React from "react";
import { ITabsListProps } from "./TabsList.types";
import { Tab, TabProps, Tabs, TabsProps, styled } from "@mui/material";
import { a11yProps } from "./utils";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const TabsList: React.FC<ITabsListProps> = (props) => {
  const { selectedTab, varaint, tabs, onChange } = props;
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  return (
    <CustomTabs
      value={selectedTab}
      variant={varaint}
      scrollButtons="auto"
      sx={props.sx}
      onChange={onChange}
      TabIndicatorProps={{ hidden: true }}
      orientation={isSmallScreen ? "vertical" : "horizontal"}
    >
      {tabs.map((tab, index) => {
        if (tab.isHidden) return <></>;
        return (
          <CustomTab
            icon={tab.icon}
            iconPosition={tab.iconPosition || "start"}
            label={tab.label}
            disabled={tab.disabled}
            {...a11yProps(index)}
          />
        );
      })}
    </CustomTabs>
  );
};

export const CustomTabs = styled((props: TabsProps) => <Tabs {...props} />)(
  ({ theme }) => ({
    backgroundColor: `${
      theme.palette.mode === "dark" ? "rgba(73,82,88,0.12)" : "#fff"
    }`,
    boxShadow: "rgba(90, 114, 123, 0.11) 0px 7px 30px 0px",
    padding: "8px",
    borderRadius: "15px",
    "& .Mui-selected": {
      //background: theme.palette.primary.main,
      // color: "#fff !important",
      borderRadius: "15px",
      fontWeight: 800,
    },
    "& .MuiTabs-flexContainer": {
      alignItems: "baseline",
    },
  }),
);

export const CustomTab = styled((props: TabProps) => <Tab {...props} />)(
  ({ theme }) => ({
    fontSize: "1rem",
    padding: "15px",
    marginRight: 15,
  }),
);
