/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, MenuItem } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CustomOnClickWrapper } from "../../../../components/CustomLink/CustomClickWrapper";
import { StandardTableActions } from "../../../../components/StandardTableActions/StandardTableActions";
import { DataTable } from "../../../../components/Table/DataTable";
import {
	IDataTableProps,
	MobileLogoRenderType,
	RenderType,
} from "../../../../components/Table/interfaces/IDataTableProps";
import {
	CSVExportPlugin,
	RefreshPlugin,
	SearchPlugin,
} from "../../../../components/Table/plugins";
import { PageContainer } from "../../../../components/container/PageContainer";
import { CustomSelect } from "../../../../components/formsComponents";
import { LoadState } from "../../../../constants/enums";
import { useDispatchWrapper } from "../../../../hooks";

import {
	IContact,
	initialContactState,
} from "../../../../redux/contacts/contacts.types";

import { IStoreState } from "../../../../redux/initialStoreState";
import { ManageCustomerContact } from "./ManageCustomerContact";
import {
	clearContact,
	fetchContactsListAsync,
} from "../../../../redux/contacts/contactsActions";

export const CustomerContactList = () => {
	const { uuid } = useParams() as { uuid?: string };

	const { list, totalRecords, loading } = useSelector(
		(storeState: IStoreState) => storeState.contacts
	);
	const { data: customerData, error } = useSelector(
		(storeState: IStoreState) => storeState.customer.customer
	);

	const [status, setStatus] = React.useState<IContact["status"] | "-1">("-1");
	const [searchColumns, setSearchColumns] = React.useState<string[]>([
		"contact_name",
	]);
	const [search, setSearch] = React.useState<string>("");
	const [pagination, setPagination] = React.useState<{
		pageNumber: number;
		rowsPerPage: number;
	}>({ pageNumber: 1, rowsPerPage: 10 });

	const dispatch = useDispatchWrapper();
	const navigate = useNavigate();

	// ******Right panel************
	const [openDialog, setOpenDialog] = React.useState<boolean>(false);
	const [dialogData, setDialogData] = React.useState<IContact>(
		initialContactState["contact"]
	);

	const handleOpenDialog = () => {
		setOpenDialog(true);
		setDialogData({
			...initialContactState["contact"],
			company_name: customerData.company_name,
			company_uuid: customerData.company_uuid,
		});
	};
	const handleCloseDialog = () => {
		setOpenDialog(false);
		dispatch(clearContact());
	};
	const handleComplete = () => {
		setOpenDialog(false);
		dispatch(clearContact());
		fetchList();
	};

	const contactsTableProps: IDataTableProps = {
		isPagination: true,
		totalRecords: totalRecords,
		rowsPerPageOptions: pagination.rowsPerPage,
		isDataLoading: loading !== LoadState.Loaded,
		selectionMode: "none",
		uniqueRowKeyName: "id",
		tableCommandBarProps: {
			leftItems: {
				customPlugins: [
					{
						key: "status",
						onRender: () => (
							<CustomSelect
								sx={{ minWidth: "120px" }}
								value={status}
								onChange={(e: any) => setStatus(e.target.value as "ACTIVE")}
							>
								<MenuItem value="-1">All Status</MenuItem>
								<MenuItem value="ACTIVE">Active</MenuItem>
								<MenuItem value="INACTIVE">Inactive</MenuItem>
							</CustomSelect>
						),
					},
					{
						key: "csvExport",
						onRender: (columns, items) => (
							<CSVExportPlugin
								columns={columns}
								items={items}
								filePrefixName="Report"
							/>
						),
					},
					{
						key: "refresh",
						onRender: () => <RefreshPlugin onClick={fetchList} />,
					},
				],
			},
			rightItems: {
				plugins: {},
				customPlugins: [
					{
						key: "search",
						onRender: () => {
							return (
								<SearchPlugin
									selectedDropdownValue={searchColumns}
									dropdownOptions={[
										{ label: "Contact Name", value: "contact_name" },
										{ label: "Designation", value: "designation" },
										{ label: "Conatct Type", value: "contact_type" },
										{ label: "Contact Number", value: "contact_number" },
										{ label: "Email ID", value: "mail_id" },
									]}
									onDropdownChange={(value) => setSearchColumns(value)}
									onChange={(newValue) => {
										setSearch(newValue);
									}}
								/>
							);
						},
					},
				],
			},
		},
		mobileLogo: {
			type: MobileLogoRenderType.reactNode,
			onMobileLogoRender: (data: any) => {
				return (
					<Avatar
						sx={{
							width: 45,
							height: 45,
							color: "#fff",
							ml: "-8px",
							mr: 2,
						}}
					></Avatar>
				);
			},
		},
		columns: [
			{
				key: "view",
				headerName: "Actions",
				fieldName: "",
				headingAlign: "center",
				renderType: RenderType.CUSTOM_RENDER,
				onRowCellRender: (value, row: IContact) => {
					return (
						<StandardTableActions
							onEditClick={() => {
								setOpenDialog(true);
								setDialogData(row);
							}}
						/>
					);
				},
			},
			{
				key: "name",
				headerName: "Contact Name",
				fieldName: "name",
				enableSorting: true,
				renderType: RenderType.CUSTOM_RENDER,
				exportCellWidth: 15,
				onRowCellRender: (value, row: IContact) => {
					return (
						<CustomOnClickWrapper
							label={row.name || ""}
							onClick={() => {
								setOpenDialog(true);
								setDialogData(row);
							}}
						/>
					);
				},
			},

			{
				key: "designation",
				headerName: "Designation",
				fieldName: "designation",
				renderType: RenderType.TEXT,
				exportCellWidth: 30,
				enableSorting: true,
			},
			{
				key: "contact_type",
				headerName: "Contact Type",
				fieldName: "contact_type",
				renderType: RenderType.TEXT,
				exportCellWidth: 30,
				enableSorting: true,
			},
			{
				key: "contact_number",
				headerName: "Contact Number",
				fieldName: "contact_number",
				renderType: RenderType.TEXT,
				exportCellWidth: 30,
				enableSorting: true,
			},
			{
				key: "mail_id",
				headerName: "Email Id",
				fieldName: "mail_id",
				renderType: RenderType.TEXT,
				exportCellWidth: 30,
				enableSorting: true,
			},
		],
		items: list,
		onPageChange: (newPageNumber: number) => {
			setPagination({ ...pagination, pageNumber: newPageNumber });
		},
		onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
			setPagination({
				pageNumber: pageNumber,
				rowsPerPage: rowsPerPage,
			});
		},
	};

	const fetchList = () => {
		dispatch(
			fetchContactsListAsync({
				status: status,
				page: pagination.pageNumber,
				rowsPerPage: pagination.rowsPerPage,
				columns: searchColumns,
				value: search,
			})
		);
	};

	React.useEffect(() => {
		fetchList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, status, search]);

	return (
		<PageContainer
			title=""
			description=""
		>
			<Box
				display={"flex"}
				justifyContent={"end"}
				sx={{
					mx: 5,
					my: 2,
				}}
			>
				<Button
					variant="contained"
					onClick={() => handleOpenDialog()}
				>
					Add Contact
				</Button>
			</Box>
			<DataTable {...contactsTableProps} />
			{openDialog && (
				<ManageCustomerContact
					open={openDialog}
					data={dialogData}
					onClose={() => handleCloseDialog()}
					onComplete={() => handleComplete()}
				/>
			)}
		</PageContainer>
	);
};
