import { api } from "../../api/api";

const convertToBase64 = (file: any) => {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const convertBase64ToBlob = (base64Image: string) => {
  const parts = base64Image.split(";base64,");
  const imageType = parts[0].split(":")[1];
  const decodedData = window.atob(parts[1]);
  const uInt8Array = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }
  return new Blob([uInt8Array], { type: imageType });
};

export const isImageFile = (fileName: string): boolean => {
  const extension = fileName.split(".").pop();
  if (
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === "png" ||
    extension === "webp" ||
    extension === "jfif" ||
    extension === "tif"
  )
    return true;
  return false;
};

export const isPdfFile = (fileName: string): boolean => {
  const extension = fileName.split(".").pop();
  if (extension === "pdf") return true;
  return false;
};

export const isExcelFile = (fileName: string): boolean => {
  const extension = fileName.split(".").pop();
  if (extension === "xls" || extension === "xlsx") return true;
  return false;
};

export type IFileExtension = "IMAGE" | "PDF" | "EXCEL" | "OTHER";
export const getFileExtensionType = (fileName: string): IFileExtension => {
  const extension = fileName.split(".").pop();
  if (
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === "png" ||
    extension === "webp" ||
    extension === "jfif" ||
    extension === "tif"
  )
    return "IMAGE";
  if (extension === "pdf") return "PDF";
  if (extension === "xls" || extension === "xlsx") return "EXCEL";
  return "OTHER";
};

export const getBase64Extension = (extension: string): string => {
  const fileType = getFileExtensionType(extension);
  if (fileType === "IMAGE") return `data:image/${extension};base64`;
  if (fileType === "EXCEL")
    return `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64`;
  return `data:application/${extension};base64`;
};

const arrayBufferToBase64 = (buffer: any, extension: string): string => {
  return `${getBase64Extension(extension)},${btoa(
    String.fromCharCode.apply(null, buffer),
  )}`;
};

export const downloadSingleFileAsync = async (fileUrl: string) =>
  new Promise<{ fileName: string; data: string; type: IFileExtension }>(
    (resolve, reject) => {
      const payload = {
        type: "json",
        keys: [fileUrl],
      };
      api
        .post("/general/download-files", payload)
        .then((res) => {
          const fileName = fileUrl.split("/")[fileUrl.split("/").length - 1];
          const fileExtension = fileName.split(".")[1];
          const base64Data = arrayBufferToBase64(
            res.data[0]?.data,
            fileExtension,
          );
          resolve({
            fileName: fileName,
            data: base64Data,
            type: getFileExtensionType(fileUrl),
          });
        })
        .catch((err) => {
          reject(err);
        });
    },
  );

export const convertBase64ToUrl = (
  base64: string,
  type = "application/octet-stream",
) => {
  const binStr = atob(base64);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  const blob = new Blob([arr], { type: type });
  return URL.createObjectURL(blob);
};
