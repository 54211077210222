export const APP_ICT_ROUTE = "/ict";
export const APP_ICT_IMPORTS_ROUTE = APP_ICT_ROUTE + "/excel";


export const ict_sample_data = [
    {
        "product_vpn": "PROD123456",
        "primary_image_url": "https://example.com/images/product123.jpg",
        "barcode": "789456123012",
        "style": "Casual T-Shirt",
        "color": "Blue",
        "fabric": "100% Cotton",
        "size": "L",
        "description": "A comfortable blue casual T-shirt made from premium cotton.",
        "category": "Apparel",
        "class": "Tops",
        "subclass": "T-Shirts",
        "season": "Summer 2024",
        "line": "Summer Casual",
        "subline": "Premium Collection",
        "gender": "Unisex",
        "brand": "FashionWear",
        "unit_cost": 12.50,
        "rsp": 25.99,
        "quantity": 100,
        "currency": "USD",
        "supplier": "ABC Textiles Ltd.",
        "supplier_code": "SUP1234",
        "zone": "North America",
        "area": "West Coast",
        "season_uda": "SS24",
        "recurrence": "Bi-Annual",
        "po_number": "PO56789",
        "delivery_start_date": "2025-02-01",
        "delivery_end_date": "2025-02-15",
        "taxomany": "Retail > Apparel > Tops",
        "usage_specificity": "Everyday Wear",
        "country_manufacture": "India",
        "country_shipment": "United States",
        "discount": 10,
        "mark_up": 2.5,
        "hts_code": "6109100020",
        "made_of": "Cotton"
    },
    {
        "product_vpn": "PROD987654",
        "primary_image_url": "https://example.com/images/product987.jpg",
        "barcode": "654321987012",
        "style": "Formal Shirt",
        "color": "White",
        "fabric": "Polyester",
        "size": "M",
        "description": "A sleek white formal shirt, perfect for office wear.",
        "category": "Apparel",
        "class": "Tops",
        "subclass": "Shirts",
        "season": "Winter 2024",
        "line": "Winter Formal",
        "subline": "Standard Collection",
        "gender": "Men",
        "brand": "OfficePro",
        "unit_cost": 18.00,
        "rsp": 36.00,
        "quantity": 50,
        "currency": "USD",
        "supplier": "XYZ Garments Ltd.",
        "supplier_code": "SUP5678",
        "zone": "Europe",
        "area": "Central",
        "season_uda": "FW24",
        "recurrence": "Annual",
        "po_number": "PO12345",
        "delivery_start_date": "2025-11-01",
        "delivery_end_date": "2025-11-10",
        "taxomany": "Retail > Apparel > Shirts",
        "usage_specificity": "Office Wear",
        "country_manufacture": "Bangladesh",
        "country_shipment": "Germany",
        "discount": 15,
        "mark_up": 2.0,
        "hts_code": "6205200010",
        "made_of": "Polyester"
    },
    {
        "product_vpn": "PROD654321",
        "primary_image_url": "https://example.com/images/product654.jpg",
        "barcode": "321789654012",
        "style": "Hooded Jacket",
        "color": "Black",
        "fabric": "Wool Blend",
        "size": "XL",
        "description": "A warm black hooded jacket, ideal for cold weather.",
        "category": "Apparel",
        "class": "Outerwear",
        "subclass": "Jackets",
        "season": "Winter 2024",
        "line": "Winter Essentials",
        "subline": "Deluxe Collection",
        "gender": "Unisex",
        "brand": "WinterGear",
        "unit_cost": 30.00,
        "rsp": 60.00,
        "quantity": 75,
        "currency": "USD",
        "supplier": "PQR Fashions",
        "supplier_code": "SUP9101",
        "zone": "Asia",
        "area": "South Asia",
        "season_uda": "FW24",
        "recurrence": "Annual",
        "po_number": "PO67890",
        "delivery_start_date": "2025-12-01",
        "delivery_end_date": "2025-12-20",
        "taxomany": "Retail > Apparel > Jackets",
        "usage_specificity": "Winter Wear",
        "country_manufacture": "China",
        "country_shipment": "India",
        "discount": 5,
        "mark_up": 2.3,
        "hts_code": "6201300020",
        "made_of": "Wool Blend"
    }
]


export const getIctColumnSampleData = (columnName: string): string[] => {
    return ict_sample_data.map((data) => data[columnName as "product_vpn"])
}