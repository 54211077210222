import { Grid } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import LoadingDialog from "../../../../components/Dialogs/LoadingDialog";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";
import {
	CustomFormLabel,
	CustomTextField,
} from "../../../../components/formsComponents";
import { useDispatchWrapper } from "../../../../hooks";
import {
	clearCustomerVendorAttachment,
	ICustomerVendorAttachment,
	upsertCustomerVendorAttachmentAsync,
} from "../../../../redux/customerVendorAttachment";
import { showMessage } from "../../../../redux/messages/messagesActions";

export interface IManageCustomerVendorAttachment {
	open: boolean;
	data: ICustomerVendorAttachment;
	onClose: () => void;
	onComplete: () => void;
}

export const ManageCustomerVendorAttachment: React.FC<
	IManageCustomerVendorAttachment
> = (props) => {
	const { open, data, onClose, onComplete } = props;
	const isUpdate = data?.customer_attachment_uuid ? true : false;
	const dispatch = useDispatchWrapper();
	const [file, setFile] = useState<any>(null);
	const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
	const navigate = useNavigate();
	const {
		values,
		errors,
		handleChange,
		handleSubmit,
		setValues,
		setFieldValue,
	} = useFormik({
		initialValues: data,
		validate: (values) => {
			const errors: any = {};

			return errors;
		},
		onSubmit: (values) => {
			setSaveLoading(true);
			dispatch(
				upsertCustomerVendorAttachmentAsync(
					{
						...values,
						company_name: values.company_name,
						company_uuid: values.company_uuid,
					},
					file,
					(isSuccess) => {
						if (isSuccess) {
							dispatch(
								showMessage({
									displayAs: "snackbar",
									message: "Attachment updated successfully.",
									type: "success",
								})
							);
							onComplete();
						}
						setSaveLoading(false);
					}
				)
			);
		},
	});

	React.useEffect(() => {
		return () => {
			dispatch(clearCustomerVendorAttachment());
		};
	});

	return (
		<Dialog
			open={open}
			title={isUpdate ? "Edit Attachment" : "Add Attachment"}
			subtitle={`Customer: ${values.company_name}`}
			onClose={onClose}
			size="sm"
			contentWrappedWithForm={{ onSubmit: handleSubmit }}
			actions={[
				{
					type: "button",
					label: "Close",
					variant: "text",
					onClick: onClose,
				},
				{
					type: "submit",
					label: "Save",
					variant: "contained",
				},
			]}
		>
			<Grid
				container
				spacing={1}
			>
				<Grid
					item
					xs={12}
					lg={12}
				>
					<CustomFormLabel>Attachement Name</CustomFormLabel>
					<CustomTextField
						name="customer_attachment_name"
						variant="outlined"
						size="small"
						type="text"
						fullWidth
						value={values.customer_attachment_name}
						error={errors.customer_attachment_name ? true : false}
						helperText={errors.customer_attachment_name}
						onChange={handleChange}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={12}
				>
					<CustomFormLabel>Branch Name</CustomFormLabel>
					<FileUpload
						value={data.link}
						onChange={(value) => setFile(value)}
					/>
				</Grid>
			</Grid>
			<LoadingDialog open={saveLoading} />
		</Dialog>
	);
};
