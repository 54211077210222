import React, { ReactNode } from "react";
import {
	Box,
	CircularProgress,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearch";
import EditIcon from "@mui/icons-material/Edit";
import { ContextMenu } from "../ContextMenu/ContextMenu";
import AddIcon from "@mui/icons-material/Add";
import {
	ContentCopyOutlined,
	DeleteOutlineOutlined,
	Download,
	FeaturedPlayListOutlined,
	PublishedWithChanges,
	SortOutlined,
} from "@mui/icons-material";

import { Dialog } from "../Dialogs/Dialog";
import HistoryIcon from "@mui/icons-material/History";
import CommentIcon from "@mui/icons-material/Comment";

export interface IMenuOption {
	icon: React.ReactNode;
	label: string;
	disabled?: boolean;
	onClick?: () => void;
}
export interface IContextMenuProps {
	menuOptions: IMenuOption[];
}

interface IStandardTableActionsProps {
	status?: string;
	onDeleteClick?: () => void;
	onReactiveClick?: () => void;
	commentBoxTypeId?: string | number;
	onViewClick?: () => void;
	onAddClick?: () => void;
	onEditClick?: () => void;
	disableEdit?: boolean;
	onViewHistoryClick?: () => void;
	onCommentClick?: () => void;
	onDownloadPreview?: () => void;
	onDownLoadClick?: () => void;
	onDuplicateClick?: () => void;
	onViewDetails?: () => void;
	downloadLoading?: boolean;
	customIcons?:ReactNode
	endIcons?:ReactNode
	more?: {
		menuItems: IContextMenuProps["menuOptions"];
	};
}

export const StandardTableActions: React.FC<IStandardTableActionsProps> = (
	props
) => {
	const {
		status,
		onDeleteClick,
		onReactiveClick,
		onViewClick,
		onEditClick,
		onAddClick,
		onViewHistoryClick,
		onCommentClick,
		onDownLoadClick,
		onDownloadPreview,
		onDuplicateClick,
		onViewDetails,
		downloadLoading,
		more,
		commentBoxTypeId,
		disableEdit,
		customIcons,
		endIcons
	} = props;

	const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

	return (
		<>
			<Stack
				direction={"row"}
				justifyContent={"center"}
				sx={{
					svg: {
						cursor: "pointer",
					},
				}}
				spacing={2}
			>
				{customIcons}
				{onDeleteClick && status !== "INACTIVE" && status !== "ARCHIVE" && (
					<Box onClick={() => setOpenDeleteModal(true)}>
						<Tooltip title="Delete">
							<DeleteOutlineOutlined
								sx={{ cursor: "pointer" }}
								fontSize="small"
								color="error"
							/>
						</Tooltip>
					</Box>
				)}
				{onReactiveClick && status === "INACTIVE" && (
					<Box onClick={() => setOpenDeleteModal(true)}>
						<Tooltip title="Reactive">
							<PublishedWithChanges
								sx={{ cursor: "pointer" }}
								fontSize="small"
								color="error"
							/>
						</Tooltip>
					</Box>
				)}

				{onViewClick && (
					<Box onClick={onViewClick}>
						<Tooltip title="View">
							<VisibilityIcon color="primary" />
						</Tooltip>
					</Box>
				)}

				{onEditClick && (
					<Box onClick={() => (disableEdit ? null : onEditClick())}>
						<Tooltip title="Edit">
							<EditIcon color={disableEdit ? "disabled" : "primary"} />
						</Tooltip>
					</Box>
				)}

				{onAddClick && (
					<Box onClick={onAddClick}>
						<Tooltip title="Add">
							<AddIcon color={disableEdit ? "disabled" : "primary"} />
						</Tooltip>
					</Box>
				)}
				{onDuplicateClick && (
					<Box onClick={onDuplicateClick}>
						<Tooltip title="Duplicate">
							<ContentCopyOutlined color="primary" />
						</Tooltip>
					</Box>
				)}
				{onViewHistoryClick && (
					<Box onClick={onViewHistoryClick}>
						<Tooltip title="Activity">
							<HistoryIcon color="primary" />
						</Tooltip>
					</Box>
				)}
				{onCommentClick && (
					<Box onClick={onCommentClick}>
						<Tooltip title="Activity">
							<CommentIcon color="primary" />
						</Tooltip>
					</Box>
				)}
				{onDownloadPreview && (
					<Box onClick={onDownloadPreview}>
						<Tooltip title="Preview">
							<ContentPasteSearchOutlinedIcon color="primary" />
						</Tooltip>
					</Box>
				)}
				{onViewDetails && (
					<Box onClick={onViewDetails}>
						<Tooltip title="View Details">
							<SortOutlined color="primary" />
						</Tooltip>
					</Box>
				)}
				{onDownLoadClick && (
					<Box onClick={!downloadLoading ? onDownLoadClick : undefined}>
						<Tooltip title="Download">
							<Download color="primary" />
						</Tooltip>
					</Box>
				)}
				{endIcons}
				{more && <ContextMenu menuOptions={more.menuItems}></ContextMenu>}
			</Stack>
			{downloadLoading && (
				<Dialog
					open={downloadLoading || false}
					size="sm"
					onClose={() => {}}
					title=""
				>
					<Stack
						minHeight={"200px"}
						justifyContent={"center"}
						direction={"column"}
						alignItems={"center"}
					>
						<CircularProgress size={40} />
						<Typography
							variant="h3"
							marginTop={2}
						>
							Download in progress..
						</Typography>
					</Stack>
				</Dialog>
			)}

			{openDeleteModal && (
				<Dialog
					title={
						status === "INACTIVE"
							? "Are you sure you want to reactive?"
							: "Are you sure you want to delete?"
					}
					size="sm"
					open={openDeleteModal}
					onClose={() => setOpenDeleteModal(false)}
					actions={[
						{
							type: "button",
							label: "Close",
							variant: "text",
							onClick: () => setOpenDeleteModal(false),
						},
						{
							type: "submit",
							label: "Confirm",
							variant: "contained",
							onClick: status === "INACTIVE" ? onReactiveClick : onDeleteClick,
						},
					]}
				/>
			)}
		</>
	);
};
