import { Preview, SortOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import LoadingDialog from "../../../components/Dialogs/LoadingDialog";
import {
	downLoadFile,
} from "../../../components/FileUpload/utils";

import {
	downloadSingleFileAsync,
	getFileExtensionType,
	IFileExtension,
} from "../../FileUploadWithAI/components/PreviewPdfFile";

import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { DataTable } from "../../../components/Table/DataTable";

import {
	IDataTableProps,
	RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import { RefreshPlugin } from "../../../components/Table/plugins";
import { useDispatchWrapper } from "../../../hooks";

import { IStoreState } from "../../../redux/initialStoreState";
import {
	closeLoaderWithMessage,
	openLoaderWithMessage,
} from "../../../redux/messages/messagesActions";
import { UserDocumentsDialog } from "../components/userDocumentDialog";

import { IUserDocument } from "../../../redux/userDocument/userDocument.types";
import {
	fetchInternalUserDocumentsListAsync,
	fetchUserDocumentsListAsync,
} from "../../../redux/userDocument/userDocumentAction";
import { ViewImageDialog } from "../../../components/FileUploadIcon/ViewImageDialog";
import { ViewDiscrepancyDialog } from "./ViewDiscrepancyDialog";

const INITIAL_STATE: IUserDocument = {
	orders_uuid: "",
	description: "",
	file_url: "",
	type: "",
	order_documents_uuid: null
};
interface UserDocumentsProps {
	orderUUID: string;
	isExternal: boolean;
}

export const UserDocuments: React.FC<UserDocumentsProps> = ({
	orderUUID,
	isExternal,
}) => {
	const dispatch = useDispatchWrapper();

	const [open, setOpen] = React.useState<IUserDocument | null>(null);
	const [openDiscrepancyDialog, setOpenDiscrepancyDialog] = React.useState<string | null>(null);

	const [pagination, setPagination] = React.useState<{
		pageNumber: number;
		rowsPerPage: number;
	}>({ pageNumber: 1, rowsPerPage: 10 });

	const [imageDialog, setImageDialog] = useState<{
		view: boolean;
		data: string;
		type: IFileExtension;
	}>({ view: false, data: "", type: "OTHER" });

	const { data, totalRecords } = useSelector(
		(storeState: IStoreState) => storeState.userDocument.list
	);

	// const handleAdd = () => {
	// 	if (leadId) {
	// 		setOpen({ ...INITIAL_STATE, enquiry_no: enquiryNumber });
	// 	}
	// };

	const handleAdd = () => {
		setOpen({ ...INITIAL_STATE, type: "OTHER", orders_uuid: orderUUID });
	};

	const fetchList = () => {
		if (isExternal === true) {
			dispatch(
				fetchUserDocumentsListAsync(
					orderUUID,
					pagination.pageNumber,
					pagination.rowsPerPage
				)
			);
		} else {
			dispatch(
				fetchInternalUserDocumentsListAsync(
					orderUUID,
					pagination.pageNumber,
					pagination.rowsPerPage
				)
			);
		}
	};

	const handleComplete = () => {
		fetchList();
		setOpen(null);
	};
	const [downloadLoading, setDownloadLoading] = React.useState(false);

	const handleDownloadClick = async (row: IUserDocument) => {
		try {
			setDownloadLoading(true);
			await downLoadFile(row.file_url, isExternal);
		} catch (err: any) {
			dispatch(err.response.data.message);
		} finally {
			setDownloadLoading(false);
		}
	};

	const userTableProps: IDataTableProps = {
		isPagination: true,
		// totalRecords: list.length,
		rowsPerPageOptions: pagination.rowsPerPage,
		// isDataLoading: loading !== LoadState.Loaded,
		selectionMode: "none",
		uniqueRowKeyName: "id",
		tableCommandBarProps: {
			leftItems: {
				customPlugins: [
					{
						key: "refresh",
						onRender: () => <RefreshPlugin onClick={fetchList} />,
					},
				],
			},
			rightItems: {
				plugins: {},
				customPlugins: [
					{
						key: "",
						onRender: (columns, items) => {
							return (
								<Stack
									direction={"row"}
									spacing={2}
								>
									<Button
										size="small"
										variant="contained"
										onClick={handleAdd}
									>
										{/* <Add fontSize="small" /> */}
										Upload Document
									</Button>
								</Stack>
							);
						},
					},
				],
			},
		},

		columns: [
			{
				key: "type",
				headerName: "Document Type",
				fieldName: "type",
				renderType: RenderType.CHIP_WARNING,
				exportCellWidth: 20,
				enableSorting: true,
			},

			// {
			// 	key: "description",
			// 	headerName: "Name",
			// 	fieldName: "description",
			// 	renderType: RenderType.TEXT,
			// 	exportCellWidth: 20,
			// 	onRowCellRender: (value, row: IUserDocument) => {
			// 		return (
			// 			<CustomFormLabel sx={{ fontWeight: 600 }}>
			// 				{getFileNameFromUrl(row.file_url).slice(0, 15) + "..."}
			// 			</CustomFormLabel>
			// 		);
			// 	},
			// },
			// {
			// 	key: "description",
			// 	headerName: "Type",
			// 	fieldName: "description",
			// 	renderType: RenderType.TEXT,
			// 	exportCellWidth: 20,
			// 	onRowCellRender: (value, row: IUserDocument) => {
			// 		return (
			// 			<CustomFormLabel sx={{ fontWeight: 600 }}>
			// 				{getFileExtensionType(row.file_url)}
			// 			</CustomFormLabel>
			// 		);
			// 	},
			// },

			{
				key: "description",
				headerName: "Description",
				fieldName: "description",
				renderType: RenderType.TEXT,
				exportCellWidth: 20,
				enableSorting: true,
			},
			{
				key: "version",
				headerName: "Version",
				fieldName: "version",
				renderType: RenderType.TEXT,
				exportCellWidth: 20,
				enableSorting: true,
			},

			{
				key: "insert_ts",
				headerName: "Date",
				fieldName: "insert_ts",
				enableSorting: true,
				renderType: RenderType.DATE_DARK_COLOR,
				exportCellWidth: 25,
			},
			{
				key: "actions",
				headerName: "Actions",
				fieldName: "",
				headingAlign: "center",
				renderType: RenderType.CUSTOM_RENDER,
				onRowCellRender: (value, row: IUserDocument) => {
					return (
						<StandardTableActions
							customIcons={row.type === "ORDER_CONFIRMATION" &&
								<>
									<Box onClick={() => setOpenDiscrepancyDialog(row.orders_uuid)}>
										<Tooltip title="View Discrepancy Report">
											<Preview color="primary" />
										</Tooltip>
									</Box>
								</>
							}
							onViewClick={() => handleViewDocumnetClick(row)}
							onEditClick={() => setOpen({ ...row })}
							onDownLoadClick={() => handleDownloadClick(row)}
						/>
					);
				},
			},
		],

		items: data,
		onPageChange: (newPageNumber: number) => {
			setPagination({ ...pagination, pageNumber: newPageNumber });
		},
		onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
			setPagination({
				pageNumber: pageNumber,
				rowsPerPage: rowsPerPage,
			});
		},
	};

	React.useEffect(() => {
		fetchList();
	}, [pagination, open]);

	const handleViewDocumnetClick = (rowData: any) => {
		// setButtonLoading(true);

		const fileType = getFileExtensionType(rowData.file_url);
		console.log("fileType", fileType);
		if (fileType === "EXCEL") {
			setImageDialog({ view: true, data: rowData.file_url, type: fileType });
		} else {
			dispatch(openLoaderWithMessage("Loading Please wait...."));
			downloadSingleFileAsync(rowData.file_url, isExternal)
				.then((result) => {
					setImageDialog({ view: true, data: result.data, type: result.type });
				})
				.finally(() => {
					dispatch(closeLoaderWithMessage());
				});
		}
	};

	return (
		<>
			<DataTable {...userTableProps} />
			{open && (
				<UserDocumentsDialog
					open={true}
					data={open}
					isExternal={isExternal}
					onClose={() => setOpen(null)}
					onComplete={handleComplete}
				/>
			)}

			<LoadingDialog open={downloadLoading} />

			{imageDialog.view && (
				<ViewImageDialog
					open={imageDialog.view}
					file={imageDialog.data}
					fileType={imageDialog.type}
					onClose={() =>
						setImageDialog({ view: false, data: "", type: "OTHER" })
					}
				/>
			)}
			{openDiscrepancyDialog && <ViewDiscrepancyDialog
				open={true}
				order_uuid={openDiscrepancyDialog}
				onClose={() => setOpenDiscrepancyDialog(null)}
			/>}
		</>
	);
};
