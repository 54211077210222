import React from "react";
import {
	IDataTableProps,
	MobileLogoRenderType,
	RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import { CustomSelect } from "../../components/formsComponents";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { useAuth } from "../../contexts/AuthProvider/AuthProvider";
import { useTheme } from "@emotion/react";
import { useDateFilter } from "../../components/Table/hooks/useDateFilter";
import { useDispatchWrapper } from "../../hooks";
import { fetchLedgerBudgetListAsync } from "../../redux/budget";
import { useNavigate } from "react-router-dom";
import {
	Avatar,
	Box,
	Button,
	MenuItem,
	SelectChangeEvent,
} from "@mui/material";
import { DateFilterDropdown } from "../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { CustomDatePicker } from "../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import {
	CSVExportPlugin,
	RefreshPlugin,
	SearchPlugin,
} from "../../components/Table/plugins";
import { IBudget } from "../../redux/budget";
import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import { StatusRenderer } from "../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageContainer } from "../../components/container/PageContainer";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { StandardCard } from "../../components/Cards";

import { DataTable } from "../../components/Table/DataTable";
import { MODULE_IDS } from "../../constants/enums";

import { set } from "lodash";
import { UsersAutoSearch } from "../../components/AutoCompleteSearches/UsersAutoSearch";
import { IUser } from "../../redux/UserProfileList/userProfile.types";
import { toggleSidebar } from "../../redux/customizer/action";
import { RoleBasedCustomButton } from "../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { APP_LEDGER_BUDGET_ROUTE } from "./LedgerBudget.constants";

export const LedgerBudgetList: React.FC = () => {
	const BCrumb: IBreadcrumbProps["items"] = [
		{
			to: "/dashboard",
			title: "dashboard",
		},
		{
			title: "Budget",
		},
	];

	const {
		data: list,
		totalRecords: totalRecords,
		loading,
	} = useSelector((storeState: IStoreState) => storeState.budget.list);
	const {
		userInfo: { isExternalLogin },
	} = useAuth();

	const [tablePagination, setTablePagination] = React.useState({
		pageNumber: 1,
		rowsInPerPage: 50,
	});

	const [searchType, setSearchType] = React.useState<string[]>(["user_name"]);
	const [search, setSearch] = React.useState<string>("");
	const theme = useTheme();
	const [selectedUserUUID, setSelectedUserUUID] = React.useState<string>("-1");

	const [userUUID, setUserUUID] = React.useState("");

	const [status, setStatus] = React.useState<string>("-1");

	const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
		useDateFilter({ defaultType: "last28Days" });

	const dispatch = useDispatchWrapper();
	const navigate = useNavigate();

	const [openTopUpDialog, setOpenTopUpDialog] = React.useState(false);
	const [selectedBudgetUuid, setSelectedBudgetUuid] = React.useState<
		string | null
	>(null);

	const handleCloseTopUpDialog = () => {
		setOpenTopUpDialog(false);
	};

	const handleTopUpSuccess = () => {
		// Handle the success callback
		setOpenTopUpDialog(false);
		fetchList();
	};

	const [TopUp, setTopUp] = React.useState<IBudget | null>(null);
	console.log("userUUID", userUUID);

	const formatAmount = (amount: number): string => {
		return new Intl.NumberFormat("en-US").format(amount);
	};

	React.useEffect(() => {
		dispatch(toggleSidebar(false));
	}, []);

	const productsTableProps: IDataTableProps = {
		isPagination: true,
		totalRecords: totalRecords,
		rowsPerPageOptions: tablePagination.rowsInPerPage,
		isDataLoading: false, // loading !== LoadState.Loaded,
		selectionMode: "none",
		uniqueRowKeyName: "id",
		tableCommandBarProps: {
			leftItems: {
				customPlugins: [
					{
						key: "status",
						onRender: () => (
							<CustomSelect
								sx={{ minWidth: "120px" }}
								value={status}
								onChange={(e: any) => setStatus(e.target.value as "ACTIVE")}
							>
								<MenuItem value="-1">All Status</MenuItem>
								<MenuItem value="TOP_UP">Top UP Amount</MenuItem>
								<MenuItem value="ADJUSTMENT">Adjustment Amount</MenuItem>
								<MenuItem value="INITIAL_ALLOCATION">
									Initial Allocation Amount
								</MenuItem>
							</CustomSelect>
						),
					},
					{
						key: "byDate",
						onRender: () => (
							<DateFilterDropdown
								value={type}
								options={dateDropdownList}
								onChange={handleDateSelectChange}
							/>
						),
					},
					{
						key: "fromDate",
						onRender: () =>
							type === "custom" ? (
								<CustomDatePicker
									sx={{ minWidth: "150px" }}
									value={date.fromDate}
									onChange={(newValue) => {
										const newDate = moment(newValue).format("YYYY-MM-DD");
										setDate({ ...date, fromDate: newDate });
									}}
								/>
							) : (
								<></>
							),
					},
					{
						key: "toDate",
						onRender: () =>
							type === "custom" ? (
								<CustomDatePicker
									sx={{ minWidth: "150px" }}
									value={date.toDate}
									onChange={(newValue) => {
										const newDate = moment(newValue).format("YYYY-MM-DD");
										setDate({ ...date, toDate: newDate });
									}}
								/>
							) : (
								<></>
							),
					},

					{
						key: "userUUID",
						onRender: () => (
							<div>
								<Box sx={{ minWidth: "200px" }}>
									<UsersAutoSearch
										uniqueKey="user_uuid"
										value={userUUID}
										onSelect={(value) =>
											// console.log(value.user_uuid)

											setUserUUID(value.user_uuid)
										}
									/>
								</Box>
							</div>
						),
					},

					{
						key: "csvExport",
						onRender: (columns, items) => (
							<CSVExportPlugin
								columns={columns}
								items={items}
								filePrefixName="Report"
							/>
						),
					},
					{
						key: "refresh",
						onRender: () => <RefreshPlugin onClick={fetchList} />,
					},
				],
			},
			rightItems: {
				plugins: {},
				customPlugins: [
					{
						key: "search",
						onRender: () => {
							return (
								<SearchPlugin
									selectedDropdownValue={
										searchType.length ? searchType : ["user_name"]
									}
									dropdownOptions={[
										{ label: "Name", value: "user_name" },
										{ label: "Debit", value: "debit" },
										{ label: "Credit", value: "credit" },
										{ label: "Amount", value: "balance" },
									]}
									onDropdownChange={(value) => setSearchType(value)}
									onChange={(newValue) => {
										setSearch(newValue);
									}}
								/>
							);
						},
					},
				],
			},
		},
		// mobileLogo: {
		// 	type: MobileLogoRenderType.reactNode,
		// 	onMobileLogoRender: (data: any) => {
		// 		return (
		// 			<Avatar
		// 				sx={{
		// 					width: 45,
		// 					height: 45,
		// 					color: "#fff",
		// 					ml: "-8px",
		// 					mr: 2,
		// 				}}
		// 			></Avatar>
		// 		);
		// 	},
		// },
		columns: [
			{
				key: "insert_ts",
				headerName: "Date",
				fieldName: "insert_ts",
				enableSorting: true,
				renderType: RenderType.CUSTOM_RENDER,
				onRowCellRender: (value, row) => {
					const date = new Date(row.insert_ts);
					return  moment(row.insert_ts).utc().format("MMMM DD, YYYY")
					// return date.toLocaleDateString("en-US", {
					// 	year: "numeric",
					// 	month: "long",
					// 	day: "numeric",
					// }); // Example: "November 21, 2024"
				},
			},
			{
				key: "order_no",
				headerName: "order No",
				fieldName: "order_no",
				enableSorting: true,
				// isFirstColumnSticky: true,
				renderType: RenderType.CUSTOM_RENDER,
			},
			{
				key: "user_name",
				headerName: "Name",
				fieldName: "user_name",
				enableSorting: true,
				// isFirstColumnSticky: true,
				renderType: RenderType.CUSTOM_RENDER,
			},
			{
				key: "debit",
				headerName: "Debit Amount",
				fieldName: "debit",
				renderType: RenderType.TEXT_DARK,
				exportCellWidth: 30,
				enableSorting: true,
				onRowCellRender: (value, row) => formatAmount(row.debit),
			},
			{
				key: "credit",
				headerName: "Credit Amount",
				fieldName: "credit",
				renderType: RenderType.TEXT_DARK,
				exportCellWidth: 30,
				enableSorting: true,
				onRowCellRender: (value, row) => formatAmount(row.credit),
			},

			{
				key: "balance",
				headerName: "Amount",
				fieldName: "balance",
				renderType: RenderType.TEXT_DARK,
				exportCellWidth: 30,
				enableSorting: true,
				onRowCellRender: (value, row) => formatAmount(row.balance),
			},
			{
				key: "status",
				headerName: "Status",
				fieldName: "status",
				renderType: RenderType.CHIP_SUCCESS,
				exportCellWidth: 30,
				enableSorting: true,
				onRowCellRender: (value, row: IBudget) => {
					return <StatusRenderer status={row.status} />;
				},
			},
		],

		items: list,
		onPageChange: (newPageNumber: number) => {
			setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
		},
		onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
			setTablePagination({
				pageNumber: pageNumber,
				rowsInPerPage: rowsPerPage,
			});
		},
	};

	const fetchList = () => {
		// Validate or preprocess input values if necessary
		const payload = {
			page: tablePagination.pageNumber,
			rowsPerPage: tablePagination.rowsInPerPage,
			status: status || "", // Ensure status is always a string
			fromDate: date?.fromDate || undefined, // Ensure dates are passed correctly
			toDate: date?.toDate || undefined,
			value: search?.trim() || "", // Ensure value is always a string
			columns: searchType || null, // Handle empty or undefined columns
		};

		// Dispatch the action with validated payload
		dispatch(fetchLedgerBudgetListAsync(payload, userUUID));
	};

	React.useEffect(() => {
		fetchList();
	}, [tablePagination, date, status, search, searchType, userUUID]);

	return (
		<PageContainer
			title="View Ledger Budget"
			description="this is inner page"
		>
			<Breadcrumb
				title="View Ledger Budget"
				items={BCrumb}
			/>

			<StandardCard heading="Ledger Budget">
				<DataTable {...productsTableProps} />
			</StandardCard>
		</PageContainer>
	);
};
