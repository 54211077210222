// budget.state.ts

import { LoadState } from "../../constants/enums";
import { IBudget, IBudgetListState } from "./budget.types";

export const defaultBudget: IBudget = {
	budget_uuid: "",
	user_uuid: "",
	user_name: "",
	allocated_amount: 0,
	remaining_amount: "0",
	frequency: "",
	next_run_date: "",
	status: "",
	created_by_uuid: "",
	created_by_name: "",
	modified_by_uuid: "",
	modified_by_name: "",
	create_ts: "",
	insert_ts: "",
};

export const defaultBudgetState: IBudgetListState = {
	list: {
		data: [],
		totalRecords: 0,
		loading: LoadState.NotLoaded,
		error: null,
	},

	single_budget_object: {
		data: defaultBudget,
		loading: LoadState.NotLoaded,
		error: null,
	},
};
