import { api } from "../../api/api";
import { ICustomer } from "../../redux/customer";
import { ICustomerDeliveryAddress, initialCustomerDeliveryAddress } from "../../redux/customerDeliveryAddress";
import {
	checkIfUserHasValidBillingCompany,
} from "./BillingCompanyProvider.helpers";
import { IBillingCompanyContextState } from "./BillingCompanyProvider.types";

export const fetchBillingCompanyInfoAsync = (
	billing_company_uuid: string,
	isAdmin: boolean
) =>
	new Promise<IBillingCompanyContextState | null>((resolve, reject) => {
		let api_url = `/company/get-company?pageNo=1&itemPerPage=100`;
		if (billing_company_uuid && !isAdmin) {
			api_url += `&customer_uuid=${billing_company_uuid}`;
		}
		api.get(api_url).then((response) => {
			const comapnyInfo = response.data.data as ICustomer[];
			resolve(checkIfUserHasValidBillingCompany(comapnyInfo, isAdmin));
		});
	});

export const fetchBillingCompanyAddressesInfoAsync = (
	billing_company_uuid: string,
) =>
	new Promise<ICustomerDeliveryAddress>((resolve, reject) => {
		let api_url = `/company/get-company-delivery-address?pageNo=1&itemPerPage=100`;
		if (billing_company_uuid) {
			api_url += `&company_uuid=${billing_company_uuid}`;
		}
		api.get(api_url).then((response) => {
			const comapnyInfo = response.data.data as ICustomerDeliveryAddress[];
			resolve(comapnyInfo[0] || initialCustomerDeliveryAddress);
		}).catch((error) => {
			reject(error)
		});
	});

