/* eslint-disable react-hooks/exhaustive-deps */
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Grid,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { StandardCard } from "../../components/Cards";
import { CustomStatusSelector } from "../../components/Common/CustomStatusSelector";
import { PageContainer } from "../../components/container/PageContainer";
import { LabelCard } from "../../components/LabelCard/LabelCard";
import {
	ILocationResponsePayload,
	LocationAutoComplete,
} from "../../components/LocationAutoComplete/LocationAutoComplete";
import { TextFieldWithLabel } from "../../components/TextFieldWithLabel/TextFieldWithLabel";
import { useDispatchWrapper, useLoggedInUserInfo } from "../../hooks";
import { IStoreState } from "../../redux/initialStoreState";
import {
	clearSingleOrderSync,
	downloadSupplierOrderPdfAsync,
	fetchSupplierOrderAsync,
	upsertExternalSupplierOrderDiscrepancies,
	upsertFileOrderDiscrepancies,
} from "../../redux/orders";
import { SupplierAutoSearch } from "../supplier/Suppliers/AutoSearch/SupplierAutoSearch";
import { APP_ORDERS_ROUTE } from "./Orders.constants";
import { ISupplier } from "../../redux/supplier";
import { displayPriceFormat } from "../../helpers/isNotNull";
import { handleApprovalAsync } from "../../redux/approval/quote";
import { APP_APPROVALS_ORDERS_ROUTE } from "../approval/Approvals.constants";
import { SupplierOrderItemsTable } from "./components/SupplierOrderItemsTable";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { APPLICATION_ROLES, LoadState } from "../../constants/enums";
import { CustomFormLabel } from "../../components/formsComponents";
import { useAuth } from "../../contexts/AuthProvider/AuthProvider";
import { FileUpload } from "../../components/FileUpload/FileUpload";
import { toggleSidebar } from "../../redux/customizer/action";
import { batch } from "react-redux";
import { SuccessDialog } from "../../components/Dialogs/SuccessDialog";
import { userInfo } from "os";
import { getNumericValue } from "../../helpers";
import { UserDocuments } from "../ExternalSupplier/UserDocument/UserDocument";
import { TabsList } from "../../components/Tabs/TabsList";
import { TabPanel } from "../../components/Tabs/TabPanel";
import { UserDocumentsDialog } from "../ExternalSupplier/components/userDocumentDialog";
import { IUserDocument } from "../../redux/userDocument/userDocument.types";
import { Add, PictureAsPdf } from "@mui/icons-material";
import { ConfirmUploadOrEditDialog } from "./dialogs/ConfirmUploadOrEditDialog";
import { addUserDocumentsAsync, UpsertOrderItemsForExternalSupplierAsync } from "../../redux/userDocument/userDocumentAction";
import { StandardPreview } from "../preview/StandardPreview";
import { SupplierOrderPdfPreview } from "../preview/SupplierOrderPdfPreview";

const INITIAL_STATE: IUserDocument = {
	orders_uuid: "",
	description: "",
	file_url: "",
	type: "",
	order_documents_uuid: null
};

export const SupplierManageOrder = () => {
	const { uuid: singleOrderUUID } = useParams() as { uuid?: string };
	const loginedUserInfo = useLoggedInUserInfo();

	const [supplierEditMode, setSupplierEditMode] = React.useState(false);
	const [confirmUploadOrEdit, setConfirmUploadOrEdit] =
		React.useState<string | null>(null);

	const dispatch = useDispatchWrapper();
	const navigate = useNavigate();

	const [open, setOpen] = React.useState<IUserDocument | null>(null);
	const [tab, setTab] = React.useState(0);
	const [expanded, setExpanded] = React.useState(false);
	const [orderItemsExpanded, setOrderItemsExpanded] = useState(true);
	const {
		user: { role_value },
	} = useAuth().userInfo;
	const [ocFile, setOcFile] = React.useState<File | null>(null);

	const { data: singleOrderDetails, loading } = useSelector(
		(storeState: IStoreState) => storeState.orders.single_order
	);
	const isSupplier = role_value === APPLICATION_ROLES.SUPPLIER;
	const [openSuccess, setOpenSuccess] = React.useState(false);
	const isExternal = true;
	const [openPreview, setOpenPreview] = React.useState<string | null>(null);

	const {
		values,
		errors,
		handleChange,
		handleSubmit,
		validateForm,
		setValues,
		setFieldValue,
	} = useFormik({
		initialValues: singleOrderDetails,
		validate: (values) => {
			const errors: any = {};
			// if(values.status === "REQUEST_FOR_SHIPMENT" ||  values.status === "PARTIAL_SHIPPED") {
			// 	values.order_item_list.forEach((item, index) => {
			// 		const itemErrors: any = {};
			// 		if (!item.shipping_quantity) {
			// 		  itemErrors.shipping_quantity = "Shipping quantity is required";
			// 		} 
			// 		errors.order_items_list[index] = itemErrors;
			// 	  });
			// }

			return errors;
		},
		onSubmit: async (values) => {
			if (values.orders_uuid && ocFile) {
				dispatch(
					upsertFileOrderDiscrepancies(
						values.orders_uuid,
						ocFile,
						(isSuccess) => {
							if (isSuccess) {
								setOpenSuccess(true);
							}
						}
					)
				);
			}
		},
	});

	const isDisabledAll = false;

	const handleFileUpload = (file: File) => {
		setOcFile(file);
		if (values.orders_uuid) {
			dispatch(
				upsertFileOrderDiscrepancies(values.orders_uuid, file, (isSuccess) => {
					if (isSuccess) {
						setOpenSuccess(true);
					}
				})
			);
		}
	};

	const handleFileDelete = () => {
		setFieldValue("order_confirmation_file", null);
		setOcFile(null);
	};

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	const onSupplierChange = (supplier: ISupplier) => {
		const supplierItems = values.order_item_list.map((O_item) => {
			return {
				...O_item,
				supplier_name: supplier.supplier_name,
			};
		});
		setValues({
			...values,
			supplier_uuid: supplier.supplier_uuid as string,
			supplier_name: supplier.supplier_name as string,
			order_item_list: supplierItems,
		});
		validateForm({
			...values,
			supplier_uuid: supplier.supplier_uuid as string,
			supplier_name: supplier.supplier_name as string,
		});
	};

	React.useEffect(() => {
		batch(() => {
			dispatch(toggleSidebar(false));
			dispatch(fetchSupplierOrderAsync());
		});
	}, [open]);

	const handleComplete = () => {
		setOpen(null);
	};

	const handleAccordionToggle = () => {
		setExpanded((prev) => !prev);
	};

	const handleOrderItemsToggle = () => {
		setOrderItemsExpanded((prev) => !prev);
	};

	React.useEffect(() => {
		setValues(singleOrderDetails);
	}, [singleOrderDetails]);

	// Order Item Calculations
	React.useEffect(() => {
		let newTotalAmount = 0;
		values.order_item_list.forEach((item) => {
			newTotalAmount +=
				Number(getNumericValue(item.quantity || "0")) *
				Number(getNumericValue(item.unit_cost || "0"));
		});

		setFieldValue("total_amount", newTotalAmount.toFixed(2));
	}, [values.order_item_list]);

	React.useEffect(() => {
		return () => {
			dispatch(clearSingleOrderSync());
		};
	}, []);

	const handleAdd = () => {
		if (values.status === "ORDER_WAITING_FOR_CONFIRMATION") {
			setOpen({
				...INITIAL_STATE,
				type: "ORDER_CONFIRMATION",
				orders_uuid: values.orders_uuid,
			});
		} else if (values.status === "REQUEST_FOR_ICT") {
			setOpen({
				...INITIAL_STATE,
				type: "ICT_MASTER_SHEET",
				orders_uuid: values.orders_uuid,
			});
		} else if (values.status === "REQUEST_FOR_PRE_SHIPMENT") {
			setOpen({
				...INITIAL_STATE,
				type: "PRE_SHIPMENT",
				orders_uuid: values.orders_uuid,
			});
		} else if (values.status === "REQUEST_FOR_SHIPMENT") {
			setOpen({
				...INITIAL_STATE,
				type: "SHIPMENT",
				orders_uuid: values.orders_uuid,
			});
		} else {
			setOpen({ ...INITIAL_STATE, orders_uuid: values.orders_uuid });
		}
	};


	const hanleUploadOrEditClick = (mode: "EDIT" | "UPLOAD") => {
		if (mode === "EDIT") {
			setSupplierEditMode(true)
		}
		if (mode === "UPLOAD") {
			handleAdd()
		}
		setConfirmUploadOrEdit(null)
	}
	console.log("setSupplierEditMode ===>", supplierEditMode)

	// UpsertOrderItemsForExternalSupplierAsync
	const handleSaveEditedIems = () => {
		const type = values.status === "REQUEST_FOR_SHIPMENT" || values.status === "PARTIAL_SHIPPED" ? "SHIPMENT" : "ORDER_CONFIRMATION"
		dispatch(UpsertOrderItemsForExternalSupplierAsync(values.orders_uuid, values.order_item_list, type,
			(isSuccess) => {
				if (isSuccess) {
					window.location.reload()
				}
			}
		)
		);
	}


	const hanleMastersheetConfirmClick = (mode: "WITHOUT" | "UPLOAD") => {
		if (mode === "UPLOAD") {
			handleAdd()
		}
		setConfirmUploadOrEdit(null)
		if (mode === "WITHOUT") {
			dispatch(
				addUserDocumentsAsync(
					{
						...INITIAL_STATE,
						type: "ICT_MASTER_SHEET",
						orders_uuid: values.orders_uuid,
					},
					null,
					(isSuccess: boolean) => {
						if (isSuccess) {
							window.location.reload()
						}
					}
				)
			);
		}
	}


	const isSaveButtonVisible = (supplierEditMode && values.status === "ORDER_WAITING_FOR_CONFIRMATION") || values.status === "REQUEST_FOR_SHIPMENT" || values.status === "PARTIAL_SHIPPED"

	const handleDownloadOrderPdf = () => {
		dispatch(downloadSupplierOrderPdfAsync(values.orders_uuid, (isSuccess) => {
			if (isSuccess) {

			}
		}))
	}

	return (
		<PageLoader loading={loading === LoadState.InProgress}>
			<PageContainer
				title="Manage Order"
				description="This is inner page"
			>
				<form onSubmit={handleSubmit}>
					<TabsList
						sx={{ mb: 2 }}
						varaint="fullWidth"
						selectedTab={tab}
						tabs={[{ label: "Order Information" }, { label: "Documents" }]}
						onChange={handleTabChange}
					/>

					<TabPanel
						value={tab}
						index={0}
					>
						<StandardCard
							heading={
								<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
									<Typography variant="h2" fontWeight={"700"}>Order Information </Typography>
									{/* <Button variant="contained" onClick={handleDownloadOrderPdf}>Download Pdf</Button> */}
									<IconButton onClick={() => setOpenPreview(values.orders_uuid)}>
										<Tooltip title="Order Pdf">
											<PictureAsPdf color="primary" />
										</Tooltip>
									</IconButton>
								</Box>
							}
							rightHeading={
								<Stack
									sx={{
										display: "flex",
										flexDirection: "row",
										marginRight: 2,
										gap: 1,
									}}
								>
									<Typography variant="h6" padding={1} sx={{ background: "#ffcf08", borderRadius: 0.8, pt: 1.250 }}>
										<>{values.order_no || "--"}</>
									</Typography>
									<Typography
										sx={{
											border: 2,
											borderColor: "primary.main",
											borderRadius: 1,
											padding: 1,
											backgroundColor: "background.paper",
											color: "text.primary",
										}}
									>
										{values.status.replace(/_/g, " ")}
									</Typography>

									{(values.status === "ORDER_WAITING_FOR_CONFIRMATION" ||
										values.status === "REQUEST_FOR_ICT") && (
											<>
												{supplierEditMode ?
													<Button
														size="small"
														variant="contained"
														sx={{ backgroundColor: "#1976d2" }}
														onClick={() => setSupplierEditMode(false)}
													>
														Revert
													</Button>
													:
													<Button
														size="small"
														variant="contained"
														sx={{ backgroundColor: "#1976d2" }}
														onClick={() => setConfirmUploadOrEdit(values.status === "REQUEST_FOR_ICT" ? "ICT_MASTER_SHEET" : "ORDER_CONFIRMATION")}
													>
														Actions
													</Button>

												}
											</>

										)}
									{values.status === "REQUEST_FOR_PRE_SHIPMENT" &&
										<Button
											size="small"
											variant="contained"
											sx={{ backgroundColor: "#1976d2" }}
											onClick={() =>
												setOpen({
													...INITIAL_STATE,
													type: "PRE_SHIPMENT",
													orders_uuid: values.orders_uuid,
												})
											}
										>
											Upload Pre Shipment Invoice
										</Button>

									}
								</Stack>
							}
						>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={12}
									lg={6}
								>
									<TextFieldWithLabel
										heading={"Buyer Name"}
										type="text"
										id="buyer_name"
										fullWidth
										value={values.buyer_name}
										disabled
									/>
								</Grid>

								<Grid
									item
									xs={12}
									lg={6}
								>
									<TextFieldWithLabel
										heading={"Buyer Email"}
										type="text"
										id="buyer_email"
										fullWidth
										value={values.buyer_email}
										disabled
									/>
								</Grid>
							</Grid>

							<Grid
								item
								xs={12}
								marginTop={2}
							>
								<Accordion
									expanded={expanded}
									onChange={handleAccordionToggle}
								>
									<AccordionSummary>
										<LabelCard heading="Buyer Address Details" />
									</AccordionSummary>
									<AccordionDetails>
										<Grid
											container
											spacing={1}
										>
											{!isDisabledAll && (
												<Grid
													item
													xs={12}
													md={12}
												>
													<TextFieldWithLabel
														heading="Address Line 1"
														id="billing_address_line_1"
														fullWidth
														disabled={!supplierEditMode}
														value={values.billing_address_line_2}
													// onChange={handleChange}
													/>
												</Grid>
											)}
											<Grid
												item
												xs={12}
												md={6}
											>
												<TextFieldWithLabel
													heading="Address Line 1"
													id="billing_address_line_1"
													fullWidth
													disabled={true}
													value={values.billing_address_line_1}
													onChange={handleChange}
												/>
											</Grid>
											<Grid
												item
												xs={6}
												md={6}
											>
												<TextFieldWithLabel
													heading="Address Line 2"
													id="billing_address_line_2"
													fullWidth
													disabled={true}
													value={values.billing_address_line_2}
													onChange={handleChange}
												/>
											</Grid>
											<Grid
												item
												xs={12}
												md={6}
											>
												<TextFieldWithLabel
													heading="City"
													id="billing_city"
													fullWidth
													disabled={true}
													value={values.billing_city}
													onChange={handleChange}
												/>
											</Grid>
											<Grid
												item
												xs={12}
												md={6}
											>
												<TextFieldWithLabel
													heading="State"
													id="billing_state"
													fullWidth
													disabled={true}
													value={values.billing_state}
													onChange={handleChange}
												/>
											</Grid>
											<Grid
												item
												xs={12}
												md={6}
											>
												<TextFieldWithLabel
													heading="Country"
													id="billing_country"
													fullWidth
													disabled={true}
													value={values.billing_country}
													onChange={handleChange}
												/>
											</Grid>
											<Grid
												item
												xs={12}
												md={6}
											>
												<TextFieldWithLabel
													heading="Pin Code"
													id="billing_pincode"
													fullWidth
													disabled={true}
													value={values.billing_pincode}
													onChange={handleChange}
												/>
											</Grid>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>

							{/*********************************** Order Items *************************************** */}

							{/* <Grid
								item
								xs={12}
								marginTop={2}
							>
								<LabelCard heading="Order Items" />
								<SupplierOrderItemsTable
									uuid={singleOrderUUID as string}
									data={values.order_item_list}
									supplierName={values.supplier_name}
									onChange={(data) => setFieldValue("order_item_list", data)}
								/>
							</Grid> */}

							<Grid
								item
								xs={12}
								marginTop={2}
							>
								<Accordion
									expanded={orderItemsExpanded}
									onChange={handleOrderItemsToggle}
								>
									<AccordionSummary>
										<LabelCard heading="Order Items" />
									</AccordionSummary>
									<AccordionDetails>
										<Grid
											item
											xs={12}
											marginTop={2}
										>
											<SupplierOrderItemsTable
												uuid={singleOrderUUID as string}
												data={values.order_item_list}
												supplierName={values.supplier_name}
												disabled={!supplierEditMode}
												status={values.status}
												// errors={errors?.order_item_list}
												onChange={(data) =>
													setFieldValue("order_item_list", data)
												}
											/>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>
						</StandardCard>
						<StandardCard
							sx={{
								marginTop: 2,
								marginBottom: 8,
							}}
						>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={12}
									lg={5}
								></Grid>
								<Grid
									item
									xs={12}
									lg={7}
								>
									<Grid
										item
										xs={12}
										lg={12}
									>
										<Stack
											direction={"row"}
											justifyContent={"space-between"}
											marginTop={2}
											marginBottom={2}
											padding={1.5}
											borderRadius={2}
											sx={{ backgroundColor: "lightgrey" }}
										>
											<Typography
												variant="h5"
												sx={{ color: "black" }}
												fontWeight={600}
											>
												Total Amount
											</Typography>
											<Typography
												variant="h5"
												fontWeight={600}
											>
												{`${values.currency} ${displayPriceFormat(values.total_amount)}`}
											</Typography>
										</Stack>
									</Grid>
									<Grid
										item
										xs={12}
										lg={12}
									>
										<Stack
											display={"flex"}
											flexDirection={"row"}
											justifyContent={"flex-end"}
											alignItems={"flex-end"}
											gap={2}
										>
											{/* <CustomStatusSelector
                      heading="Status"
                      name="status"
                      module="ORDER"
                      value={values.status}
                      onChange={handleChange}
                    /> */}
											{/* <Button
												variant="contained"
												disabled={!ocFile}
												type="submit"
											>
												Save Items
											</Button>

											{(values.status ===
												"ORDER_WAITING_FOR_CONFIRMATION" ||
												values.status === "REQUEST_FOR_ICT" ||
												values.status === "REQUEST_FOR_PRE_SHIPMENT" ||
												values.status === "REQUEST_FOR_SHIPMENT") && (
													<Button
														size="small"
														variant="contained"
														sx={{ backgroundColor: "#1976d2" }}
														onClick={handleAdd}
													>
											Upload Document
										</Button>
												)} */}
											{isSaveButtonVisible &&
												<Button
													variant="contained"
													onClick={handleSaveEditedIems}
												>
													Save Items
												</Button>}

										</Stack>
									</Grid>
								</Grid>
							</Grid>
						</StandardCard>
					</TabPanel>

					<TabPanel
						value={tab}
						index={1}
					>
						<StandardCard>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={12}
									md={12}
								>
									<>
										{values && (
											<UserDocuments
												orderUUID={values.orders_uuid}
												isExternal={isExternal}
											/>
										)}
									</>
								</Grid>
							</Grid>
						</StandardCard>
					</TabPanel>
				</form>
			</PageContainer>

			{
				open && (
					<UserDocumentsDialog
						open={true}
						data={open}
						isExternal={isExternal}
						onClose={() => setOpen(null)}
						onComplete={handleComplete}
					/>
				)
			}

			{
				openSuccess && (
					<SuccessDialog
						open={openSuccess}
						content={
							<Typography variant="h4">
								Your file has been successfully uploaded
							</Typography>
						}
						onClose={() => setOpenSuccess(false)}
					/>
				)
			}
			{
				confirmUploadOrEdit && <ConfirmUploadOrEditDialog
					open={true}
					type={confirmUploadOrEdit}
					onConfirm={hanleUploadOrEditClick}
					onMasterSheetConfirm={hanleMastersheetConfirmClick}
					onClose={() => setConfirmUploadOrEdit(null)} />
			}
			{openPreview && (
				<SupplierOrderPdfPreview
					open={true}
					type="PI"
					uuid={openPreview}
					onClose={() => setOpenPreview(null)}
				/>
			)}
		</PageLoader >
	);
};
