import { LoadState } from "../../../constants/enums";

export interface IApproval {
	order_id: number;
	order_uuid: string;
	enquiry_no: string;
	enquiry_uuid: string;
	project_name: string;
	target_dispatch_date: string | null;
	company_uuid: string;
	company_name: string;
	delivery_address_line1: string;
	delivery_address_line2: string;
	delivery_address_city: string;
	delivery_address_state: string;
	delivery_address_pincode: string;
	delivery_address_country: string;
	status: string;
	created_by_uuid: string;
	assigned_to_uuid: string;
	secondary_assigned_to_uuid: string;
	create_ts: string;
	insert_ts: string;
	approval_uuid: string;
	requested_by_uuid: string;
	approval_status: string;
}

export interface IUpsertApproval {
	table_name: string;
	record_uuid: string;
	record_column_name: string;
	previous_status: string;
	next_status: string;

	// read only
	create_ts?: string;
	insert_ts?: string;
}

export interface IApprovalState {
	list: IApproval[];
	totalRecords: number;
	loading: LoadState;
}

export interface IPostEnquiryOrderApproval {
	enquiry_uuid: string;
	target_dispatch_date: string | null;
	approval_uuid: string;
	remark: string;
	status: "APPROVED" | "REJECTED" | "ROLLBACK";
}
export const intitialApprovalState: IApprovalState = {
	list: [],
	loading: LoadState.NotLoaded,
	totalRecords: 0,
};
