import { useCallback, useState } from "react";
import { CustomFormLabel, CustomTextField, CustomTextFieldStyled } from "../formsComponents";
import { ITextFieldWithLabelProps } from "./TextFieldWithLabel.types";
import { isNotNull } from "../../helpers/isNotNull";
import { TextFieldProps, Typography } from "@mui/material";

export const TextFieldWithLabel: React.FC<ITextFieldWithLabelProps> = (
  props,
) => {
  return (
    <>
      <CustomFormLabel>{props.heading}</CustomFormLabel>
      <CustomTextField
        {...props}
        variant="outlined"
        size="small"
        error={props.error ? true : false}
        helperText={props.error ? props.helperText : ''}
      />
    </>
  );
};



interface CustomNumberFieldProps extends Omit<TextFieldProps, "value"> {
  readOnlyMode?: boolean;
  value?: string | number;
}

export const CustomNumberField: React.FC<CustomNumberFieldProps> = (props) => {
  const { readOnlyMode = false, value, onChange, ...rest } = props;

  const processedValue = useCallback(() => {
    if (!value) return "";
    return value.toString().replace(/^0+/, ""); // Remove leading zeros
  }, [value])();

  return (
    <CustomTextFieldStyled
      {...rest}
      value={processedValue} // Use processed value correctly
      onChange={(event) => {
        if (!props.onChange) return;
        let newValue = event.target.value
          .replace(/[^0-9.]/g, "")
          .replace(/(\..*)\./g, "$1"); // Allow only numbers
        newValue = newValue.replace(/^0+/, ""); // Remove leading zeros
        props.onChange({
          ...event,
          target: { ...event.target, value: newValue },
        }); // Fire onChange with a new synthetic event
      }}
      InputProps={{ inputProps: { min: 1 } }} // Prevents negative values in UI
    />
  );
};



export const MuiAmountFieldWithLabel: React.FC<ITextFieldWithLabelProps> = (
  props,
) => {

  const processedValue = useCallback(() => {
    if (!props.value) return "";
    return isNotNull(props.value as any).toLocaleString("en-IN").replace(/^0+/, ""); // Remove leading zeros
  }, [props.value])();

  return (
    <>
      {props.heading && <CustomFormLabel>{props.heading}</CustomFormLabel>}
      <CustomTextField
        {...props}
        variant="outlined"
        size="small"
        error={props.error ? true : false}
        helperText={props.error ? props.helperText : ''}
        value={processedValue}
        onChange={(evt) => {
          const rawValue = evt.target.value.replace(/,/g, ""); // Remove commas
          if (isNotNull(rawValue) > -1) {
            const newEvent = { ...evt, target: { ...evt.target, id: props.id as string, value: rawValue } }
            props.onChange && props.onChange(newEvent); // Store as number
          }
        }}
      />
    </>
  );
};
