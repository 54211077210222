import { LoadState } from "../../constants/enums";
import {
	ICustomer,
	ICustomerBranch,
	ICustomerState,
	ILoadCustomerData,
} from "./customer.types";

export const defaultCustomer: ICustomer = {
	company_uuid: "",
	company_name: "",
	company_email: "",
	organization_type: null,
	is_billing_company: null,
	company_website: null,
	legal_entity: null,
	status: "ACTIVE",
	create_ts: "",
	insert_ts: "",
	created_by_uuid: "",
};

export const defaultCustomerBranch: ICustomerBranch = {
	company_branch_uuid: null,
	company_uuid: null,
	company_name: null,
	branch_name: null,
	legal_entity: null,
	is_customer_supplier: null,
	alternate_name: null,
	region: null,
	sub_region_or_country: null,
	city: null,
	pin_zip_code: null,
	industry: null,
	billing_address_line1: null,
	billing_address_line2: null,
	billing_city: null,
	billing_state: null,
	billing_pincode: null,
	billing_country: null,
	delivery_address_line1: null,
	delivery_address_line2: null,
	delivery_address_city: null,
	delivery_address_state: null,
	delivery_address_pincode: null,
	delivery_address_country: null,
	import_export_code_iec: null,
	pan_no: null,
	gst_no: null,
	service_tax_category: null,
	registered_msme: null,
	status: "ACTIVE",
};

export const defaultLoadCustomerData: ILoadCustomerData = {
	company_name: "",
	organization_type: null,
	customer_website: null,
	legal_entity: null,
	registration_type: null,
	customer_branch_name: "",
	customer_branch_gst_in: "",
	customer_branch_address_line1: null,
	customer_branch_address_line2: null,
	customer_branch_address_city: null,
	customer_branch_address_state: null,
	customer_branch_address_pincode: null,
	customer_branch_address_country: null,
	customer_branch_mobile: null,
	customer_branch_phone_number: null,
	customer_branch_website: null,
	customer_branch_mail_id: null,
	name: "",
	mail_id: null,
	pan_no: null,
	salutation: null,
	designation: null,
	contact_type: null,
	department: null,
	extension: null,
	company_landline: null,
	fax: null,
	website: null,
	dob: null,
	previous_organisation: null,
	skype_id: null,
	executive_location_line1: null,
	executive_location_line2: null,
	executive_location_city: null,
	executive_location_state: null,
	executive_location_pincode: null,
	executive_location_country: null,
	contact_number: null,
	delivery_name: null,
	customer_delivery_address_line1: null,
	customer_delivery_address_line2: null,
	customer_delivery_address_city: null,
	customer_delivery_address_state: null,
	customer_delivery_address_pincode: null,
	customer_delivery_address_country: null,
};

export const defaultCustomerState: ICustomerState = {
	list: {
		data: [],
		totalRecords: 0,
		loading: LoadState.NotLoaded,
		error: null,
	},
	customer: {
		data: defaultCustomer,
		loading: LoadState.NotLoaded,
		error: null,
	},
	customerBranchList: {
		data: [],
		totalRecords: 0,
		loading: LoadState.NotLoaded,
		error: null,
	},
	customerBranch: {
		data: defaultCustomerBranch,
		loading: LoadState.NotLoaded,
		error: null,
	},
};
