import { Add, ContentCopy, Delete, LowPriority, Visibility } from "@mui/icons-material";
import {
	Box,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import produce from "immer";
import React, { useState } from "react";
import { defaultOrderItem, IOrderItem } from "../../../redux/orders";
import { CustomNumberField, CustomTextField } from "../../../components/formsComponents";
import { IProduct } from "../../../redux/products";
import { PreviewImage } from "../../../components/PreviewImage/PreviewImage";
import { ViewSingleOrderItemDialog } from "../dialogs/ViewSingleOrderItemDialog";
import { ProductAutoSearchDialogField } from "../../products/AutoSearch/ProductAutoSearchDialogField";
import { ProductAutoSearchPanel } from "../../products/AutoSearch/ProductAutoSearchPanel";
import { getNumericValue } from "../../../helpers";
import { isNotNull } from "../../../helpers/isNotNull";

interface IProps {
	uuid: string;
	disabled?: boolean;
	// errors: any
	status: string
	data: IOrderItem[];
	supplierName: string;
	onChange: (data: any) => void;
}
export const SupplierOrderItemsTable: React.FC<IProps> = ({
	uuid,
	data,
	disabled,
	supplierName,
	status,
	onChange,
}) => {
	const [openProductDialog, setOpenProductDialog] = React.useState<
		number | null
	>(null);

	const [preViewSingleProduct, setPreViewSingleProduct] =
		useState<IOrderItem | null>(null);
	const handleProductChange = (index: number, key: string, value: any) => {
		const newValue = produce(data, (draftValues) => {
			(draftValues[index][key as keyof IOrderItem] as any) = value;
			// (draftValues[index]["total_price"] as any) = draftValues[index]["quantity"] * draftValues[index]["price"];
		});
		onChange(newValue);
	};

	const handleAdd = (index: number) => {
		const newValues = produce(data, (draftValues) => {
			draftValues.splice(index + 1, 0, defaultOrderItem);
		});
		onChange(newValues);
	};

	const handleDelete = (index: number) => {
		const newValues = produce(data, (draftValues) => {
			draftValues.splice(index, 1);
		});
		onChange(newValues);
	};

	const formatAmount = (amount: number): string => {
		return new Intl.NumberFormat("en-US").format(amount);
	};

	const handleProductItemChange = (index: number, value: IProduct) => {
		const newValue = produce(data, (draftValues) => {
			draftValues[index]["product_uuid"] = value.product_uuid;

			draftValues[index]["product_vpn"] = value.product_vpn;
			draftValues[index]["primary_image_url"] = value.primary_image_url;
			draftValues[index]["barcode"] = value.barcode;
			draftValues[index]["style"] = value.style;
			draftValues[index]["color"] = value.color;
			draftValues[index]["fabric"] = value.fabric;
			draftValues[index]["size"] = value.size;
			draftValues[index]["description"] = value.description;
			draftValues[index]["category"] = value.category;
			draftValues[index]["class"] = value.class;
			draftValues[index]["subclass"] = value.subclass;
			draftValues[index]["season"] = value.season;
			draftValues[index]["line"] = value.line;
			draftValues[index]["subline"] = value.subline;
			draftValues[index]["gender"] = value.gender;
			draftValues[index]["brand"] = value.brand;
			draftValues[index]["unit_cost"] = value.unit_cost;
			draftValues[index]["rsp"] = value.rsp;
			draftValues[index]["quantity"] = value.quantity;
			draftValues[index]["currency"] = value.currency;
			draftValues[index]["additional_property"] = value.additional_property;
			draftValues[index]["status"] = value.status;
		});

		onChange(newValue);
	};

	const handleProductSelect = (index: number, value: IProduct) => {
		const newItem = { ...defaultOrderItem };
		newItem["orders_uuid"] = uuid;
		// newItem["product_documents_uuid"] = value.product_documents_uuid;
		newItem["product_vpn"] = value.product_vpn;
		newItem["primary_image_url"] = value.primary_image_url;
		newItem["barcode"] = value.barcode;
		newItem["style"] = value.style;
		newItem["color"] = value.color;
		newItem["fabric"] = value.fabric;
		newItem["size"] = value.size;
		newItem["description"] = value.description;
		newItem["category"] = value.category;
		newItem["class"] = value.class;
		newItem["subclass"] = value.subclass;
		newItem["season"] = value.season;
		newItem["line"] = value.line;
		newItem["subline"] = value.subline;
		newItem["gender"] = value.gender;
		newItem["brand"] = value.brand;
		newItem["unit_cost"] = value.unit_cost;
		newItem["rsp"] = value.rsp;
		newItem["quantity"] = value.quantity;
		newItem["currency"] = value.currency;
		newItem["additional_property"] = value.additional_property;
		newItem["status"] = value.status;

		const newValues = produce(data, (draftValues) => {
			draftValues.splice(index + 1, 0, newItem);
		});
		onChange(newValues);
		setOpenProductDialog(null);
	};

	const isShipmentEditable = status === "REQUEST_FOR_SHIPMENT" || status === "PARTIAL_SHIPPED" || status === "SHIPPED"
	const isReceivedEditable = status === "PARTIAL_SHIPPED" || "SHIPPED"

	const handleCopyItemsFromQtyTOTransit = () => {
		const newItems = data.map((item) => ({...item,  shipping_quantity: item.quantity }))
		onChange(newItems);
	}

	return (
		<TableContainer component={Paper}>
			<Table aria-label="Enquiry Checklist">
				<TableHead
					sx={(theme) => ({
						backgroundColor: theme.palette.grey[200],
					})}
				>
					<TableRow>
						<TableCell sx={{ width: 250 }}>Name</TableCell>
						<TableCell>Description</TableCell>
						<TableCell>Size</TableCell>
						<TableCell>
							<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
								<Typography variant="body1" >Quantity</Typography>
								<IconButton onClick={handleCopyItemsFromQtyTOTransit}>
									<Tooltip title="Move to Received">
										<LowPriority color="primary" />
									</Tooltip>
								</IconButton>
							</Box>

						</TableCell>
						{isShipmentEditable &&
							<>
								<TableCell>Shipping Quantity</TableCell>
								<TableCell>In Transit Quantity</TableCell>
								<TableCell>Received Quantity</TableCell>
							</>
						}
						<TableCell>Price</TableCell>
						<TableCell>Total</TableCell>
						{/* <TableCell>Discrepancy Quantity</TableCell> */}
						<TableCell>Preview</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data?.map((item, index) => {
						return (
							<TableRow key={index}>
								<TableCell
									width={250}
									height={100}
									tabIndex={-1}
								>
									<Typography
										component="span"
										sx={{
											borderRadius: 1,
										}}
										variant="body1"
									>
										{item.line}
									</Typography>
									<Box
										mt={1}
										onClick={() => setPreViewSingleProduct(item)}
										sx={{
											maxHeight: 100,
											maxWidth: 100,
											overflow: "hidden",
											width: "100%",
											height: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											objectFit: "contain",
										}}
									>
										<PreviewImage imageUrl={item.preview_url as string} />
									</Box>
								</TableCell>

								<TableCell>
									<Typography variant="subtitle2" > {`Color: ${item.color || ""}`} </Typography>
									<Typography variant="subtitle2" > {`Fabric: ${item.fabric || ""}`} </Typography>
									<Typography variant="subtitle2" > {`Category: ${item.category || ""}`} </Typography>
									<Typography variant="subtitle2" > {`Line: ${item.line || ""}`} </Typography>
									<Typography variant="subtitle2" > {`VPN: ${item.product_vpn || ""}`} </Typography>
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2" > {`Color: ${item.size || ""}`} </Typography>
								</TableCell>
								<TableCell >
									{item.quantity || 0}
								</TableCell>
								{isShipmentEditable &&
									<>
										<TableCell>
											<CustomTextField
												name="shipping_quantity"
												type="number"
												sx={{ maxWidth: 100 }}
												value={item.shipping_quantity}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													const newValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
													if ((isNotNull(newValue) + isNotNull(item.shipped_quantity)) <= Number(item.quantity)) {
														handleProductChange(index, "shipping_quantity", newValue)
													}
												}}
											// error={!!objError?.shipping_quantity}
											// helperText={objError?.shipping_quantity}
											/>
										</TableCell>
										<TableCell sx={{ textAlign: "center" }}>
											{item.shipped_quantity || 0}
										</TableCell>
										<TableCell sx={{ textAlign: "center" }}>
											{item.recived_quantity || 0}
										</TableCell>
									</>
								}
								<TableCell>
									<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', color: "#454545", gap: 1 }} >
										<Typography variant="h6">{item.currency}</Typography>
										<CustomNumberField
											name="unit_cost"
											disabled={disabled}
											sx={{ maxWidth: 100 }}
											value={item.unit_cost as string}
											tabIndex={0}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												handleProductChange(
													index,
													"unit_cost",
													e.target.value
												)
											}
										/>
									</Box>
								</TableCell>
								<TableCell tabIndex={-1}>
									{formatAmount(
										Number(item.quantity) *
										Number(getNumericValue(item.unit_cost || "0"))
									)}
								</TableCell>
								{/* <TableCell>
									<CustomTextField
										name="quantity_ordered"
										type="number"
										value={item.quantity_ordered}
										disabled={disabled}
										tabIndex={0} // Make navigable by keyboard
										onChange={(e) =>
											handleProductChange(
												index,
												"quantity_ordered",
												e.target.value
											)
										}
									/>
								</TableCell> */}
								<TableCell tabIndex={-1}>
									{" "}
									{/* Make non-navigable */}
									<Tooltip title="View Item">
										<IconButton
											disabled={disabled}
											onClick={() => setPreViewSingleProduct(item)}
										>
											<Visibility />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>

			{preViewSingleProduct && (
				<ViewSingleOrderItemDialog
					open={true}
					productInfo={preViewSingleProduct}
					onClose={() => setPreViewSingleProduct(null)}
				/>
			)}

			{openProductDialog && (
				<ProductAutoSearchPanel
					open={true}
					onClose={() => setOpenProductDialog(null)}
					onSelect={(data) => handleProductSelect(openProductDialog, data)}
				/>
			)}
		</TableContainer>
	);
};
